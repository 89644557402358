import React, { FC, useEffect, useState } from 'react';
import {
  Create,
  TextInput,
  BooleanInput,
  PasswordInput,
  CreateProps,
  useNotify,
  useDataProvider,
  Record,
} from 'react-admin';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import ToolBar from 'components/Toolbar';
import { validateUserCreation } from './userValidation';
import SimpleForm from 'components/SimpleForm';
import { RegionDto, RoleDto } from '../../../../../common/dist';
import { AppRole } from 'providers/roles';
import { findManyRoles } from 'services/roles';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { findManyRegions } from 'services/regions';
import RolesAndRegionsInputs from '../roles-and-regions-inputs';

type LocationState = {
  formState: Record;
  selectedBumpers: string[];
};

export const UserCreate: FC<CreateProps> = props => {
  const notify = useNotify();
  const classes = useStyles();
  const history = useHistory();
  const dataProvider = useDataProvider();
  const { hasRole } = PermissionsProvider.useContainer();

  const [roles, setRoles] = useState<RoleDto[] | null>(null);
  const [regions, setRegions] = useState<RegionDto[] | null>(null);
  const [rolesError, setRolesError] = useState(null);
  const [regionsError, setRegionsError] = useState<string | null>();
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<string[]>([]);

  const searchParams = new URLSearchParams(props?.location?.search);
  const contractorId = searchParams.get('contractorId');

  const routerState = (props?.location?.state ||
    props?.history?.location?.state) as LocationState;
  const formState = routerState?.formState || null;
  const selectedBumpers = routerState?.selectedBumpers || null;

  const rolesValue =
    contractorId && roles?.length
      ? [roles.filter(role => role.name === AppRole.Bumper)[0].id] ?? []
      : selectedRoles;

  const handleSave = async data => {
    try {
      const user = await dataProvider.create('user', {
        data,
      });

      // Because this is a new user, no need to handle `DELETE` here
      const userRolesPromises = rolesValue?.map(async role =>
        dataProvider.create('user-role', {
          data: {
            userId: user.data.id,
            roleId: role,
          },
        }),
      );

      // Because this is a new user, no need to handle `DELETE` here
      const userRegionsPromises = selectedRegions?.map(async region =>
        dataProvider.create('user-region', {
          data: {
            userId: user.data.id,
            regionId: region,
          },
        }),
      );

      if (userRegionsPromises?.length) {
        await Promise.all(userRegionsPromises);
      }

      if (userRolesPromises?.length) {
        await Promise.all(userRolesPromises);
      }
      if (!contractorId) {
        history.push('/user');
        notify('Element created');
        return;
      }

      // Redirect to user's edit page if this create form
      // was originated from a contractor's edit flow
      history.push({
        pathname: `/user/${user.data.id}`,
        search: `contractorId=${contractorId}`,
        state: {
          _scrollToTop: true,
          user: user.data,
          formState,
          selectedBumpers,
        },
      });
    } catch (error) {
      notify(error.message, 'warning');
    }
  };

  const getRoles = async () => {
    const roles = await findManyRoles(dataProvider);

    if (roles?.message) {
      setRolesError(roles.message);
      return;
    }

    // Customer Service Representative should only see Builder Supervisor role
    // upon user creation.
    // The backend validates the role, but since we call: 1. User creation 2. Role assignment
    // The user would be created even though the role throwed an error.
    if (hasRole(AppRole.CustomerServiceRepresentative)) {
      setRoles(roles?.filter(role => role.name === AppRole.BuilderSupervisor));
      return;
    }

    setRoles(roles);
  };

  const getRegions = async () => {
    const regions = await findManyRegions(dataProvider);

    if (regions?.message) {
      setRegionsError(regions.message);
      return;
    }

    setRegions(regions);
  };

  useEffect(() => {
    getRegions();
    getRoles();
  }, []);

  return (
    <Create {...props} className={classes.createBox}>
      <SimpleForm
        customSave={handleSave}
        toolbar={<ToolBar />}
        validate={validateUserCreation({
          type: 'create',
          regions: selectedRegions,
          roles: rolesValue,
        })}
      >
        <Box className={classes.fields}>
          <TextInput fullWidth source="firstName" className={classes.input} />
          <TextInput fullWidth source="lastName" className={classes.input} />
        </Box>
        <Box className={classes.fields}>
          <TextInput fullWidth source="email" className={classes.input} />
          <TextInput
            fullWidth
            source="primaryPhone"
            className={classes.input}
          />
          <TextInput
            fullWidth
            source="idNumber"
            label="ID Number"
            className={classes.input}
          />
          <PasswordInput
            fullWidth
            source="password"
            className={classes.input}
          />
        </Box>
        <RolesAndRegionsInputs
          contractorId={contractorId}
          regions={regions}
          roles={roles}
          regionsError={regionsError}
          rolesError={rolesError}
          rolesValue={rolesValue}
          selectedRegions={selectedRegions}
          setSelectedRegions={setSelectedRegions}
          setSelectedRoles={setSelectedRoles}
        />
        <BooleanInput
          source="active"
          className={classes.input}
          initialValue={true}
        />
      </SimpleForm>
    </Create>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
});
