import { FC, useState } from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from 'components/ConfirmDialog';

import CustomReference from 'components/CustomReference';
import { useContainer } from 'unstated-next';
import { PurchaseOrderContainer } from '../../providers/purchase-order';
import { useDataProvider, useNotify } from 'react-admin';
import { DefaultModelContainer } from '../../providers/default-model';
import { JobContainer } from '../../providers/job';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_CANCEL_PO } from 'providers/permissions';
import { TakeoffDatabaseCopyDialog } from '../takeoff-database-copy-dialog';
import { BoardExcelFloorBaseDto } from 'modules/jobs/types';
import { Show } from 'components/Show';
import { getFieldValidation, getMaterialsFromBoards } from 'modules/jobs/utils';
import { updatePurchaseOrder } from 'services/jobs';

export const PurchaseOrderBody: FC = () => {
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const {
    draftPurchaseOrder,
    selectedPhase,
    resetPurchaseOrder,
    isEdit,
    errors,
    isNew,
    isLoading,
    isDraft,
    setDraftPurchaseOrder,
    setIsLoading,
    sameSupplier,
    hasPOBoardExcelFloors,
    setPOHasBoardExcelFloors,
  } = useContainer(PurchaseOrderContainer);

  const {
    job,
    subdivision,
    loadJobData,
    purchaseOrders,
    selectedPurchaseOrder,
    setIsPurchaseOrderOpen,
  } = useContainer(JobContainer);

  const {
    clearDefaultModel,
    importDefaultModel,
    hasDefaultModel,
    importedDefault,
  } = useContainer(DefaultModelContainer);

  const { hasPermission } = PermissionsProvider.useContainer();
  const classes = useStyles();

  const [sendCancellationEmail, setSendCancellationEmail] = useState(true);
  const [showConfirmPurchaseDelete, setShowConfirmPurchaseDelete] =
    useState(false);
  const [openQuickCopyWarningModal, setOpenQuickCopyWarningModal] =
    useState(false);
  const [openCopyTakeoffDatabaseModal, setOpenCopyTakeoffDatabaseModal] =
    useState(false);

  const hasPurchaseOrders = !!purchaseOrders && purchaseOrders.length > 0;
  const disableSupplier = isNew
    ? hasPurchaseOrders
      ? sameSupplier
      : false
    : !isDraft;
  const lastSupplierId =
    purchaseOrders && purchaseOrders[purchaseOrders.length - 1]?.supplierId;
  const canCancel =
    hasPermission(CAN_CANCEL_PO) &&
    isEdit &&
    !isDraft &&
    !selectedPurchaseOrder?.approved &&
    !selectedPurchaseOrder?.canceled;
  const supplierFieldError = getFieldValidation(errors, 'supplier');
  const currentJobPhase = job?.jobPhases?.find(
    job => job.phase?.id === selectedPhase,
  );

  const onPurchaseDelete = () => setShowConfirmPurchaseDelete(true);

  const onPurchaseDeleteClose = () => {
    setSendCancellationEmail(true);
    setShowConfirmPurchaseDelete(false);
  };

  const onPurchaseDeleteConfirm = () => {
    handleCancel(sendCancellationEmail);
    onPurchaseDeleteClose();
  };

  const hasFloorsFilled = !!draftPurchaseOrder?.boardPurchaseFloors?.some(
    board => !!board.boardTypeId,
  );

  const handleCancel = async (cancelNotification = true) => {
    if (!selectedPurchaseOrder?.id || !job?.id) return;

    try {
      setIsLoading(true);

      const params = {
        id: selectedPurchaseOrder.id,
        data: { canceled: true, cancelNotification },
        previousData: selectedPurchaseOrder,
      };

      await updatePurchaseOrder(dataProvider, params);
      await loadJobData(job.id);
      setIsPurchaseOrderOpen(false);
    } catch (err) {
      notify(
        `Board Purchase cancelation Failed! Error: ${err.message}`,
        'warning',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const updateFloorsAndMaterialsFromBoardExcelFloors = (
    boardExcelFloors: BoardExcelFloorBaseDto,
  ) => {
    const formattedFloors = boardExcelFloors.map(board => ({
      floor: board.floor,
      boardTypeId: board.boardTypeId,
      quantity: board.quantity,
    }));

    setDraftPurchaseOrder(prevState => ({
      ...prevState,
      boardPurchaseFloors: formattedFloors,
      ...(!purchaseOrders?.length && {
        materials: getMaterialsFromBoards(formattedFloors, prevState.materials),
      }),
    }));
    setPOHasBoardExcelFloors(true);
  };

  return (
    <Box>
      <Box>
        <Box my={2}>
          <Show condition={!!selectedPhase && (isNew || isDraft)}>
            <Show condition={!!hasDefaultModel(currentJobPhase)}>
              <Show
                condition={!importedDefault}
                fallback={
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    style={{ width: '100%' }}
                    disabled={draftPurchaseOrder.laborOnly}
                    onClick={() => {
                      clearDefaultModel();
                    }}
                  >
                    {draftPurchaseOrder.laborOnly
                      ? 'Cannot import model information for labor only'
                      : 'Clear Default Model Information'}
                  </Button>
                }
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  style={{ width: '100%' }}
                  disabled={draftPurchaseOrder.laborOnly}
                  onClick={() => importDefaultModel(currentJobPhase)}
                >
                  {draftPurchaseOrder.laborOnly
                    ? 'Cannot import model information for labor only'
                    : 'Import Default Model Information'}
                </Button>
              </Show>
            </Show>
            <Show condition={!hasDefaultModel(currentJobPhase)}>
              <Box display="flex">
                <Show
                  condition={hasPOBoardExcelFloors}
                  fallback={
                    <Button
                      variant="contained"
                      size="large"
                      className={classes.quickCopyButton}
                      style={{
                        textTransform: draftPurchaseOrder.laborOnly
                          ? 'uppercase'
                          : 'none',
                        ...(!draftPurchaseOrder.laborOnly && {
                          backgroundColor: '#d2820a',
                        }),
                      }}
                      fullWidth
                      disabled={draftPurchaseOrder.laborOnly}
                      onClick={() =>
                        hasFloorsFilled
                          ? setOpenQuickCopyWarningModal(true)
                          : setOpenCopyTakeoffDatabaseModal(true)
                      }
                    >
                      {draftPurchaseOrder.laborOnly
                        ? 'Cannot Quick Copy from Takeoff Database for Labor Only'
                        : 'Quick Copy from Takeoff Database'}
                    </Button>
                  }
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    size="large"
                    fullWidth
                    disabled={draftPurchaseOrder.laborOnly}
                    onClick={() => {
                      resetPurchaseOrder(hasPurchaseOrders);
                      setPOHasBoardExcelFloors(false);
                    }}
                  >
                    Clear data from Takeoff Database
                  </Button>
                </Show>
              </Box>
            </Show>
          </Show>
        </Box>
      </Box>
      <CustomReference
        label="Supplier"
        resource="supplier"
        sort={{ field: 'name', order: 'ASC' }}
        filters={
          subdivision?.region?.id
            ? {
                $join: [{ field: 'supplierRegions' }],
                'supplierRegions.regionId': subdivision.region.id,
              }
            : {}
        }
        onChange={event => {
          if (event.target.value !== draftPurchaseOrder.supplierId) {
            setDraftPurchaseOrder(prevState => ({
              ...prevState,
              supplierId: event.target.value as string,
            }));
          }
        }}
        disabled={disableSupplier}
        value={
          isEdit && !isDraft
            ? selectedPurchaseOrder?.supplierId ?? undefined
            : (draftPurchaseOrder.supplierId || lastSupplierId) ?? undefined
        }
        error={!!supplierFieldError}
        helperText={supplierFieldError ?? undefined}
      />
      <Box display="flex">
        <TextField
          label="Purchase Order ID"
          fullWidth
          disabled={isEdit && !isDraft}
          className={classes.input}
          value={
            isEdit && !isDraft
              ? selectedPurchaseOrder?.orderNumber
              : draftPurchaseOrder.orderNumber
          }
          onChange={event =>
            setDraftPurchaseOrder(prevState => ({
              ...prevState,
              orderNumber: event.currentTarget.value,
            }))
          }
        />
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          disabled={isEdit && !isDraft}
          margin="normal"
          id="date-picker-inline"
          label="Expected Delivery Date"
          fullWidth
          className={classes.inputNoMarginRight}
          value={
            isEdit && !isDraft
              ? selectedPurchaseOrder?.dateExpected
              : draftPurchaseOrder.dateExpected
          }
          onChange={(date: any) =>
            setDraftPurchaseOrder(prevState => ({
              ...prevState,
              dateExpected: date,
            }))
          }
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
        />
      </Box>
      <Show condition={canCancel}>
        <Box display="flex" alignItems="center" justifyContent="center" my={3}>
          <Button
            disabled={isLoading}
            color="primary"
            variant="contained"
            className={classes.buttonCancelPO}
            onClick={onPurchaseDelete}
          >
            Cancel Purchase Order
          </Button>
        </Box>
      </Show>
      <Dialog
        open={showConfirmPurchaseDelete}
        onClose={onPurchaseDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography className={classes.warning}>Warning!</Typography>
        <DialogContent>
          <Typography className={classes.purchaseDeleteTitle}>
            Are you sure you want to cancel this purchase order? <br />
            <Typography
              className={`${classes.purchaseDeleteTitle} ${classes.underline}`}
            >
              This action cannot be undone
            </Typography>
          </Typography>
          <Box display="flex" alignItems="center" mt="20px">
            <Checkbox
              checked={sendCancellationEmail}
              onClick={() => setSendCancellationEmail(e => !e)}
            />
            <Typography>Send a cancelation email</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            paddingX="20px"
            paddingY="20px"
          >
            <Button onClick={onPurchaseDeleteClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={onPurchaseDeleteConfirm}
              color="primary"
              variant="contained"
              className={classes.buttonConfirmCancel}
            >
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={openQuickCopyWarningModal}
        confirmLabel="Confirm"
        showWarning
        title="It appears you have entered board information. If you proceed with the 'Quick Copy' action, all the current board information will be wiped."
        handleClose={() => setOpenQuickCopyWarningModal(false)}
        onConfirm={() => {
          setOpenQuickCopyWarningModal(false);
          setOpenCopyTakeoffDatabaseModal(true);
        }}
      />
      <TakeoffDatabaseCopyDialog
        open={openCopyTakeoffDatabaseModal}
        handleClose={() => setOpenCopyTakeoffDatabaseModal(false)}
        updateFloors={updateFloorsAndMaterialsFromBoardExcelFloors}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  purchaseDeleteTitle: {
    textAlign: 'center',
    fontSize: '20px',
  },
  buttonConfirmCancel: {
    marginLeft: '10px',
    height: '40px',
    backgroundColor: '#4caf51',
    color: 'white',
    '&:hover': {
      backgroundColor: '#4caf51',
    },
  },
  warning: {
    fontSize: '40px',
    textAlign: 'center',
    color: '#e6005a',
    marginTop: 20,
  },
  underline: {
    textDecoration: 'underline',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  input: {
    margin: '8px 0px',
  },
  quickCopyButton: {
    color: '#fff',
    textTransform: 'none',
  },
  buttonCancelPO: {
    backgroundColor: '#eb5757',
    marginLeft: '10px',
    height: '40px',
    color: 'white',
    '&:hover': {
      backgroundColor: '#eb5757',
    },
  },
});
