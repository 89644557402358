import React from 'react';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import { SubdivisionDto } from '@vatos-pas/common';
import { useStyles } from './master-sheet-styles';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_CHECK_UNCHECK_HOUSE } from 'providers/permissions';

interface MasterSheetJobInfoProps {
  theJob: any;
  checkHouse: any;
}

const getSubdivisionAddressInfo = (subdivision: SubdivisionDto) => {
  if (!subdivision) return '';
  return `${subdivision.city} - ${subdivision.state} - ${subdivision.zip}`;
};

export const MasterSheetJobInfo: React.FC<MasterSheetJobInfoProps> = ({
  theJob,
  checkHouse,
}) => {
  const classes = useStyles();
  const { hasPermission } = PermissionsProvider.useContainer();
  const inputProps = {
    InputLabelProps: { shrink: true, className: classes.black },
    InputProps: { className: classes.black },
  };

  const getPhaseName = () => {
    if (theJob.job.currentJobPhasePriority === 100) {
      return 'Materials';
    }
    if (theJob.job.currentJobPhasePriority === 200) {
      return 'Garage';
    }
    return theJob.jobPhase.phaseName;
  };

  return (
    <Box className={classes.whiteBox}>
      <Box m={3}>
        <Box display="flex" alignItems="center">
          <TextField
            label="Project Phase"
            fullWidth
            disabled
            className={classes.input}
            value={getPhaseName()}
            {...inputProps}
          />
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Project Approval - Due Date"
            fullWidth
            disabled
            className={classes.inputNoMarginRight}
            value={theJob.job.dateDue}
            onChange={(date: MaterialUiPickersDate) => date}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            {...inputProps}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            label="Builder"
            fullWidth
            {...inputProps}
            disabled
            className={classes.input}
            value={theJob.job.builderName}
          />
          <TextField
            label="Job ID #"
            fullWidth
            {...inputProps}
            disabled
            value={theJob.job.idNumber}
            className={classes.inputNoMarginRight}
          />
        </Box>
        <TextField
          label="Current Supplier"
          fullWidth
          {...inputProps}
          disabled
          className={classes.input}
          value={theJob.job.currentSupplierName}
        />
        <Box display="flex" alignItems="center">
          <TextField
            label="Subdivision"
            fullWidth
            {...inputProps}
            disabled
            className={classes.input}
            value={theJob.job.subdivisionName}
          />
          <TextField
            label="Supervisor"
            fullWidth
            {...inputProps}
            disabled
            className={classes.inputNoMarginRight}
            value={theJob.job.supervisorFullName}
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Box width="100%">
            <TextField
              label="Model"
              fullWidth
              {...inputProps}
              disabled
              className={classes.input}
              value={theJob.job.modelName}
            />
          </Box>
          <Box width="100%" display="flex" pl={2}>
            <TextField
              label="Building #"
              fullWidth
              {...inputProps}
              disabled
              className={classes.input}
              value={theJob.job.buildingNumber}
            />
            <TextField
              label="Lot"
              fullWidth
              {...inputProps}
              disabled
              value={theJob.job.lot}
              className={classes.inputNoMarginRight}
            />
            <TextField
              label="Block"
              fullWidth
              {...inputProps}
              disabled
              value={theJob.job.block}
              className={classes.inputNoMarginRight}
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <TextField
            label="Address"
            fullWidth
            {...inputProps}
            disabled
            value={theJob.job.address1}
            className={classes.input}
          />
          <TextField
            label="City, State, Zip"
            fullWidth
            {...inputProps}
            disabled
            value={getSubdivisionAddressInfo(theJob.job)}
            className={classes.inputNoMarginRight}
          />
        </Box>
        <Box display="flex" width="100%" flexDirection="row">
          <Box width="50%" display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={theJob.job.houseMarked}
                  onChange={(event: any) => checkHouse(event.target.checked)}
                  name="isMonday"
                  disabled={
                    !hasPermission(CAN_CHECK_UNCHECK_HOUSE) ||
                    theJob.job.houseMarked
                  }
                  color="primary"
                />
              }
              label="Mark House"
            />
          </Box>
          <Box width="50%">
            {theJob.job.dateHouseMarked && (
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="House marked on"
                fullWidth
                onChange={() => console.log()}
                // className={classes.dateStarted}
                value={new Date(theJob.job.dateHouseMarked)}
                disabled
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            )}
          </Box>
        </Box>
        <Box mt={2}>
          <Box className={classes.titleTab}>
            <Typography className={classes.titleFont}>Options</Typography>
          </Box>
          {theJob.job.options && (
            <div
              className="preview"
              dangerouslySetInnerHTML={{
                __html: theJob.job.options,
              }}
            ></div>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default MasterSheetJobInfo;
