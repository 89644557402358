import Box from '@material-ui/core/Box';
import { useFormState } from 'react-final-form';
import { Identifier } from 'react-admin';
import { RegionDto, RoleDto } from '@vatos-pas/common';

import CustomSelect from 'components/CustomSelect';

interface RolesAndRegionsInputsProps {
  contractorId: Identifier | null;
  roles: RoleDto[] | null;
  rolesValue: string[];
  setSelectedRoles: (roles: string[]) => void;
  rolesError?: string | null;
  regionsError?: string | null;
  regions: RegionDto[] | null;
  selectedRegions: string[];
  setSelectedRegions: (regions: string[]) => void;
}

const RolesAndRegionsInputs: React.FC<RolesAndRegionsInputsProps> = ({
  contractorId,
  roles,
  rolesValue,
  setSelectedRoles,
  rolesError,
  regionsError,
  regions,
  selectedRegions,
  setSelectedRegions,
}) => {
  const { errors, submitFailed } = useFormState();

  return (
    <Box width="100% !important" display="flex">
      <Box width="100%" mb={2} ml={2}>
        <CustomSelect
          variant="filled"
          disabled={!!contractorId}
          multiple
          resource="region"
          label="Roles"
          options={roles ?? []}
          value={rolesValue}
          onChange={event => setSelectedRoles(event.target.value as string[])}
          error={!!rolesError || (submitFailed && !!errors?.['roles'])}
          helperText={rolesError ?? errors?.['roles']}
        />
      </Box>
      <Box width="100%" ml={3} mr={2}>
        <CustomSelect
          variant="filled"
          multiple
          resource="user"
          label="Regions"
          options={regions ?? []}
          value={selectedRegions}
          onChange={event => setSelectedRegions(event.target.value as string[])}
          error={!!regionsError || (submitFailed && !!errors?.['region'])}
          helperText={regionsError ?? errors?.['region']}
        />
      </Box>
    </Box>
  );
};

export default RolesAndRegionsInputs;
