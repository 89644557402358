import React, { FC, useEffect, useMemo, useState } from 'react';
import { UpdateParams, useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FormHelperText from '@material-ui/core/FormHelperText';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { luxon } from 'services/luxon';
import { axiosInstance } from 'services/http';
import RepairPhotoUpload from './job-repairs-sheet-repair-photo-upload';
import RepairContractorDialog from './job-repairs-sheet-repair-contractor';
import { useStyles } from '../views/master-sheet-styles';
import { Repair, RepairPayment, Image } from '.';
import {
  JobRepairNoteDto,
  JobRepairNoteType,
  SubdivisionBuilderSupervisorDto,
} from '@vatos-pas/common';
import isEqual from 'lodash/isEqual';
import { difference } from 'lodash';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_ADD_REPAIR_CONTRACTOR } from 'providers/permissions';
import isMobileHook from 'hooks/isMobile';
import { Show } from 'components/Show';
import { getFormattedTimeWindow } from '../utils/getFormattedTimeWindow';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getAddContractorsBlocked } from '../utils/getAddContractorsBlocked';
import NotesManagement from './NotesManagement';

type RepairDialogProps = {
  theJob: any;
  subdivisionBuilderSupervisors: SubdivisionBuilderSupervisorDto[] | null;
  isShowView: boolean;
  repair?: Repair;
  handleConfirm(repair: Repair | undefined): Promise<Repair[]>;
  handleClose(): void;
  open: boolean;
  setJob: any;
};

const getPayment = (repairPayment: RepairPayment) => {
  const { approvedHours, estimatedHours, extraHours, fixedAmount } =
    repairPayment;

  const type =
    // If it has `approvedHours` and doesn't have `estimatedHours`
    // then this record was created outside the bumpout timesheet flow, regardless of current subdivision flag.
    approvedHours && !estimatedHours
      ? 'Hours'
      : estimatedHours
      ? 'Estimated Hours'
      : extraHours
      ? 'Extra Hours'
      : fixedAmount
      ? 'Fixed Amount $'
      : 'Per Day';

  const amount =
    approvedHours && !estimatedHours
      ? approvedHours
      : estimatedHours
      ? estimatedHours
      : extraHours
      ? extraHours
      : fixedAmount
      ? fixedAmount
      : '';

  return { type, amount };
};

const validateFields = (fields: any): Record<string, boolean> => {
  const errors: Record<string, boolean> = {};

  if (!fields.repairDate) {
    errors['repairDate'] = true;
  }

  if (
    fields.shouldSelectSubdivisionBuilderSupervisor &&
    fields.selectedBuilderSupervisor.length === 0
  ) {
    errors[`selectedBuilderSupervisor`] = true;
  }

  return errors;
};

// const SUBDIVISION_BUILDER_SUPERVISOR_INITIAL_STATE = [];
const MIN_SUBDIVISION_BUILDER_SUPERVISOR = 1;

// const getSelectedSubdivisionBuilderSupervisorInitialState = (repair: any) => {
//   if (repair?.jobRepairSubdivisionBuilderSupervisors.length) {
//     const ids = repair.jobRepairSubdivisionBuilderSupervisors.map(
//       item => item.subdivisionBuilderSupervisorId,
//     );

//     return ids;
//   }

//   return SUBDIVISION_BUILDER_SUPERVISOR_INITIAL_STATE;
// };

export const RepairDialog: FC<RepairDialogProps> = ({
  open,
  subdivisionBuilderSupervisors,
  isShowView,
  handleClose,
  handleConfirm,
  repair,
  theJob,
}) => {
  const isMobile = isMobileHook();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { hasPermission } = PermissionsProvider.useContainer();
  const classes = useStyles();

  const [saving, setSaving] = useState(false);
  const [selectedRepair, setSelectedRepair] = useState<any>({});
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [addContractorModalOpen, setAddContractorModalOpen] = useState(false);

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [repairDate, setRepairDate] = useState<any>(undefined);
  const [notes, setNotes] = useState('');
  const [photos, setPhotos] = useState<Image[]>([]);
  const [photosStaged, setPhotosStaged] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [payments, setPayments] = useState<RepairPayment[]>([]);
  const [complete, setComplete] = useState(repair?.complete);
  const [deletedPayments, setDeletedPayments] = useState<string[]>([]);
  const [selectedBuilderSupervisor, setSelectedBuilderSupervisor] = useState<
    string[]
  >([]);

  const canAddContractor = hasPermission(CAN_ADD_REPAIR_CONTRACTOR);
  const isAddContractorsBlocked = getAddContractorsBlocked(repair);
  const canEdit = canAddContractor && !repair?.complete;
  const shouldSelectSubdivisionBuilderSupervisor =
    subdivisionBuilderSupervisors &&
    subdivisionBuilderSupervisors?.length > MIN_SUBDIVISION_BUILDER_SUPERVISOR;
  const hasSubdivisionBuilderSupervisorErrors = Object.keys(errors).includes(
    'selectedBuilderSupervisor',
  );
  const currentRepairNote = repair?.text || '';

  const jobRepairSubdivisionBuilderSupervisorsIds = !selectedRepair
    ? []
    : selectedRepair.jobRepairSubdivisionBuilderSupervisors?.map(
        item => item.subdivisionBuilderSupervisorId,
      ) || [];

  const isBumpoutTimesheetFlow = theJob?.job?.reqBumpoutTimesheet;

  // const loadPayments = () => {
  //   const list = repair?.repairPayment.map(
  //     ({
  //       id,
  //       contractorId,
  //       approvedHours,
  //       repairFormulaId,
  //       extraHours,
  //       fixedAmount,
  //       estimatedHours,
  //       contractor,
  //       repairResourceType,
  //       repairPaymentDate,
  //       windowStartTime,
  //       windowEndTime,
  //     }) => ({
  //       id,
  //       contractorId,
  //       approvedHours,
  //       repairFormulaId,
  //       extraHours,
  //       fixedAmount,
  //       estimatedHours,
  //       contractor,
  //       repairResourceType,
  //       repairPaymentDate,
  //       windowStartTime,
  //       windowEndTime,
  //     }),
  //   );

  //   setPayments(list);
  // };

  const loadRepair = async () => {
    setLoading(true);

    try {
      const { data } = await dataProvider.getList<Repair>('job-repair', {
        filter: {
          id: repair?.id || '',
        },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'createdAt', order: 'ASC' },
      });

      const jobRepairItem: any = data[0];

      const { jobRepairPhotos } = jobRepairItem;

      setPhotos(jobRepairPhotos);
      const list = jobRepairItem.repairPayment.map(
        ({
          id,
          contractorId,
          approvedHours,
          repairFormulaId,
          extraHours,
          fixedAmount,
          estimatedHours,
          contractor,
          repairResourceType,
          repairPaymentDate,
          windowStartTime,
          windowEndTime,
        }) => ({
          id,
          contractorId,
          approvedHours,
          repairFormulaId,
          extraHours,
          fixedAmount,
          estimatedHours,
          contractor,
          repairResourceType,
          repairPaymentDate,
          windowStartTime,
          windowEndTime,
        }),
      );

      setPayments(list);

      // Contractor History
      const ids = jobRepairItem.jobRepairSubdivisionBuilderSupervisors.map(
        item => item.subdivisionBuilderSupervisorId,
      );

      setSelectedBuilderSupervisor(ids);

      const repairSelectedIndex = theJob.jobRepairs.findIndex(
        repairItem => repairItem.id === repair?.id,
      );
      theJob.jobRepairs[repairSelectedIndex] = {
        ...theJob.jobRepairs[repairSelectedIndex],
        ...jobRepairItem,
      };

      setSelectedRepair(jobRepairItem);
      setRepairDate(new Date(jobRepairItem?.repairDate));

      setNotes(repair?.text || '');
    } catch (error) {
      notify('Failed to load repair photos', 'error');
    } finally {
      setLoading(false);
    }
  };

  // const loadPhotos = async () => {
  //   try {
  //     const { data } = await dataProvider.getOne<Repair>('job-repair', {
  //       id: repair?.id || '',
  //     });
  //     const { jobRepairPhotos } = data;

  //     setPhotos(jobRepairPhotos);
  //   } catch (error) {
  //     notify('Failed to load repair photos', 'error');
  //   }
  // };

  const stagePhoto = (fileName: string) =>
    setPhotosStaged([...photosStaged, fileName]);

  const onDeleteContractorClick = (payment: RepairPayment, index: number) => {
    const newPayments = [...payments];
    newPayments.splice(index, 1);
    setPayments(newPayments);

    if (payment.id) {
      setDeletedPayments(payments => [...payments, payment.id]);
    }
  };

  const handleSubdivisionBuilderSupervisorChange = (id: string) => {
    setSelectedBuilderSupervisor(prevState => {
      const newState = [...prevState];

      if (!newState.includes(id)) {
        newState.push(id);
      } else {
        return newState.filter(item => item !== id);
      }

      return newState;
    });
  };

  const onAddContractorClick = () => setAddContractorModalOpen(true);

  const handleUploadClick = () => {
    setUploadModalOpen(true);
  };

  const onConfirmClick = async () => {
    try {
      const newErrors = validateFields({
        repairDate,
        selectedBuilderSupervisor,
        shouldSelectSubdivisionBuilderSupervisor,
      });
      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) return;

      setSaving(true);
      const promises: Promise<any>[] = [];

      photosStaged.forEach((photo: any) => {
        const data = {
          jobRepairId: repair?.id,
          fileName: photo.fileName,
          repairPhotoType: 'Photo',
        };
        promises.push(axiosInstance.post('job-repair-photo', data));
      });

      if (currentRepairNote !== notes) {
        promises.push(
          dataProvider.create<JobRepairNoteDto>('/job-repair-note', {
            data: {
              text: notes.trim(),
              jobRepairId: repair?.id,
              jobRepairNoteType: JobRepairNoteType.DESCRIPTION,
            },
          }),
        );
      }

      await Promise.all(promises);

      const insert = payments
        .filter(payment => !payment.id)
        .map(payment => {
          const {
            repairFormulaId,
            contractorId,
            estimatedHours,
            fixedAmount,
            extraHours,
            regionTimeWindowId,
            repairPaymentDate,
          } = payment;
          return {
            jobRepairId: repair?.id,
            repairFormulaId,
            contractorId,
            estimatedHours,
            fixedAmount,
            extraHours,
            regionTimeWindowId,
            repairPaymentDate,
          };
        });

      const batchParams = { insert, delete: deletedPayments };
      await axiosInstance.post('/repair-payment/batch-process', batchParams);

      const { data: previousData } = await dataProvider.getOne('job-repair', {
        id: repair?.id || '',
      });

      const deleteSubdivisionBuilderSupervisorsIds = difference(
        jobRepairSubdivisionBuilderSupervisorsIds,
        selectedBuilderSupervisor,
      );

      const hasInsertDuplicates =
        new Set(selectedBuilderSupervisor).size !==
        selectedBuilderSupervisor.length;

      const insertSubdivisionBuilderSupervisorsIds = hasInsertDuplicates
        ? selectedBuilderSupervisor
        : difference(
            selectedBuilderSupervisor,
            jobRepairSubdivisionBuilderSupervisorsIds,
          );

      const params: UpdateParams = {
        id: repair?.id || '',
        previousData,
        data: {
          complete,
          repairDate,
          ...(shouldSelectSubdivisionBuilderSupervisor && {
            subdivisionBuilderSupervisor: {
              insert: insertSubdivisionBuilderSupervisorsIds,
              delete: deleteSubdivisionBuilderSupervisorsIds,
            },
          }),
        },
      };

      await dataProvider.update('/job-repair', params);

      handleClose();
      await handleConfirm(repair);
    } catch (error) {
      setSaving(false);
      notify(
        `Repair update Error: ${
          error?.response?.data?.message || error.message
        }`,
        'warning',
      );
    }
  };

  const hasChanges = useMemo(() => {
    const hasCompleteChange = selectedRepair?.complete !== complete;
    const hasRepairDateChange = !luxon.isSameDay(
      luxon.date(selectedRepair?.repairDate),
      luxon.date(repairDate),
    );
    const hasNewContractors = payments.some(payment => !payment.id);
    const hasDeletedContractors = deletedPayments.length > 0;
    const hasNewPhotos = photosStaged.length > 0;
    const hasBuilderSupervisorChange = !isEqual(
      selectedBuilderSupervisor,
      jobRepairSubdivisionBuilderSupervisorsIds,
    );
    const hasNotesChanges = currentRepairNote !== notes;

    return (
      hasCompleteChange ||
      hasRepairDateChange ||
      hasNewContractors ||
      hasDeletedContractors ||
      hasNewPhotos ||
      hasNotesChanges ||
      hasBuilderSupervisorChange
    );
  }, [
    repair,
    jobRepairSubdivisionBuilderSupervisorsIds,
    selectedBuilderSupervisor,
    complete,
    deletedPayments,
    currentRepairNote,
    payments,
    repairDate,
    photosStaged,
  ]);

  useEffect(() => {
    loadRepair();
  }, [repair]);

  // const onChangeContractorDate = index => value => {
  //   const newPayments = [...payments];
  //   newPayments[index].repairPaymentDate = value;
  //   setPayments(newPayments);
  // };
  console.log('selected', selectedRepair);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">Repairs</DialogTitle>
      <Show
        condition={!loading}
        fallback={
          <DialogContent>
            <Box display="flex" justifyContent="center" padding={4}>
              <CircularProgress size={24} />
            </Box>
          </DialogContent>
        }
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-desc9ription">
            <Box
              width={isMobile ? '100%' : '850px'}
              display="flex"
              alignItems={isMobile ? 'initial' : 'center'}
              flexDirection={isMobile ? 'column' : 'row'}
              justifyContent="space-between"
              className={classes.info}
            >
              <Box flex={1}>
                <Typography className={classes.label}>Repair Type</Typography>
                <Typography>
                  {selectedRepair?.repair?.repairType || '-'}
                </Typography>
              </Box>
              <Box flex={2} mt={isMobile ? '-8px' : undefined}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="PO Request Date"
                  fullWidth
                  error={errors.repairDate}
                  value={repairDate}
                  onChange={selected => {
                    if (!selected) return;
                    setRepairDate(selected.toJSDate());
                  }}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  disabled={repair?.complete}
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              my={2}
              className={classes.info}
            >
              <Box flex={2}>
                <Typography className={classes.label}>Subdivision</Typography>
                <Typography>{theJob?.job?.subdivisionName || '-'}</Typography>
              </Box>
              <Box flex={1}>
                <Typography className={classes.label}>Lot</Typography>
                <Typography>
                  {theJob?.job?.lot ? theJob?.job?.lot?.padStart(4, '0') : '-'}
                </Typography>
              </Box>
              <Box flex={1}>
                <Typography className={classes.label}>Building #</Typography>
                <Typography>
                  {theJob?.job?.buildingNumber
                    ? theJob?.job?.buildingNumber?.padStart(3, '0')
                    : '-'}
                </Typography>
              </Box>
            </Box>
            {subdivisionBuilderSupervisors &&
              shouldSelectSubdivisionBuilderSupervisor && (
                <Box display="flex" alignItems="center" maxWidth={648}>
                  <FormControl
                    fullWidth
                    className={classes.builderSupervisorFormControl}
                    error={hasSubdivisionBuilderSupervisorErrors}
                    component="fieldset"
                  >
                    <FormLabel component="legend">Builder Supervisor</FormLabel>
                    <FormGroup className={classes.builderSupervisorFormGroup}>
                      <Grid container>
                        {subdivisionBuilderSupervisors?.map(item => (
                          <Grid key={item.id} item xs={isMobile ? 6 : 4}>
                            <FormControlLabel
                              style={{
                                flexGrow: 1,
                              }}
                              control={
                                <Checkbox
                                  disabled={!canEdit}
                                  onChange={() =>
                                    handleSubdivisionBuilderSupervisorChange(
                                      item.id,
                                    )
                                  }
                                />
                              }
                              checked={selectedBuilderSupervisor.includes(
                                item.id,
                              )}
                              label={`${item.user?.firstName} ${item.user?.lastName}`}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </FormGroup>
                    {hasSubdivisionBuilderSupervisorErrors &&
                      !selectedBuilderSupervisor?.length && (
                        <FormHelperText error>
                          Please select at least one Builder Supervisor before
                          creating the repair.
                        </FormHelperText>
                      )}
                  </FormControl>
                </Box>
              )}

            <Box mt={2} display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="space-between"
                className={classes.info}
              >
                <Box>
                  <Typography className={classes.label}>PO Total $</Typography>
                  <Typography>{repair?.poTotal}</Typography>
                </Box>
                <Box ml={3}>
                  <FormControlLabel
                    disabled
                    control={<Switch />}
                    checked={selectedRepair?.nonBillable}
                    label="Non-billable"
                  />
                </Box>
              </Box>
              <Box
                justifyContent="flex-end"
                display="flex"
                flex={1}
                gridGap={8}
              >
                <IconButton onClick={handleUploadClick}>
                  <CloudUploadIcon className={classes.iconsUpload} />
                </IconButton>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" mt={2}>
              <NotesManagement
                label="Description"
                enableHistory
                disabled={isShowView}
                value={notes}
                onChange={event => setNotes(event.target.value)}
                jobRepairId={repair?.id}
              />
            </Box>
            <Box className={classes.table} mt={2}>
              <Box className={classes.tableHeader}>
                <Typography>Contractors</Typography>
                {!repair?.complete && (
                  <IconButton
                    style={{
                      padding: 0,
                    }}
                    disabled={isAddContractorsBlocked || loading}
                    onClick={onAddContractorClick}
                  >
                    <AddIcon
                      className={
                        isAddContractorsBlocked || loading
                          ? classes.iconAddDisabled
                          : classes.iconAdd
                      }
                    />
                  </IconButton>
                )}
              </Box>
              {payments.map((payment, i) => {
                const { amount, type } = getPayment(payment);
                return (
                  <Box
                    key={`repair-payment-${i.toString()}`}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    gridGap={16}
                    className={classes.tableLine}
                  >
                    {!repair?.complete && (
                      <Box onClick={() => onDeleteContractorClick(payment, i)}>
                        <DeleteIcon className={classes.iconDelete} />
                      </Box>
                    )}
                    <Box flex={2}>
                      <Typography className={classes.label}>
                        Resource
                      </Typography>
                      <Typography>{payment.repairResourceType}</Typography>
                    </Box>
                    <Box flex={3}>
                      <Typography className={classes.label}>
                        Contractor
                      </Typography>
                      <Typography>{payment.contractor?.name}</Typography>
                    </Box>
                    <Box flex={2}>
                      <Typography className={classes.label}>{type}</Typography>
                      <Typography>{amount}</Typography>
                    </Box>
                    <Box flex={2}>
                      {/* {payment.id ? ( */}
                      <>
                        <Typography className={classes.label}>
                          Repair Date
                        </Typography>
                        <Typography>
                          {payment.repairPaymentDate
                            ? new Date(
                                payment.repairPaymentDate,
                              ).toLocaleDateString('en-US')
                            : '-'}
                        </Typography>
                      </>
                      {/* ) : (
                      <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="MM/dd/yyyy"
                        margin="normal"
                        id="date-picker-inlines"
                        label="Repair Date"
                        fullWidth
                        // error={errors.repairDate}
                        value={payment.repairPaymentDate}
                        onChange={onChangeContractorDate(i)}
                        KeyboardButtonProps={{ 'aria-label': 'change date' }}
                      />
                    )} */}
                    </Box>
                    <Show
                      condition={
                        isBumpoutTimesheetFlow &&
                        !!payment?.windowStartTime &&
                        !!payment?.windowEndTime
                      }
                    >
                      {payment?.windowStartTime && payment?.windowEndTime && (
                        <Box flex={3}>
                          <Typography className={classes.label}>
                            Time Window
                          </Typography>
                          <Typography>
                            {getFormattedTimeWindow(
                              payment.windowStartTime,
                              payment.windowEndTime,
                            )}
                          </Typography>
                        </Box>
                      )}
                    </Show>
                  </Box>
                );
              })}
            </Box>
            <Show condition={isAddContractorsBlocked}>
              <Typography
                style={{
                  marginTop: 8,
                }}
              >
                Contractor is not able to be assigned until the PO has been
                released.
              </Typography>
            </Show>

            <Box display="flex" alignItems="center" mt={2}>
              <Checkbox
                disabled={repair?.complete}
                checked={complete}
                onClick={() => setComplete(c => !c)}
              />
              <Typography>Repair Complete</Typography>
            </Box>
          </DialogContentText>
          <Box display="flex" justifyContent="space-between" mt={4} mb={2}>
            <Button
              color="default"
              variant="contained"
              className={classes.buttonRepairsCancel}
              onClick={handleClose}
            >
              {repair?.complete ? 'Close' : 'Cancel'}
            </Button>
            {!repair?.complete && (
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonRepairs}
                onClick={onConfirmClick}
                disabled={saving || !hasChanges}
              >
                Confirm
              </Button>
            )}
          </Box>
        </DialogContent>
        {uploadModalOpen && (
          <RepairPhotoUpload
            open
            handleClose={() => setUploadModalOpen(false)}
            setPhotosStaged={stagePhoto}
            photosStaged={photosStaged}
            photos={photos}
            readOnly={!!repair?.complete}
          />
        )}
        {addContractorModalOpen && selectedRepair && (
          <RepairContractorDialog
            open
            theJob={theJob}
            repair={selectedRepair}
            handleClose={() => setAddContractorModalOpen(false)}
            handleConfirm={newPayment =>
              setPayments(oldPayments => [...oldPayments, newPayment])
            }
          />
        )}
      </Show>
    </Dialog>
  );
};

export default RepairDialog;
