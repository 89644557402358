import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/file-input/dist/style.css';

import React, { FC, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { Dashboard, useUppy } from '@uppy/react';
import { Uppy } from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import { makeStyles } from '@material-ui/core/styles';

import { dataProvider } from 'providers/dataProvider';
import ImageViewerRepair from './repair-image-viewer';
import { Image } from '.';

declare module '@uppy/utils' {
  export interface UppyFile {
    fileName: string;
  }
}

const useStyles = makeStyles({
  img: {
    marginTop: '10px',
    width: '80px',
    marginRight: '10px',
    cursor: 'pointer',
  },
  dialog: {
    height: '90vh',
  },
  uploader: {
    height: '30vh',
  },
});

type RepairPhotoUploadProps = {
  open: boolean;
  handleClose(): void;
  photos: Image[];
  photosStaged: any[];
  setPhotosStaged(name: any): void;
  readOnly?: boolean;
};

export const RepairPhotoUpload: FC<RepairPhotoUploadProps> = ({
  open,
  handleClose,
  photos,
  photosStaged,
  setPhotosStaged,
  readOnly = false,
}) => {
  const classes = useStyles();
  const [imageViewerOpen, setImageViewerOpen] = useState<boolean>(false);
  const [imageSelected, setImageSelected] = useState<any>();

  const onBeforeUpload = files => {
    Object.keys(files).map(key => {
      files[key].fileName = `job-repair-photo-${new Date().getTime()}-${
        files[key].name
      }`;
    });
    return files;
  };

  const uppy = useUppy(() => {
    return new Uppy({
      autoProceed: true,
      onBeforeUpload,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif'],
      },
    }).use(AwsS3, {
      getUploadParameters(file) {
        return dataProvider
          .create('/uploads/sign', {
            data: { contentType: file.type, objectName: `${file.fileName}` },
          })
          .then(({ data }) => {
            return {
              method: 'PUT',
              url: data.signedUrl,
              fields: data.fields,
              headers: { 'Content-Type': file.type },
            };
          });
      },
    });
  });

  useEffect(() => {
    uppy.on('complete', _response => {
      _response.successful.map(itemResponse => {
        setPhotosStaged({
          fileName: itemResponse.fileName,
          fullUrl: itemResponse.uploadURL,
        });
        handleClose();
      });
      uppy.reset();
    });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-ti9tle">Photos</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          {!readOnly && (
            <Dashboard
              disableThumbnailGenerator
              showLinkToFileUploadResult={false}
              uppy={uppy}
              height={100}
            />
          )}
        </DialogContentText>
        <Box>
          <Box>
            {photos.map(photo => (
              <img
                key={photo.id}
                className={classes.img}
                src={photo.fileUrl}
                onClick={() => {
                  setImageSelected(photo.fileUrl);
                  setImageViewerOpen(true);
                }}
              />
            ))}
            {photosStaged.map((repairPhoto, index) => {
              return (
                <img
                  key={index}
                  className={classes.img}
                  onClick={() => {
                    setImageSelected(repairPhoto);
                    setImageViewerOpen(true);
                  }}
                  src={repairPhoto.fullUrl}
                />
              );
            })}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
      <ImageViewerRepair
        imageSelected={imageSelected}
        open={imageViewerOpen}
        handleClose={() => setImageViewerOpen(false)}
      />
    </Dialog>
  );
};

export default RepairPhotoUpload;
