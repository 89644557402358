import jsonExport from 'jsonexport/dist';
import { downloadCSV } from 'react-admin';
import cloneDeep from 'lodash/cloneDeep';
import { AppRole } from './roles';

const idProperties = [
  'id',
  'approvingUserId',
  'boardPurchaseId',
  'boardPurchaseFloorId',
  'boardReceiptFloorId',
  'boardTypeId',
  'builderId',
  'buildingId',
  'contractorId',
  'contractorRegionId',
  'jobId',
  'jobPhaseId',
  'jobPhasePhotoId',
  'materialId',
  'modelId',
  'phaseId',
  'previousBumpContractorId',
  'regionId',
  'roleId',
  'subdivisionId',
  'supervisorUserId',
  'supplierId',
  'supplierRegionId',
  'userId',
  'userRegionId',
  'userRoleId',
];

const JobPhaseSprayReadyHourEnum = {
  8: '8am',
  9: '9am',
  10: '10am',
  11: '11am',
  12: '12pm',
  13: '1pm',
  14: '2pm',
};

export const exporter =
  list => async (items, _fetchRelatedRecords, dataProvider) => {
    let processedItems = items;

    if (list === 'bumpout-timesheet') {
      const supervisorUserIds = Array.from(
        new Set(items.map(item => item.supervisorUserId).filter(Boolean)),
      );

      if (supervisorUserIds.length > 0) {
        const { data } = await dataProvider.getList('user', {
          select: ['id', 'firstName', 'lastName'], // Explicitly include id
          filter: {
            s: {
              $and: [
                { id: { $in: supervisorUserIds } },
                { 'userRoles.role.name': { $eq: AppRole.Supervisor } },
              ],
            },
          },
          pagination: { page: 1, perPage: supervisorUserIds.length }, // Only fetch what we need
          sort: { field: 'id', order: 'ASC' },
        });

        const supervisorNameMap = data.reduce((acc, supervisor) => {
          if (supervisor?.id) {
            acc[supervisor.id] = `${supervisor.firstName || ''} ${
              supervisor.lastName || ''
            }`.trim();
          }
          return acc;
        }, {});

        processedItems = items.map(item => {
          const newItem: Record<string, any> = {};

          for (const key in item) {
            newItem[key] = item[key];

            // After adding contractorName, add supervisorFullname
            if (key === 'contractorName') {
              newItem['supervisorFullname'] = item.supervisorUserId
                ? supervisorNameMap[item.supervisorUserId] || ''
                : '';
            }
          }

          return newItem;
        });
      }
    }

    const itemsForExport = cloneDeep(processedItems).map((item: any) => {
      Object.keys(item).map((key: string) => {
        if (item[key] && typeof item[key] === 'object' && item[key]?.id) {
          delete item[key].id;
        }
      });

      for (const fkIdProperty of idProperties) {
        if (fkIdProperty in item) {
          delete item[fkIdProperty];
        }
      }

      if (item.userRegions) {
        item.regions = item.userRegions
          .map((region: any) => region.region.name)
          .join(', ');
        delete item.userRegions;
      }
      if (item.userRoles) {
        item.roles = item.userRoles
          .map((role: any) => role.role.name)
          .join(', ');
        delete item.userRoles;
      }
      if (item.supplierRegions) {
        item.regions = item.supplierRegions
          .map((region: any) => region.region.name)
          .join(', ');
        delete item.supplierRegions;
      }
      if (item.contractorRegions) {
        item.regions = item.contractorRegions
          .map((region: any) => region.region.name)
          .join(', ');
        delete item.contractorRegions;
      }

      if (list === 'rm-master-sheet') {
        if (item.jobPhaseReadyHour) {
          item.sprayReadyHour =
            JobPhaseSprayReadyHourEnum[item.jobPhaseReadyHour];
        } else {
          item.sprayReadyHour = '';
        }
        delete item.jobPhaseReadyHour;
      }

      if (list === 'pre-rock-sheet') {
        delete item.jobNotes;
      }

      if (list === 'routes') {
        delete item['subdivisions'];
      }

      if (list === 'master-sheet') {
        item.payableBoards = item.payableBoardsTotal;
        delete item.payableBoardsTotal;
      }

      return item;
    });

    jsonExport(itemsForExport, (_err, csv) => {
      downloadCSV(csv, list);
    });
  };
