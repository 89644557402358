/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-debugger */
import React, { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { useNotify, GetOneResult, useRedirect } from 'react-admin';
import map from 'lodash/map';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import {
  FactCheck,
  Handyman,
  HomeRepairService,
  StickyNote2,
} from 'components/Icons';
import ConfirmDialog from 'components/ConfirmDialog';
import MobileMasterSheetJobInfo from './mobile/master-sheet-job-info';
import { dataProvider } from 'providers/dataProvider';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_EDIT_NOTES, CAN_EDIT_OPTIONS } from 'providers/permissions';
import MasterSheetUpload from './master-sheet-upload';
import {
  BoardFloorDto,
  BoardFloorInterface,
  BoardPurchaseDto,
  BoardTypeDto,
  BoardTypeInterface,
  ContractorDto,
  JobDto,
  JobNoteDto,
  JobPhaseDto,
  JobPhasePhotoDto,
  JobPhaseUpdateDto,
  ModelDto,
  PhaseDto,
  PhaseTypeEnum,
  SubdivisionDto,
  UserDto,
} from '@vatos-pas/common';
import { Point } from 'geojson';
import MasterSheetBoards from './master-sheet-boards';
import MasterSheetTimeline from './master-sheet-timeline';
import MobileMasterSheetTimeline, {
  BUMP_PHASES,
} from './mobile/master-sheet-timeline';
import MasterSheetStatusBar from './master-sheet-status-bar';
import MasterSheetJobInfo from './master-sheet-job-info';
import MasterSheetRepairs from './master-sheet-repairs';
import MobileMasterSheetRepairs from './mobile/master-sheet-repairs';
import { useStyles } from './master-sheet-styles';

import isMobileHook from './../../../hooks/isMobile';

export enum TabEnum {
  Boards = 'boards',
  Timeline = 'timeline',
  Notes = 'notes',
  Repairs = 'repairs',
}

export interface FloorListRow {
  row: number;
  boards: {
    [index: string]: Pick<
      BoardFloorInterface,
      'boardTypeId' | 'floor' | 'quantity'
    >;
  };
}

export interface FloorListItem {
  floor: BoardFloorInterface['floor'];
  rows: FloorListRow[];
}

export interface BoardRows {
  [index: number]: { rows: { [index: number]: BoardFloorDto[] } };
  items?: BoardFloorDto[];
}

export interface Job {
  job: any;
  subdivision?: SubdivisionDto;
  supervisorUser?: UserDto;
  supervisors: UserDto[];
  phases: PhaseDto[];
  jobPhase: JobPhaseDto;
  jobPhases: JobPhaseDto[];
  boardTypes: BoardTypeDto[];
  boardPurchases: BoardPurchaseDto[];
  boardReceiptFloors: BoardFloorDto[];
  boardRows: BoardRows;
  contractors: ContractorDto[];
  jobPhasePhotos: JobPhasePhotoDto[];
  jobNotes: JobNoteDto[] | null;
  model: ModelDto;
  options: any[];
  jobRepairs: any[];
  repairFormula: any[];
}

const getJob = async (
  setJob: any,
  id: string,
  setToggleGarage: any,
  setTabSelected: (tab: TabEnum) => void,
  setLoadingJob: any,
  setLoadingTimeline,
  setJobLotError,
  setLoadingBoards,
) => {
  setJob(null);
  // set up some vars
  let job: any;
  const model: any = {};
  const subdivision: any = {};
  const supervisorUser: any = {};
  const boardTypes: BoardTypeDto[] = [];
  const boardPurchases: any[] = [];
  const boardReceiptFloors: BoardFloorDto[] = [];
  const phases: PhaseDto[] = [];
  const jobPhase: any = {};
  const jobPhases: JobPhaseDto[] = [];
  const contractors: ContractorDto[] = [];
  const supervisors: UserDto[] = [];
  const jobPhasePhotos: JobPhasePhotoDto[] = [];
  const boardRows: BoardRows = { items: [] };
  const options: any = [];
  const jobOptions: any = [];
  const jobRepairs: any = [];
  const repairFormula: any = [];
  const jobNotes: JobNoteDto[] | null = null;

  // try to get the job and model
  // const jobResult = await dataProvider.getOne<JobDto>('job', { id });

  // if (jobResult.data) {
  //   job = jobResult.data;
  //   if (job?.jobPhase) {
  //     jobPhase = job.jobPhase;
  //   } else {
  //     throw new Error('Board Purchases not found on job');
  //   }
  // } else {
  //   throw new Error('Job not found');
  // }

  // try to get the subdivision
  // const subdivisionResult = await dataProvider.getOne<SubdivisionDto>(
  //   'subdivision',
  //   { id: model.subdivisionId },
  // );

  // if (subdivisionResult.data) {
  //   subdivision = subdivisionResult.data;
  // } else {
  //   throw new Error('Subdivision not found');
  // }

  // const supervisorResult = await dataProvider.getOne<UserDto>('user', {
  //   id: subdivision.supervisorUserId,
  // });

  // if (supervisorResult.data) {
  //   supervisorUser = supervisorResult.data;
  // } else {
  //   throw new Error('Supervisor not found');
  // }

  // const phasesResult = await dataProvider.getList<PhaseDto>('phase', {
  //   filter: {},
  //   pagination: { page: 1, perPage: 100 },
  //   sort: { field: 'id', order: 'ASC' },
  // });

  // if (phasesResult.data) {
  //   phases = phasesResult.data;
  // } else {
  //   throw new Error('Phases not found');
  // }

  // const jobPhasesResult = await dataProvider.getList<JobPhaseDto>('job-phase', {
  //   filter: { jobId: id },
  //   pagination: { page: 1, perPage: 100 },
  //   sort: { field: 'id', order: 'ASC' },
  // });

  // const jobNotesResponse = await dataProvider.getList<JobNoteDto>('job-note', {
  //   filter: { jobId: id },
  //   pagination: { page: 1, perPage: 100 },
  //   sort: { field: 'createdAt', order: 'DESC' },
  // });

  // if (jobNotesResponse.data) {
  //   jobNotes = jobNotesResponse.data;
  // }

  // if (jobPhasesResult.data) {
  //   jobPhases = jobPhasesResult.data;
  //   const contractorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
  //     jobPhase.contractorId ? jobPhase.contractorId : '',
  //   );

  //   const firstContractorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
  //     jobPhase.initialContractorId ? jobPhase.initialContractorId : '',
  //   );

  //   const allContractors = [
  //     ...new Set([...firstContractorsMapped, ...contractorsMapped]),
  //   ];

  //   const supervisorsMapped = jobPhases.map((jobPhase: JobPhaseDto) =>
  //     jobPhase.approvingUserId ? jobPhase.approvingUserId : '',
  //   );

  //   const jobPhasesMapped = jobPhases.map((phase: JobPhaseDto) => phase.id);
  //   const contractorsResult = await Promise.all(
  //     allContractors.map((contractorId: string) => {
  //       if (contractorId) {
  //         return dataProvider.getOne<ContractorDto>('contractor', {
  //           id: contractorId,
  //         });
  //       }
  //     }),
  //   );
  //   contractorsResult.map(
  //     (contractorData: GetOneResult<ContractorDto> | undefined) => {
  //       if (contractorData) {
  //         contractors.push(contractorData.data);
  //       }
  //     },
  //   );
  //   const phasePhotosResult = await Promise.all(
  //     jobPhasesMapped.map((jobPhaseId: string) => {
  //       return dataProvider.getList('job-phase-photo', {
  //         filter: { 'jobPhaseId||$eq||': jobPhaseId },
  //         pagination: { page: 1, perPage: 100 },
  //         sort: { field: 'id', order: 'ASC' },
  //       });
  //     }),
  //   );
  //   phasePhotosResult.map((jobPhaseData: any) => {
  //     if (jobPhaseData) {
  //       jobPhasePhotos = jobPhasePhotos.concat(jobPhaseData.data);
  //     }
  //   });

  //   const supervisorsResult = await Promise.all(
  //     supervisorsMapped.map((supervisorId: string) => {
  //       if (supervisorId) {
  //         return dataProvider.getOne<UserDto>('user', {
  //           id: supervisorId,
  //         });
  //       }
  //     }),
  //   );
  //   supervisorsResult.map(
  //     (supervisorData: GetOneResult<UserDto> | undefined) => {
  //       if (supervisorData) {
  //         supervisors.push(supervisorData.data);
  //       }
  //     },
  //   );
  // } else {
  //   throw new Error('Job phases not found');
  // }

  // const boardPurchasesCall = await dataProvider.getList<BoardPurchaseDto>(
  //   'board-purchase',
  //   {
  //     filter: { jobId: id },
  //     pagination: { page: 1, perPage: 100 },
  //     sort: { field: 'createdAt', order: 'ASC' },
  //   },
  // );

  // if (boardPurchasesCall.data) {
  //   boardPurchases = boardPurchasesCall.data.sort((a, b) =>
  //     a.createdAt < b.createdAt ? -1 : 0,
  //   );
  // } else {
  //   throw new Error('Board Purchases not found');
  // }

  // const jobRepairsCall = await dataProvider.getList<PhaseDto>('job-repair', {
  //   filter: {
  //     jobId: id,
  //     $join: [{ field: 'jobRepairNotes' }],
  //   },
  //   pagination: { page: 1, perPage: 100 },
  //   sort: { field: 'createdAt', order: 'ASC' },
  // });

  // if (jobRepairsCall.data) {
  //   jobRepairs = jobRepairsCall.data.sort((a, b) =>
  //     a.createdAt < b.createdAt ? -1 : 0,
  //   );
  // } else {
  //   throw new Error('Board Purchases not found');
  // }

  // const materialsPhase = getPhase(
  //   job,
  //   phases,
  //   jobPhases,
  //   PhaseTypeEnum.Materials,
  // );

  // if (materialsPhase && materialsPhase.approved) {
  //   const boardPurchaseIdList = [
  //     ...new Set(
  //       boardPurchases.map((boardPurchase: BoardFloorDto) => boardPurchase.id),
  //     ),
  //   ];
  //   const boardReceiptFloorsResult = await Promise.all(
  //     boardPurchaseIdList.map((boardPurchaseId: BoardTypeInterface['id']) =>
  //       dataProvider.getList<BoardFloorDto>('board-receipt-floor', {
  //         filter: { boardPurchaseId },
  //         pagination: { page: 1, perPage: 200 },
  //         sort: { field: 'id', order: 'ASC' },
  //       }),
  //     ),
  //   );

  //   if (boardReceiptFloorsResult) {
  //     boardReceiptFloorsResult.map(
  //       (boardReceiptFloor: any) =>
  //         (boardReceiptFloors = boardReceiptFloors.concat(
  //           boardReceiptFloor.data,
  //         )),
  //     );
  //   }
  // }

  // const optionsCall = await dataProvider.getList<any>('option', {
  //   filter: { isInternal: true },
  //   pagination: { page: 1, perPage: 100 },
  //   sort: { field: 'createdAt', order: 'ASC' },
  // });

  // const jobOptionsCall = await dataProvider.getList<PhaseDto>('job-option', {
  //   filter: { jobId: id },
  //   pagination: { page: 1, perPage: 100 },
  //   sort: { field: 'createdAt', order: 'ASC' },
  // });

  // if (jobOptionsCall.data) {
  //   jobOptions = jobOptionsCall.data;
  // } else {
  //   throw new Error('Job Options not found');
  // }

  // if (jobOptions.length) {
  //   setOptionsSelected(jobOptions.map((jobOption: any) => jobOption.optionId));
  // }

  // if (optionsCall.data) {
  //   options = optionsCall.data;
  // } else {
  //   throw new Error('Options not found');
  // }

  // build the "new job"
  const newJob: Job = {
    job,
    supervisors,
    jobPhase,
    jobPhases,
    boardRows,
    boardTypes,
    boardPurchases,
    boardReceiptFloors,
    model,
    phases,
    subdivision,
    supervisorUser,
    contractors,
    jobPhasePhotos,
    options,
    jobRepairs,
    repairFormula,
    jobNotes,
  };

  // const currentPhase = phases.find(
  //   (phase: PhaseDto) => phase.id === jobPhase.phaseId,
  // );

  // const tabSelected =
  //   currentPhase?.phaseType === PhaseTypeEnum['Materials']
  //     ? TabEnum.Boards
  //     : TabEnum.Timeline;
  setJob(newJob);
  getJobLot({
    jobId: id,
    setJob,
    theJob: newJob,
    setLoadingJob,
    setTabSelected,
    setToggleGarage,
    skipLoading: false,
    setLoadingTimeline,
    setJobLotError,
    setLoadingBoards,
  });
};

const refreshNotes = (setJob: any, theJob: any) => async () => {
  let jobNotes: JobNoteDto[] | null = null;
  const jobNotesResponse = await dataProvider.getList<JobNoteDto>('job-note', {
    filter: { jobId: theJob.job.id },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
  });

  if (jobNotesResponse.data) {
    jobNotes = jobNotesResponse.data;
  } else {
    throw new Error('Job notes not found');
  }
  setJob({ ...theJob, jobNotes });
};

const refreshRepairs =
  (setJob: any, theJob: any, setLoadingRepairs: any) => async () => {
    return getRepairsLot({
      jobId: theJob.job.id,
      theJob,
      setJob,
      setLoadingRepairs,
    });
  };

const refreshJobCall = async (setJob: any, id: string, theJob: any) => {
  const newJob = { ...theJob };
  const jobResult = await dataProvider.getOne<any>('job-lot-report', {
    id,
  });
  newJob.job = {
    ...newJob.job,
    ...jobResult.data,
  };
  setJob(newJob);
};

const getPhase = (
  job: JobDto,
  phases: PhaseDto[],
  jobPhases: JobPhaseDto[],
  phaseType: PhaseTypeEnum,
): any => {
  if (job && phases) {
    const jobPhase = jobPhases.find(
      (item: any) => item.phaseType === phaseType,
    );
    if (jobPhase) {
      return jobPhase;
    }
  }

  throw new Error('Job phase not found.');
};

// Refactor on API calls Start here

// New getJob call
const getJobLot = async ({
  jobId,
  theJob,
  setJob,
  setLoadingJob,
  setTabSelected,
  setToggleGarage,
  skipLoading = true,
  setLoadingTimeline,
  setJobLotError,
  setLoadingBoards,
}) => {
  const newJob = { ...theJob };
  let jobResult;
  try {
    jobResult = await dataProvider.getOne<any>('job-lot-report', {
      id: jobId,
    });
  } catch (err) {
    return setJobLotError(true);
  }
  newJob.job = {
    ...newJob.job,
    ...jobResult.data,
  };

  if (newJob.job.currentJobPhasePriority > 100) {
    getTimelineData({
      jobId,
      theJob: newJob,
      setJob,
      setLoadingJob,
      setToggleGarage,
      skipLoading,
      setLoadingTimeline,
    });
    setTabSelected(TabEnum.Timeline);
  } else {
    getBoardsData({
      jobId,
      theJob: newJob,
      setJob,
      setLoadingJob,
      skipLoading,
      setLoadingBoards,
    });
    setTabSelected(TabEnum.Boards);
  }
};

const getJobPhases = async ({
  jobId,
  theJob,
  setJob,
  setLoadingJob,
  refresh = false,
  setToggleGarage,
  setLoadingTimeline,
}) => {
  const newJob = { ...theJob };
  const jobPhasesResult = await dataProvider.getList<any>(
    'job-lot-phase-report',
    {
      filter: { jobId },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
    },
  );
  const phasesArray = jobPhasesResult.data.map(jobPhase => {
    if (jobPhase.phaseType === PhaseTypeEnum.Garage) {
      setToggleGarage(jobPhase.required);
    }
    return {
      countRequired: jobPhase.countRequired,
      description: jobPhase.phaseName,
      name: jobPhase.phaseName,
      phaseType: jobPhase.phaseType,
      priority: jobPhase.priority,
      resourceType: jobPhase.phaseResourceType,
    };
  });
  newJob.phases = phasesArray;
  newJob.jobPhases = [...jobPhasesResult.data];
  newJob.jobPhase = jobPhasesResult.data.find(
    jobPhase => jobPhase.id === theJob.job.currentJobPhaseId,
  );

  setJob(newJob);
  if (refresh) {
    setLoadingJob(false);
  }
  setLoadingTimeline(false);
};

const getMaterialsJobPhase = theJob => {
  if (theJob.job.currentJobPhasePriority === 100)
    return theJob.job.currentJobPhaseId;

  const materialsPhase = theJob.jobPhases.find(
    jobPhase => jobPhase.priority === 100,
  ).id;
  return materialsPhase;
};

const getBoardsData = async ({
  jobId,
  theJob,
  setJob,
  setLoadingJob,
  skipLoading,
  setLoadingBoards,
}) => {
  // const materialsPhaseId = getMaterialsJobPhase(theJob);
  // const currentPhase = await dataProvider.getOne<any>('job-phase', {
  //   id: materialsPhaseId,
  // });

  let boardPurchases: any = [];
  let boardReceiptFloors: any = [];
  const boardPurchasesCall = await dataProvider.getList<BoardPurchaseDto>(
    'board-purchase',
    {
      filter: { jobId },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'createdAt', order: 'ASC' },
    },
  );

  if (boardPurchasesCall.data) {
    boardPurchases = boardPurchasesCall.data.sort((a, b) =>
      a.createdAt < b.createdAt ? -1 : 0,
    );
  } else {
    throw new Error('Board Purchases not found');
  }

  if (theJob.job.currentJobPhasePriority > 100) {
    const boardPurchaseIdList = [
      ...new Set(
        boardPurchases.map((boardPurchase: BoardFloorDto) => boardPurchase.id),
      ),
    ];
    const boardReceiptFloorsResult = await Promise.all(
      boardPurchaseIdList.map((boardPurchaseId: any) =>
        dataProvider.getList<BoardFloorDto>('board-receipt-floor', {
          filter: { boardPurchaseId },
          pagination: { page: 1, perPage: 200 },
          sort: { field: 'id', order: 'ASC' },
        }),
      ),
    );

    if (boardReceiptFloorsResult) {
      boardReceiptFloorsResult.map(
        (boardReceiptFloor: any) =>
          (boardReceiptFloors = boardReceiptFloors.concat(
            boardReceiptFloor.data,
          )),
      );
    }
  }
  const newJob = { ...theJob, boardPurchases, boardReceiptFloors };

  setJob(newJob);
  if (!skipLoading) {
    setLoadingJob(false);
  }
  setLoadingBoards(false);
};

const getTimelineData = ({
  jobId,
  theJob,
  setJob,
  setLoadingJob,
  setToggleGarage,
  skipLoading,
  setLoadingTimeline,
}) => {
  getJobPhases({
    jobId,
    theJob,
    setJob,
    setLoadingJob,
    refresh: !skipLoading,
    setToggleGarage,
    setLoadingTimeline,
  });
};

const getNotes = async ({
  jobId,
  setJob,
  theJob,
  setOptionsSelected,
  setLoadingNotes,
}) => {
  if (theJob.options.length > 0) return;
  setLoadingNotes(true);
  // Notes do Job
  const jobNotesResponse = await dataProvider.getList<JobNoteDto>('job-note', {
    filter: { jobId },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'DESC' },
  });

  // Note checkbox options
  const optionsCall = await dataProvider.getList<any>('option', {
    filter: { isInternal: true },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'ASC' },
  });

  // options selected
  const jobOptionsCall = await dataProvider.getList<PhaseDto>('job-option', {
    filter: { jobId },
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'createdAt', order: 'ASC' },
  });

  if (jobOptionsCall.data.length) {
    setOptionsSelected(
      jobOptionsCall.data.map((jobOption: any) => jobOption.optionId),
    );
  }
  setJob({
    ...theJob,
    jobNotes: jobNotesResponse.data,
    jobOptions: jobOptionsCall.data,
    options: optionsCall.data,
  });
  setLoadingNotes(false);
};

const getRepairsLot = async ({ jobId, theJob, setJob, setLoadingRepairs }) => {
  const newJob = { ...theJob };
  const jobRepairsCall = await dataProvider.getList<PhaseDto>(
    'job-lot-repair-report',
    {
      filter: {
        jobId,
      },
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'id', order: 'ASC' },
    },
  );
  const jobRepairs = jobRepairsCall.data.sort((a, b) =>
    a.createdAt < b.createdAt ? -1 : 0,
  );

  setJob({ ...newJob, jobRepairs, repairFormula: [] });
  setLoadingRepairs(false);
  return jobRepairs;
};

export const MasterSheetEdit: FC<any> = props => {
  const classes = useStyles();
  const notify = useNotify();
  const redirect = useRedirect();
  const [theJob, setJob] = useState<Job>();
  const [geoLocation, setGeoLocation] = useState<Point['coordinates']>([]);
  const [geoLocationError, setGeoLocationError] = useState<string>('');
  const [tabSelected, setTabSelected] = useState<TabEnum>(TabEnum.Boards);
  const [toggleGarage, setToggleGarage] = useState<boolean>(false);
  const [openConfirmGoBack, setOpenConfirmGoBack] = useState(false);
  const [loadingTimeline, setLoadingTimeline] = useState(true);
  const [loadingJob, setLoadingJob] = useState(true);
  const [openConfirmApprove, setOpenConfirmApprove] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [isViewPhotos, setIsViewPhotos] = useState(false);
  const [loadingBoards, setLoadingBoards] = useState(true);
  const [loadingRepairs, setLoadingRepairs] = useState(true);
  const [loadingNotes, setLoadingNotes] = useState(true);
  const [approveMessage, setApproveMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedPhase, setSelectedPhase] = useState<JobPhaseDto>();
  const [notes, setNotes] = useState<EditorState>(EditorState.createEmpty());
  const [optionsSelected, setOptionsSelected] = useState<string[]>([]);
  const [jobLotError, setJobLotError] = useState(false);
  const [initialRepairValue, setInitialRepairValue] = useState<
    string | undefined
  >();

  console.log('theJob', theJob);

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(setPosition, retryLocation, {
      maximumAge: Infinity,
      timeout: 6000,
    });
  };

  const setPosition = (position: any) =>
    setGeoLocation([position.coords.latitude, position.coords.longitude]);

  const refreshJobPhases = async () => {
    getJobLot({
      jobId: props.id,
      theJob,
      setJob,
      setLoadingJob,
      setTabSelected,
      setToggleGarage,
      setLoadingTimeline,
      setJobLotError,
      setLoadingBoards,
    });
  };

  const approveJob = async () => {
    const jobPhase = theJob?.jobPhase;
    if (jobPhase) {
      const params: any = {
        id: jobPhase.id,
        data: { approved: true },
        previousData: jobPhase,
      };
      if (geoLocation.length) {
        params.data.approvedGeo = { type: 'Point', coordinates: geoLocation };
      }
      await dataProvider
        .update('job-phase', params)
        .then(() => {
          notify('Job Approved!');
          refreshJobPhases();
          onClose();
          setUploadModalOpen(false);
        })
        .catch(error => {
          notify(`Approve Job Error: ${error.message}`, 'warning');
        });
    }
  };

  const jobPhase = theJob?.jobPhase;
  const goBackJob = async () => {
    if (jobPhase && theJob?.phases) {
      const params = {
        id: jobPhase.id,
        data: {} as JobPhaseUpdateDto,
        previousData: jobPhase,
      };
      if (jobPhase.approved || (!jobPhase.contractorId && !jobPhase.requested))
        return;

      const currentPhase = theJob?.phases.find(
        phase => phase.id === jobPhase.phaseId,
      );

      if (currentPhase && BUMP_PHASES.includes(currentPhase.phaseType)) {
        params.data.contractorId = null;
        params.data.requested = false;
      } else {
        if (jobPhase.contractorId) {
          params.data.contractorId = null;
        } else if (jobPhase.requested) {
          params.data.requested = false;
        }
      }

      await dataProvider
        .update('job-phase', params)
        .then(() => {
          notify('Go back with success!');
          refreshJobPhases();
          onClose();
        })
        .catch(error => {
          notify(`Go Back error: ${error.message}`, 'warning');
        });
    }
  };

  const onClose = () => {
    setOpenConfirmApprove(false);
    setOpenConfirmGoBack(false);
  };

  const retryLocation: PositionErrorCallback = (error: any) => {
    if (error.code === 3) {
      return getLocation();
    }
    if (error.code === 1) {
      return setGeoLocationError('You need to enable your location');
    }
    setGeoLocationError('');
  };

  useEffect(() => {
    getJob(
      setJob,
      props.id,
      setToggleGarage,
      setTabSelected,
      setLoadingJob,
      setLoadingTimeline,
      setJobLotError,
      setLoadingBoards,
    );
    getLocation();
  }, []);

  useEffect(() => {
    const jobLoaded = theJob && theJob.job && theJob.job.id;

    const checkParams = () => {
      if (!jobLoaded) return;
      try {
        const searchParams = new URLSearchParams(props.location.search);
        const repairId = searchParams.get('repairId');
        if (repairId === null) return;
        setTabSelected(TabEnum.Repairs);
        setInitialRepairValue(repairId);
      } catch (error) {
        console.log(error);
      }
    };

    checkParams();
  }, [theJob, props.location.search]);

  const isInWarranty = () => {
    return theJob?.job.currentJobPhasePriority >= 600;
  };

  const showBump = (bumpType: PhaseTypeEnum) => {
    if (bumpType === PhaseTypeEnum['Bump4']) {
      return (
        theJob?.job.currentJobPhasePriority > 800 ||
        (theJob?.job.currentJobPhasePriority === 800 &&
          theJob?.jobPhase.approved)
      );
    }
    if (bumpType === PhaseTypeEnum['Bump5']) {
      return (
        theJob?.job.currentJobPhasePriority > 900 ||
        (theJob?.job.currentJobPhasePriority === 900 &&
          theJob?.jobPhase.approved)
      );
    }
    return false;
  };

  const changeNotes = async () => {
    setLoading(true);

    const plainText =
      convertToHTML(notes.getCurrentContent()).trim() !== '<p></p>'
        ? convertToHTML(notes.getCurrentContent()).trim()
        : '';

    try {
      if (theJob && hasPermission(CAN_EDIT_NOTES)) {
        const formattedOptions = optionsSelected.map(optionId => ({
          optionId,
        }));

        await dataProvider.create('job-note', {
          data: {
            jobId: theJob.job.id,
            ...(plainText && { note: plainText }),
            ...(formattedOptions.length && {
              jobNoteOptions: formattedOptions,
            }),
          },
        });

        await refreshNotes(setJob, theJob)();

        setNotes(EditorState.createEmpty());
      }
    } catch (error) {
      return notify(`Update Job Error: ${error.message}`, 'warning');
    } finally {
      setLoading(false);
    }
  };

  const getCheckedOption = option => optionsSelected.indexOf(option.id) !== -1;

  const { hasPermission } = PermissionsProvider.useContainer();

  const onChangeOption = option => {
    const newOptionsSelected = [...optionsSelected];
    const index = newOptionsSelected.indexOf(option.id);
    if (index === -1) {
      newOptionsSelected.push(option.id);
    } else {
      newOptionsSelected.splice(index, 1);
    }
    setOptionsSelected(newOptionsSelected);
  };

  const isWarranty = window.location.href.indexOf('job-warranty-sheet') !== -1;

  const checkHouse = async checked => {
    const params: any = {
      id: theJob?.job.id,
      data: { houseMarked: checked },
      previousData: theJob?.job,
    };
    await dataProvider
      .update('job', params)
      .then(() => {
        const messageMarked = 'House marked!';
        const messageUnMarked = 'House unmarked!';
        notify(checked ? messageMarked : messageUnMarked);
        refreshJobCall(setJob, props.id, theJob);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(`House mark Error: ${error.message}`, 'warning');
      });
  };

  const updateJob = () =>
    getJob(
      setJob,
      props.id,
      setToggleGarage,
      setTabSelected,
      setLoadingJob,
      setLoadingTimeline,
      setJobLotError,
      setLoadingBoards,
    );

  const changeTab = (newTab: TabEnum) => setTabSelected(newTab);

  const isMobile = isMobileHook();

  useEffect(() => {
    if (tabSelected === TabEnum.Notes && !theJob?.jobNotes) {
      getNotes({
        jobId: props.id,
        setJob,
        theJob,
        setOptionsSelected,
        setLoadingNotes,
      });
    }

    if (tabSelected === TabEnum.Repairs && theJob?.jobRepairs.length === 0) {
      getRepairsLot({ jobId: props.id, setJob, theJob, setLoadingRepairs });
    }

    if (
      tabSelected === TabEnum.Timeline &&
      theJob?.phases.length === 0 &&
      theJob.job
    ) {
      getTimelineData({
        jobId: props.id,
        setJob,
        theJob,
        setLoadingJob,
        setToggleGarage,
        skipLoading: true,
        setLoadingTimeline,
      });
    }
  }, [tabSelected]);

  const onChangeTab = newTab => {
    if (newTab === TabEnum.Boards && theJob?.boardPurchases.length === 0) {
      getBoardsData({
        jobId: props.id,
        theJob,
        setJob,
        setLoadingJob,
        skipLoading: true,
        setLoadingBoards,
      });
    }
    setTabSelected(newTab);
  };

  return (
    <Fragment>
      <Box {...props} width="100%" maxWidth="100vw">
        <Box className={classes.createBox}>
          {theJob && theJob.job && theJob.job.id && !loadingJob ? (
            <Fragment>
              <Box mb={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<ChevronLeftIcon />}
                  onClick={() =>
                    redirect(
                      isWarranty ? '/job-warranty-sheet' : '/job-master-sheet',
                    )
                  }
                >
                  Go Back
                </Button>
              </Box>
              {theJob.job.currentJobPhasePriority > 200 && (
                <MasterSheetStatusBar
                  theJob={theJob}
                  getPhase={getPhase}
                  showBump={showBump}
                  isInWarranty={isInWarranty}
                />
              )}

              {isMobile ? (
                <MobileMasterSheetJobInfo
                  checkHouse={checkHouse}
                  theJob={theJob}
                />
              ) : (
                <MasterSheetJobInfo checkHouse={checkHouse} theJob={theJob} />
              )}

              <Box className={classes.mobileBorderlessWhiteBox} overflow="auto">
                <AppBar position="static">
                  <Tabs
                    value={tabSelected}
                    onChange={(_event, newTab) => onChangeTab(newTab)}
                    aria-label="Vatos Tabs on House Report"
                    centered
                    indicatorColor="secondary"
                    variant="fullWidth"
                    scrollButtons="auto"
                    classes={{
                      root: classes.tabs,
                      indicator: classes.indicator,
                    }}
                  >
                    <Tab
                      label="Boards"
                      value={TabEnum.Boards}
                      className={classes.tabsText}
                      icon={isMobile ? <FactCheck /> : undefined}
                    />
                    <Tab
                      label="Timeline"
                      value={TabEnum.Timeline}
                      className={classes.tabsText}
                      icon={isMobile ? <Handyman /> : undefined}
                    />
                    <Tab
                      label="Notes"
                      value={TabEnum.Notes}
                      className={classes.tabsText}
                      icon={isMobile ? <StickyNote2 /> : undefined}
                    />
                    <Tab
                      label="Repairs"
                      value={TabEnum.Repairs}
                      className={classes.tabsText}
                      icon={isMobile ? <HomeRepairService /> : undefined}
                    />
                  </Tabs>
                </AppBar>
                <Box width="100%" overflow="auto">
                  <Box
                    display={tabSelected === TabEnum.Boards ? 'block' : 'none'}
                  >
                    <MasterSheetBoards
                      loading={loading}
                      theJob={theJob}
                      geoLocationError={geoLocationError}
                      updateJob={updateJob}
                      changeTab={changeTab}
                      history={props.history}
                      loadingBoards={loadingBoards}
                    />
                  </Box>
                  {tabSelected === TabEnum.Timeline && (
                    <>
                      {loadingTimeline ? (
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent={'center'}
                          flexDirection={'row'}
                          my={2}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        <>
                          {isMobile ? (
                            <MobileMasterSheetTimeline
                              theJob={theJob}
                              geoLocationError={geoLocationError}
                              getPhase={getPhase}
                              isInWarranty={isInWarranty}
                              showBump={showBump}
                              setOpenConfirmGoBack={setOpenConfirmGoBack}
                              refreshJobPhases={refreshJobPhases}
                              toggleGarage={toggleGarage}
                              setToggleGarage={setToggleGarage}
                              setIsViewPhotos={setIsViewPhotos}
                              setUploadModalOpen={setUploadModalOpen}
                              setSelectedPhase={setSelectedPhase}
                            />
                          ) : (
                            <MasterSheetTimeline
                              theJob={theJob}
                              geoLocationError={geoLocationError}
                              getPhase={getPhase}
                              isInWarranty={isInWarranty}
                              showBump={showBump}
                              setOpenConfirmGoBack={setOpenConfirmGoBack}
                              refreshJobPhases={refreshJobPhases}
                              toggleGarage={toggleGarage}
                              setToggleGarage={setToggleGarage}
                              setIsViewPhotos={setIsViewPhotos}
                              setUploadModalOpen={setUploadModalOpen}
                              setSelectedPhase={setSelectedPhase}
                            />
                          )}{' '}
                        </>
                      )}
                    </>
                  )}
                  {tabSelected === TabEnum.Notes && (
                    <Box width="100%">
                      <Box className={classes.titleTabNoBorder} px={2}>
                        <Typography className={classes.titleFont}>
                          Notes
                        </Typography>
                      </Box>
                      {loadingNotes ? (
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent={'center'}
                          flexDirection={'row'}
                          my={2}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        <>
                          <Box className={classes.optionsSection} px={2}>
                            {theJob.options.map(option => (
                              <Box key={option.id} mr={2}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={getCheckedOption(option)}
                                      onChange={() => onChangeOption(option)}
                                      disabled={
                                        !hasPermission(CAN_EDIT_OPTIONS)
                                      }
                                      name="isMonday"
                                      color="primary"
                                    />
                                  }
                                  label={option.name}
                                />
                              </Box>
                            ))}
                          </Box>
                          <Box mt={2}>
                            {hasPermission(CAN_EDIT_NOTES) ? (
                              <Editor
                                editorState={notes}
                                editorClassName={classes.editor}
                                toolbar={{
                                  options: ['inline', 'blockType', 'list'],
                                  fontSize: {
                                    options: [12, 14, 16, 18, 24, 30],
                                  },
                                  inline: {
                                    options: ['bold', 'italic', 'underline'],
                                  },
                                  blockType: {
                                    inDropdown: false,
                                    options: ['Normal', 'H1', 'H2', 'H3'],
                                  },
                                  list: {
                                    options: ['unordered', 'ordered'],
                                  },
                                }}
                                onEditorStateChange={setNotes}
                              />
                            ) : (
                              <div
                                className="preview"
                                dangerouslySetInnerHTML={{
                                  __html: convertToHTML(
                                    notes.getCurrentContent(),
                                  ),
                                }}
                              ></div>
                            )}
                          </Box>
                          {hasPermission(CAN_EDIT_NOTES) && (
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              className={classes.button}
                              onClick={changeNotes}
                              disabled={loading}
                            >
                              {loading ? (
                                <CircularProgress
                                  classes={{ colorPrimary: classes.spinner }}
                                  size={24}
                                />
                              ) : (
                                'Submit'
                              )}
                            </Button>
                          )}

                          {!!theJob.jobNotes?.length && (
                            <Box pt={3}>
                              <Typography style={{ fontWeight: 'bold' }}>
                                Notes History
                              </Typography>
                              <Box>
                                {theJob.jobNotes.map((item, index) => (
                                  <Box key={item.id} mb={2}>
                                    {index > 0 && <Divider />}
                                    <Box pt={2}>
                                      <Box>
                                        {item.user?.firstName &&
                                          item.user.lastName && (
                                            <Box>
                                              <Typography>{`${
                                                item.user.firstName
                                              } ${
                                                item.user.lastName
                                              } - ${new Date(
                                                item.createdAt,
                                              ).toLocaleTimeString(
                                                'en-US',
                                              )} ${new Date(
                                                item.createdAt,
                                              ).toLocaleDateString(
                                                'en-US',
                                              )}`}</Typography>
                                            </Box>
                                          )}
                                        {!!item.jobNoteOptions?.length && (
                                          <Typography>
                                            Job Options:{' '}
                                            {map(
                                              item.jobNoteOptions,
                                              'option.name',
                                            ).join(', ')}
                                          </Typography>
                                        )}
                                      </Box>
                                      {item.note && (
                                        <Box mt={2}>
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: item.note,
                                            }}
                                            className="preview"
                                          />
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                ))}
                              </Box>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  )}
                  {tabSelected === TabEnum.Repairs && (
                    <>
                      {loadingRepairs ? (
                        <Box
                          display="flex"
                          width="100%"
                          justifyContent={'center'}
                          flexDirection={'row'}
                          my={2}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      ) : (
                        <>
                          {isMobile ? (
                            <MobileMasterSheetRepairs
                              refreshRepairs={refreshRepairs(
                                setJob,
                                theJob,
                                setLoadingRepairs,
                              )}
                              theJob={theJob}
                              initialValue={initialRepairValue}
                            />
                          ) : (
                            <MasterSheetRepairs
                              refreshRepairs={refreshRepairs(
                                setJob,
                                theJob,
                                setLoadingRepairs,
                              )}
                              theJob={theJob}
                              initialValue={initialRepairValue}
                              setJob={setJob}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Fragment>
          ) : (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="100%"
              height="100%"
              flexDirection="column"
            >
              {jobLotError ? (
                <>
                  <Typography>
                    Error! Please verify if the link is correct or check your
                    role and/or region.
                  </Typography>
                  <Button
                    style={{ marginTop: '10px' }}
                    fullWidth={false}
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<ChevronLeftIcon />}
                    onClick={() =>
                      redirect(
                        isWarranty
                          ? '/job-warranty-sheet'
                          : '/job-master-sheet',
                      )
                    }
                  >
                    Go Back
                  </Button>
                </>
              ) : (
                <>
                  <CircularProgress />
                  <Typography className={classes.loadingText}>
                    Loading Job Info
                  </Typography>
                </>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <ConfirmDialog
        open={openConfirmApprove}
        title={approveMessage}
        confirmLabel="Approve Phase"
        handleClose={onClose}
        onConfirm={approveJob}
      />
      <ConfirmDialog
        open={openConfirmGoBack}
        title={`Are you sure that you want to go back?`}
        handleClose={onClose}
        onConfirm={goBackJob}
      />
      {theJob && theJob.job && theJob.job.id && selectedPhase ? (
        <MasterSheetUpload
          jobPhaseId={theJob?.jobPhase.id}
          open={uploadModalOpen}
          handleClose={() => setUploadModalOpen(false)}
          setOpenConfirmApprove={setOpenConfirmApprove}
          isViewPhotos={isViewPhotos}
          setApproveMessage={setApproveMessage}
          approveJob={approveJob}
          selectedPhase={selectedPhase}
        />
      ) : (
        <Fragment />
      )}
    </Fragment>
  );
};
