import React, { Fragment, useEffect, useState } from 'react';
import {
  BoardFloorDto,
  BoardTypeDto,
  BoardFloorInterface,
} from '@vatos-pas/common';
import { useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CAN_SIGN_OFF, CAN_CHECK_COUNT } from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { FloorListItem, FloorListRow } from '../master-sheet-edit';
import { useStyles } from '../master-sheet-styles';
import CheckCountDialog from './master-sheet-check-count';
import { CircularProgress } from '@material-ui/core';

interface MasterSheetBoardTableProps {
  boardPurchase: any;
  signReport: any;
  boardReceiptFloors: any;
  history: any;
  theJob: any;
  title: string;
  expanded: boolean;
  onExpandToggle: (id: string, value: boolean) => void;
  loadingBoards?: any;
}

const getBoardTypes = async (
  boardPurchase,
  dataProvider,
  setBoardTypes,
  setLoadingBoardTypes,
) => {
  const boardTypeIds = [
    ...new Set(
      boardPurchase.boardPurchaseFloors.map(
        boardFloor => boardFloor.boardTypeId,
      ),
    ),
  ];

  if (!boardTypeIds.length) {
    setLoadingBoardTypes(false);
    return;
  }

  const responses = await dataProvider.getList('board-type', {
    filter: {
      'id||$in': boardTypeIds.join(),
    },
    pagination: { page: 1, perPage: 999 },
    sort: { field: 'name', order: 'ASC' },
  });
  const boards = responses.data;
  setLoadingBoardTypes(false);

  if (!boards) throw new Error('Board types not found');

  const boardTypes = boards.sort((a, b) => a.sortPriority - b.sortPriority);
  setBoardTypes(boardTypes);
};

const getFloors = (boardPurchase, setFloors) => {
  let floorList;

  if (boardPurchase.boardPurchaseFloors) {
    const floors = [
      ...new Set(
        boardPurchase.boardPurchaseFloors.map(
          (boardFloor: any) => boardFloor.floor,
        ),
      ),
    ].sort();

    floorList = floors.map((boardFloor: any) => ({
      floor: boardFloor,
      rows: [{ row: 1, boards: {} }],
    }));
    setFloors(floorList);
  }
};

const addFloorRow =
  (floor: BoardFloorInterface['floor'], floors: any, setFloors: any) => () => {
    const newFloors = [...floors];
    const floorFound = newFloors.find(
      (floorItem: FloorListItem) => floorItem.floor === floor,
    );
    if (floorFound) {
      floorFound.rows.push({ row: floorFound.rows.length + 1, boards: {} });
      setFloors(newFloors);
    }
  };

const removeFloorRow =
  (
    floor: BoardFloorInterface['floor'],
    rowIndex: number,
    floors: any,
    setFloors: any,
  ) =>
  () => {
    const newFloors = [...floors];
    const floorListItem = newFloors.find(
      (floorListItem: FloorListItem) => floorListItem.floor === floor,
    );
    if (floorListItem) {
      floorListItem.rows.splice(rowIndex, 1);
    }
    setFloors(newFloors);
  };

const totalBoardsSum = (floors: any) => {
  let count = 0;
  floors.map((floor: FloorListItem) => {
    return floor.rows.map((row: FloorListRow) => {
      const keys = Object.keys(row.boards);
      return keys.map(key => {
        if (row.boards[key] && row.boards[key].quantity) {
          const string = row.boards[key].quantity.toString();
          count = count + parseInt(string);
        }
      });
    });
  });
  return count;
};

const isSameBoardsQuantity = (
  boardPurchases: any,
  floors: any,
  boardReceipts: any,
) => {
  if (boardReceipts.length) {
    let sumPurchase = 0;
    boardPurchases.boardPurchaseFloors.map((floor: any) => {
      sumPurchase += floor.quantity;
    });
    let sumReceipt = 0;
    boardReceipts.map((boardReceipt: BoardFloorDto) => {
      sumReceipt += boardReceipt.quantity;
    });
    return sumPurchase === sumReceipt;
  }
  if (boardPurchases.boardPurchaseFloors) {
    const sumFilled: number = totalBoardsSum(floors);
    let sumPurchase = 0;
    boardPurchases.boardPurchaseFloors.map((floor: BoardFloorDto) => {
      sumPurchase += floor.quantity;
    });
    return sumFilled === sumPurchase;
  } else {
    throw new Error('No board purchase floors were found on board purchase');
  }
};

const setBoardQuantity =
  (
    floor: FloorListItem,
    row: FloorListRow,
    boardType: any,
    floors: any,
    setFloors: any,
  ) =>
  (event: any) => {
    const newFloors = [...floors];
    const floorFound = newFloors.find(
      (floorItem: FloorListItem) => floorItem.floor === floor.floor,
    );
    if (floorFound) {
      const rowFound = floorFound.rows.find(
        (floorListRow: FloorListRow) => floorListRow.row === row.row,
      );
      if (rowFound) {
        const boardItem = rowFound.boards[boardType.id];
        if (!boardItem) {
          rowFound.boards[boardType.id] = {
            boardTypeId: boardType.id,
            quantity: parseInt(event.target.value),
            floor: floor.floor,
          };
        } else {
          boardItem.quantity = event.target.value;
        }
        setFloors(newFloors);
      }
    }
  };

const getBoardQuantity = (
  row: FloorListRow,
  boardType: BoardTypeDto,
  floor: BoardFloorInterface['floor'],
  floors: any,
): number => {
  const floorFound = floors.find(
    (floorItem: FloorListItem) => floorItem.floor === floor,
  );
  if (floorFound) {
    const rowFound = floorFound.rows.find(rowItem => rowItem.row === row.row);
    if (rowFound) {
      const itemFound = rowFound.boards[boardType.id];
      return itemFound ? itemFound.quantity : 0;
    }
    return 0;
  }
  return 0;
};

const getFloorBoardQuantity = (
  floor: BoardFloorInterface['floor'],
  floors: any,
) => {
  const floorFound = floors.find(
    (floorItem: FloorListItem) => floorItem.floor === floor,
  );
  if (floorFound) {
    return floorFound.rows.reduce((acc: number, row: any) => {
      const rowTotal = Object.values(row.boards).reduce<number>(
        (acc: number, board: any) => {
          const quantity = Number(board.quantity);
          return acc + (Number.isNaN(quantity) ? 0 : quantity);
        },
        0,
      );
      return acc + rowTotal;
    }, 0);
  }
  return 0;
};

const getBoardReceipts = (boardReceiptFloors: any, boardPurchase: any) =>
  boardReceiptFloors.filter(
    (boardReceiptFloor: any) =>
      boardReceiptFloor.boardPurchaseId === boardPurchase.id,
  );

export const MasterSheetBoardTable: React.FC<MasterSheetBoardTableProps> = ({
  theJob,
  boardPurchase,
  boardReceiptFloors,
  history,
  signReport,
  title,
  expanded,
  onExpandToggle,
  loadingBoards,
}) => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [boardTypes, setBoardTypes] = useState([]);
  const [floors, setFloors] = useState([]);
  const [loadingBoardTypes, setLoadingBoardTypes] = useState(true);
  const [openCheckCount, setOpenCheckCount] = useState(false);
  const [boardReceipts] = useState(
    getBoardReceipts(theJob.boardReceiptFloors, boardPurchase),
  );

  useEffect(() => {
    getBoardReceipts(boardReceiptFloors, boardPurchase);
  }, [theJob.boardReceiptFloors, boardPurchase]);

  useEffect(() => {
    if (!boardTypes.length) {
      getBoardTypes(
        boardPurchase,
        dataProvider,
        setBoardTypes,
        setLoadingBoardTypes,
      );
    }
    getFloors(boardPurchase, setFloors);
  }, []);

  let floorsMap = floors;
  if (boardReceipts.length) {
    const floorsReceipt: any = [];
    boardReceipts
      .filter((item: any) => item.quantity > 0)
      .forEach(item => {
        const floorReceiptFound: any = floorsReceipt.find(
          (floor: any) => floor.floor === item.floor,
        );
        if (floorReceiptFound) {
          const alreadyExist = floorReceiptFound.rows.filter(
            row => row.boards[item.boardTypeId],
          ).length;
          if (alreadyExist > 0) {
            floorReceiptFound.rows.push({
              row: alreadyExist + 1,
              boards: {
                [item.boardTypeId]: {
                  boardTypeId: item.boardTypeId,
                  quantity: item.quantity,
                  floor: item.floor,
                },
              },
            });
          } else {
            floorReceiptFound.rows[0].boards[item.boardTypeId] = {
              boardTypeId: item.boardTypeId,
              quantity: item.quantity,
              floor: item.floor,
            };
          }
        } else {
          const newFloorReceipt = {
            floor: item.floor,
            rows: [
              {
                row: 1,
                boards: {
                  [item.boardTypeId]: {
                    boardTypeId: item.boardTypeId,
                    quantity: item.quantity,
                    floor: item.floor,
                  },
                },
              },
            ],
          };
          floorsReceipt.push(newFloorReceipt);
        }
      });
    floorsMap = floorsReceipt;
  }

  const { hasPermission } = PermissionsProvider.useContainer();

  const sum = function (items, prop) {
    return items.reduce(function (a, b) {
      return a + b[prop];
    }, 0);
  };

  const isCountedWrong = boardType => {
    if (!boardReceipts.length) {
      return false;
    }
    const originalPO = sum(
      boardPurchase.boardPurchaseFloors.filter(
        boardReceipt => boardReceipt.boardTypeId === boardType.id,
      ),
      'quantity',
    );
    const counted = sum(
      theJob.boardReceiptFloors.filter(
        boardReceipt => boardReceipt.boardTypeId === boardType.id,
      ),
      'quantity',
    );
    return originalPO !== counted;
  };

  const getMudCount = (po: any) => {
    const mud = po.materials.find((m: any) => m.name.toLowerCase() === 'mud');
    return mud ? mud.amount : 0;
  };

  // if (!boardPurchase.isPrimary) {
  //   return (
  //     <Box
  //       display="flex"
  //       alignItems="center"
  //       justifyContent="space-between"
  //       marginX="16px"
  //       marginY="12px"
  //     >
  //       <Typography>{title}</Typography>
  //       <Typography>For reference only</Typography>
  //     </Box>
  //   );
  // }

  if (loadingBoards || loadingBoardTypes) {
    return (
      <Box
        display="flex"
        width="100%"
        justifyContent={'center'}
        flexDirection={'row'}
        my={2}
      >
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, value) => onExpandToggle(boardPurchase.id, value)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center">
          <Typography>{title}</Typography>
        </Box>
        {!!theJob.boardReceiptFloors.length &&
          hasPermission(CAN_CHECK_COUNT) &&
          !expanded && (
            <Box
              display="flex"
              flex={1}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                <Box
                  display="flex"
                  gridGap="12px"
                  justifyContent="space-between"
                >
                  <Typography className={classes.orderDescription}>
                    Field Count
                  </Typography>
                  <Typography
                    className={
                      isSameBoardsQuantity(boardPurchase, floors, boardReceipts)
                        ? classes.totalBoardsGreenMobile
                        : classes.totalBoardsMobile
                    }
                  >
                    {totalBoardsSum(floorsMap)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  gridGap="12px"
                  justifyContent="space-between"
                  className={classes.orderDescriptionSeparator}
                >
                  <Typography className={classes.orderDescription}>
                    PO Total
                  </Typography>
                  <Typography className={classes.orderDescription}>
                    {sum(boardPurchase.boardPurchaseFloors, 'quantity')}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
      </AccordionSummary>
      <AccordionDetails>
        <Box flexDirection="column" display="flex" width="100%">
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            {theJob.boardReceiptFloors.length &&
            hasPermission(CAN_CHECK_COUNT) ? (
              <Button
                color="primary"
                disabled={!boardPurchase.isPrimary}
                variant="outlined"
                size="large"
                className={classes.button}
                onClick={() => setOpenCheckCount(true)}
              >
                Check Count
              </Button>
            ) : (
              ''
            )}
            {theJob.boardReceiptFloors.length ||
            !hasPermission(CAN_SIGN_OFF) ? (
              ''
            ) : (
              <Fragment>
                <Button
                  color="primary"
                  variant="outlined"
                  disabled={!boardPurchase.isPrimary}
                  size="large"
                  className={classes.button}
                  onClick={() => signReport(floors, boardPurchase)}
                >
                  Sign Off
                </Button>
              </Fragment>
            )}
            <Typography
              className={
                isSameBoardsQuantity(boardPurchase, floors, boardReceipts)
                  ? classes.totalBoardsGreen
                  : classes.totalBoards
              }
            >
              {totalBoardsSum(floorsMap)}
            </Typography>
          </Box>

          <Box display="flex" width="100%" flexDirection="column">
            {floorsMap.length > 0 &&
              floorsMap.map((floor: any, floorIndex: number) => (
                <Box key={floor.floor} className={classes.whiteBoxMobile}>
                  <Box className={classes.floorHeader}>
                    <Typography>Floor #{floor.floor}</Typography>
                    {theJob.boardReceiptFloors.length ||
                    !hasPermission(CAN_SIGN_OFF) ||
                    !boardPurchase.isPrimary ? (
                      ''
                    ) : (
                      <AddCircleIcon
                        onClick={addFloorRow(floor.floor, floorsMap, setFloors)}
                        className={classes.pointer}
                      />
                    )}
                  </Box>
                  {boardTypes.map((boardType: any) => (
                    <Box
                      key={boardType.id}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      className={classes.floorGroupMobile}
                    >
                      {floor.rows.map((row: any, i: number) => {
                        const disabled =
                          !boardPurchase.isPrimary ||
                          !hasPermission(CAN_SIGN_OFF) ||
                          (theJob.boardReceiptFloors &&
                            theJob.boardReceiptFloors.length > 0);

                        const value = Number(
                          getBoardQuantity(
                            row,
                            boardType,
                            floor.floor,
                            floorsMap,
                          ),
                        );

                        const isValueANumber = !Number.isNaN(value);

                        const onChange = setBoardQuantity(
                          floor,
                          row,
                          boardType,
                          floorsMap,
                          setFloors,
                        );

                        const onRemoveClick = () =>
                          onChange({
                            target: { value: isValueANumber ? value - 1 : 0 },
                          });

                        const onAddClick = () =>
                          onChange({
                            target: { value: isValueANumber ? value + 1 : 0 },
                          });

                        return (
                          <Box
                            key={`${boardType.id}_${i.toString()}`}
                            alignItems="center"
                            display="flex"
                            flexDirection="row"
                          >
                            <Box display="flex" flex={1}>
                              {i === 0 ? (
                                <Typography
                                  className={
                                    isCountedWrong(boardType)
                                      ? classes.contedWrong
                                      : ''
                                  }
                                >
                                  {boardType.shortName}
                                </Typography>
                              ) : (
                                <>
                                  {theJob.boardReceiptFloors.length ||
                                  !hasPermission(CAN_SIGN_OFF) ? (
                                    ''
                                  ) : (
                                    <RemoveCircleIcon
                                      className={classes.pointer}
                                      onClick={removeFloorRow(
                                        floor.floor,
                                        i,
                                        floorsMap,
                                        setFloors,
                                      )}
                                    />
                                  )}
                                </>
                              )}
                            </Box>
                            <Box display="flex" flex={1} flexDirection="row">
                              <Box
                                display="flex"
                                alignItems="center"
                                gridGap={16}
                              >
                                {!disabled && (
                                  <Box
                                    className={classes.circleSmall}
                                    onClick={onRemoveClick}
                                  >
                                    <RemoveIcon
                                      className={classes.iconCircle}
                                    />
                                  </Box>
                                )}
                                <TextField
                                  type="number"
                                  className={classes.inputGrid}
                                  placeholder="0"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  value={value}
                                  onChange={onChange}
                                  disabled={disabled}
                                />
                                {!disabled && (
                                  <Box
                                    className={classes.circleSmall}
                                    onClick={onAddClick}
                                  >
                                    <AddIcon className={classes.iconCircle} />
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  ))}
                  {floorIndex === 0 && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      className={classes.floorGroupMobile}
                    >
                      <Box display="flex" flex={1} alignItems="center">
                        <Typography>Mud</Typography>
                      </Box>
                      <Box
                        display="flex"
                        flex={1}
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography>{getMudCount(boardPurchase)}</Typography>
                      </Box>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    justifyContent="center"
                    className={classes.floorGroupMobile}
                  >
                    <Box display="flex" flex={1} alignItems="center">
                      <Typography>Total boards</Typography>
                    </Box>
                    <Box
                      display="flex"
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography>
                        {getFloorBoardQuantity(floor.floor, floorsMap)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
          </Box>
          {openCheckCount && (
            <CheckCountDialog
              open={openCheckCount}
              title={title}
              theJob={theJob}
              history={history}
              boardTypes={boardTypes}
              handleClose={() => setOpenCheckCount(false)}
              boardReceipts={boardReceipts}
              boardPurchase={boardPurchase}
              boardReceiptFloors={boardReceiptFloors}
              boardPurchaseFloors={boardPurchase.boardPurchaseFloors}
              disabled={!boardPurchase.isPrimary}
            />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MasterSheetBoardTable;
