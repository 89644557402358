import React, { FC, useEffect, useState } from 'react';

import {
  List,
  TextField,
  ListProps,
  useListContext,
  DateField,
  useDataProvider,
  FunctionField,
  ReferenceField,
  useNotify,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import {
  ContractorPaymentTypeEnum,
  RepairBumperPaymentTypeEnum,
  RepairFormulaInterface,
} from '@vatos-pas/common';
import Datagrid from 'components/Datagrid';
import { PermissionsProvider } from 'providers/permissionsProvider';

import {
  CAN_APPROVE_BUMPOUT,
  CAN_EDIT_BUMPOUT_TIMESHEET_NOTES,
  CAN_EDIT_BUMPOUT_TIMESHEET_PAID,
  CAN_SEE_CONTRACTORS_BUMPOUT,
} from 'providers/permissions';
import { AppRole } from 'providers/roles';
import TimeInput from '../components/TimeInput';
import ApproveButton from '../components/ApproveButton';
import ContractorSelect from '../components/ContractorSelect';
import WorkedHoursInput from '../components/WorkedHoursInput';
import { JOB_PHASE_BUMP_APPROVAL_STATUS_LABEL_MAPPING } from '../constants';
import BumpoutTimesheetFilter from '../components/BumpoutTimesheetFilter';
import BulkActionButtons from '../components/BulkActionButtons';
import ListActions from '../components/ListActions';
import RepairTypeField from '../components/RepairTypeField';
import { findRepairFormula, getContractorFilter } from '../utils';
import SaveButton from '../components/SaveButton';
import NotesButton from '../components/NotesButton';
import AddressField from '../components/AddressField';
import PaidCheckboxField from '../components/PaidCheckboxField';
import PhotoUpload from '../components/PhotoUpload';
import PhotosButton from '../components/PhotosButton';
import { UIProvider } from 'providers/uiProvider';
import { exporter } from 'providers/export';
import isMobileHook from 'hooks/isMobile';

export type BumpoutTimesheetFields = {
  [key: string]: {
    [key: string]: DateTime | string | null;
  };
};

export enum BumpoutTimesheetFieldType {
  TimeIn = 'timeIn',
  TimeOut = 'timeOut',
  WorkedHours = 'workedHours',
}

type ListWrapperProps = {
  fields: BumpoutTimesheetFields | null;
  onChangeField: (
    id: string,
    name: BumpoutTimesheetFieldType,
    value: DateTime | string | null,
  ) => void;
};

const ListWrapper = (props: ListWrapperProps) => {
  const { data, refetch } = useListContext();

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const { hasRole, hasPermission } = PermissionsProvider.useContainer();

  const canApprove = hasPermission(CAN_APPROVE_BUMPOUT);
  const canEditNotes = hasPermission(CAN_EDIT_BUMPOUT_TIMESHEET_NOTES);
  const isSupervisor = hasRole(AppRole.Supervisor);
  const isBumper = hasRole(AppRole.Bumper);
  const canEditPaid = hasPermission(CAN_EDIT_BUMPOUT_TIMESHEET_PAID);

  // Each region can only have its own contractors
  // We need a dictionary by regionId key
  // that will hold every contractor for that region
  const [contractors, setContractors] = useState<any>();
  const [isPhotoUploadOpen, setIsPhotoUploadOpen] = useState(false);

  const dataValues = Object.values(data);
  const mappedData = dataValues.map(item => ({
    regionIdAndRepairId: `${item.regionId}-${item.repairId}`,
    regionId: item.regionId,
    repairId: item.repairId,
    bumpResourceType: item.bumpResourceType,
  }));
  const regionIds = uniqBy(mappedData, 'regionIdAndRepairId');

  const getFormulas = async () => {
    const response = await dataProvider.getList<RepairFormulaInterface>(
      'repair-formula',
      {
        filter: { active: true },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'createdAt', order: 'ASC' },
      },
    );

    return response?.data;
  };

  const getContractors = async () => {
    const repairFormulas = await getFormulas();

    return regionIds?.map(
      async ({ regionIdAndRepairId, regionId, repairId, bumpResourceType }) => {
        const response = await dataProvider.getList('contractor', {
          filter: getContractorFilter(
            repairFormulas,
            repairId,
            regionId,
            bumpResourceType,
          ),
          pagination: { page: 1, perPage: 200 },
          sort: { field: 'name', order: 'ASC' },
        });

        if (response?.data?.length) {
          let contractors = [...response.data];

          const isRepairPayment = bumpResourceType === 'REPAIR_PAYMENT';

          if (isRepairPayment) {
            const perBoard = findRepairFormula(
              repairFormulas,
              repairId,
              RepairBumperPaymentTypeEnum.PerBoard,
            );
            const perHour = findRepairFormula(
              repairFormulas,
              repairId,
              RepairBumperPaymentTypeEnum.PerHour,
            );

            const hasPerBoard = perBoard !== undefined;
            const hasPerHour = perHour !== undefined;

            if (hasPerBoard && !hasPerHour) {
              contractors = contractors?.filter(
                contractor =>
                  contractor.bumperPaymentType ===
                  ContractorPaymentTypeEnum.PerBoard,
              );
            }
            if (hasPerHour && !hasPerBoard) {
              contractors = contractors?.filter(
                contractor =>
                  contractor.bumperPaymentType ===
                  ContractorPaymentTypeEnum.PerHour,
              );
            }
          }

          setContractors(prevState => ({
            ...prevState,
            [regionIdAndRepairId]: contractors,
          }));
        }
      },
    );
  };

  useEffect(() => {
    const initialize = async () => {
      if (!hasPermission(CAN_SEE_CONTRACTORS_BUMPOUT)) return;

      try {
        await getContractors();
      } catch (error) {
        notify(error.message, 'warning');
      }
    };

    initialize();
  }, [JSON.stringify(data)]);

  return (
    <Datagrid
      isHeaderSelectable
      isRowSelectable={record => {
        if (
          canApprove &&
          record?.bumpResourceType !== 'JOB_PHASE_BUMP' &&
          (record?.bumperApprovalStatus === 'PENDING_REVIEW' ||
            record?.bumperApprovalStatus === 'PENDING_APPROVAL')
        ) {
          return true;
        }

        return false;
      }}
      {...props}
    >
      <RepairTypeField label="Repair Type" />
      <TextField source="jobLot" label="Lot #" />
      <TextField source="builderName" label="Builder" />
      <TextField source="subdivisionName" label="Subdivision" />
      <AddressField label="Address" />
      {!isSupervisor && (
        <ReferenceField
          label="Supervisor"
          source="supervisorUserId"
          reference="user"
          data-cy="masterSheetFilterSupervisor"
          link={false}
        >
          <FunctionField
            render={record =>
              record && `${record.firstName} ${record.lastName}`
            }
          />
        </ReferenceField>
      )}
      {hasPermission(CAN_SEE_CONTRACTORS_BUMPOUT) && (
        <ContractorSelect label="Contractor" contractors={contractors} />
      )}
      <DateField source="repairDate" label="Repair Date" />
      <TextField source="payableBoardsTotal" label="Payable Boards" />
      <TimeInput
        label="Time In"
        value={BumpoutTimesheetFieldType.TimeIn}
        onChangeField={props.onChangeField}
        fields={props.fields}
      />
      <TimeInput
        label="Time Out"
        value={BumpoutTimesheetFieldType.TimeOut}
        onChangeField={props.onChangeField}
        fields={props.fields}
      />
      <SaveButton handleRefetch={refetch} fields={props.fields} />
      {canEditNotes && <NotesButton label="Notes" />}
      <PhotosButton label="Photos" />
      <WorkedHoursInput
        label="Worked Hours"
        value={BumpoutTimesheetFieldType.WorkedHours}
        onChangeField={props.onChangeField}
        fields={props.fields}
      />
      <TextField source="estimatedHours" label="Estimated Hours" />
      <FunctionField
        label="Status"
        render={record =>
          JOB_PHASE_BUMP_APPROVAL_STATUS_LABEL_MAPPING[
            record.bumperApprovalStatus
          ]
        }
      />
      {canEditPaid && <PaidCheckboxField label="Paid?" />}
      {!isBumper && (
        <FunctionField
          label="Approval Timestamp"
          source="approvedTimestamp"
          render={record => {
            const timestamp = record.approvedTimestamp;

            if (!timestamp) return null;

            return DateTime.fromISO(timestamp).toFormat('MM/dd/yyyy - t');
          }}
        />
      )}
      <ApproveButton handleRefetch={refetch} fields={props.fields} />
      <PhotoUpload
        open={isPhotoUploadOpen}
        handleClose={() => setIsPhotoUploadOpen(false)}
      />
    </Datagrid>
  );
};

export const BumpoutTimesheetList: FC<ListProps> = props => {
  const { hasRole } = PermissionsProvider.useContainer();
  const classes = useStyles();
  const { opened } = UIProvider.useContainer();
  const isMobile = isMobileHook();

  const [fields, setFields] = useState<BumpoutTimesheetFields | null>(null);

  const onChangeField = (
    id: string,
    name: BumpoutTimesheetFieldType,
    value: DateTime | string | null,
  ) =>
    setFields(prevState => ({
      ...prevState,
      [id]: {
        ...prevState?.[id],
        [name]: value,
      },
    }));

  const isSupervisor = hasRole(AppRole.Supervisor);
  const isBumper = hasRole(AppRole.Bumper);

  return (
    <List
      classes={{
        content: classes.content,
        root: !isMobile
          ? opened
            ? classes.root
            : classes.rootClosed
          : classes.rootMobile,
      }}
      {...props}
      empty={false}
      filters={<BumpoutTimesheetFilter>{props.filters}</BumpoutTimesheetFilter>}
      filterDefaultValues={{
        ...(isSupervisor && {
          'bumperApprovalStatus||$in': ['PENDING_REVIEW', 'PENDING_APPROVAL'],
        }),
        ...(isBumper && {
          'bumperApprovalStatus||$in': ['NOT_STARTED'],
        }),
      }}
      sort={{ field: 'repairDate', order: 'DESC' }}
      bulkActionButtons={<BulkActionButtons fields={fields} />}
      exporter={exporter('bumpout-timesheet')}
      actions={<ListActions maxResults={1000} />}
      perPage={5}
    >
      <ListWrapper fields={fields} onChangeField={onChangeField} />
    </List>
  );
};

const useStyles = makeStyles({
  content: {
    overflow: 'auto',
  },
  root: {
    width: 'calc(100vw - 290px)',
  },
  rootClosed: {
    width: 'calc(100vw - 135px)',
  },
  rootMobile: {
    width: 'calc(100vw - 24px)',
  },
});
