import { DataProvider } from 'react-admin';
import { axiosInstance } from 'services/http';
import { RegionDto, SupplierRegionDto } from '@vatos-pas/common';

interface ISendVerificationCode {
  regionId: string;
}
export interface UserInterface {
  id: string;
  firstName: string;
  lastName: string;
}
export interface UserInterfaceResponse {
  data: {
    data: UserInterface[];
  };
}

export const getSupervisors = ({
  regionId,
}: ISendVerificationCode): Promise<UserInterfaceResponse> => {
  const filter = `?limit=150&page=1&filter=userRoles.role.name%7C%7C$eq%7C%7CRegionalManager&filter=userRegions.regionId%7C%7C$eq%7C%7C${regionId}&join=userRegions&join=userRoles&join=userRoles.role`;
  return axiosInstance.get('/user' + filter);
};

export const getBuilderSupervisors = (
  regionId,
): Promise<UserInterfaceResponse> => {
  const filter = `?limit=300&page=1&filter=userRoles.role.name%7C%7C$eq%7C%7CBuilderSupervisor&&sort=firstName,ASC&filter=userRegions.regionId%7C%7C$eq%7C%7C${regionId}&join=userRegions&join=userRoles&join=userRoles.role`;
  return axiosInstance.get('/user' + filter);
};

export const findManyRegions = async (dataProvider: DataProvider) => {
  try {
    const regions = await dataProvider.getList<RegionDto>('region', {
      filter: {},
      pagination: { page: 1, perPage: 999 },
      sort: { field: 'id', order: 'DESC' },
    });

    return regions.data;
  } catch (error) {
    return error;
  }
};

export const findManySupplierRegions = async (
  dataProvider: DataProvider,
  supplierId: string,
) => {
  try {
    const supplierRegions = await dataProvider.getList<SupplierRegionDto>(
      'supplier-region',
      {
        filter: { supplierId },
        pagination: { page: 1, perPage: 80 },
        sort: { field: 'id', order: 'DESC' },
      },
    );

    return supplierRegions.data;
  } catch (error) {
    return error;
  }
};
