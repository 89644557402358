import React, { Fragment, useEffect, useState } from 'react';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import { useNotify } from 'react-admin';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import { axiosInstance } from 'services/http';
import { PhaseTypeEnum } from '@vatos-pas/common';
import { useStyles } from './../master-sheet-styles';
import { Job } from './../master-sheet-edit';
import { dataProvider } from 'providers/dataProvider';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ContractorChangeDialog from './../master-sheet-contractor-change';
import ContractorHistoryDialog from './../master-sheet-contractor-history';
import ConfirmRequestDialog from './../master-sheet-confirm-request';
import ConfirmDialog from '../../../../components/ConfirmDialog';
import {
  CAN_OVERRIDE_CONTRACTOR,
  CAN_REQUEST_RESOURCE,
  CAN_REQUEST_RESOURCE_ASSIGNED,
  CAN_TOGGLE_GARAGE,
  CAN_APPROVE_PHASE,
  CAN_LOG_GARAGE,
} from 'providers/permissions';
import { PermissionsProvider } from 'providers/permissionsProvider';

export const BUMP_PHASES = ['Bump1', 'Bump2', 'Bump3', 'Bump4', 'Bump5'];

import { makeStyles } from '@material-ui/core/styles';
import LogGarage from '../master-sheet-log-garage';
import { dateWithoutServerTimezone } from 'modules/master-sheet/utils/dateWithoutServerTimezone';

const styles = makeStyles({
  boxTitlePhases: {
    background: '#f2f2f2',
    borderRadius: '5px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  titlePhases: {
    fontWeight: 'bold',
  },
  accordion: {
    marginBottom: '10px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
});

interface MasterSheetTimelineProps {
  theJob: any;
  geoLocationError: string;
  getPhase: any;
  isInWarranty: any;
  setOpenConfirmGoBack: any;
  refreshJobPhases: any;
  setToggleGarage: any;
  toggleGarage: boolean;
  setIsViewPhotos: any;
  setUploadModalOpen: any;
  setSelectedPhase: any;
  showBump: any;
}

const getResourceRequested = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const phaseJob = getPhase(
    theJob.job,
    theJob.phases,
    theJob.jobPhases,
    phaseType,
  );
  if (phaseJob) {
    if (phaseJob.dateRequested) {
      const dateRequested = dateWithoutServerTimezone(phaseJob.dateRequested);
      return `${
        dateRequested.getMonth() + 1
      }/${dateRequested.getDate()}/${dateRequested.getFullYear()}`;
    }
  }
};

const getResourceAllocated = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
  currentContractor = false,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );

  if (currentContractor && theJob.job.reqBumpoutTimesheet) {
    return jobPhase.contractorName;
  } else {
    return jobPhase.initialContractorName;
  }
};

const getSupervisorApproved = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  return jobPhase.approvingUserFullName;
};

const getStartDate = (phaseType: PhaseTypeEnum, theJob: Job, getPhase: any) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );

  if (
    (jobPhase.priority === 500 || jobPhase.priority === 400) &&
    jobPhase.approved &&
    jobPhase.customDateStart
  ) {
    const customDateStart = dateWithoutServerTimezone(jobPhase.customDateStart);
    return `${
      customDateStart.getMonth() + 1
    }/${customDateStart.getDate()}/${customDateStart.getFullYear()}`;
  }

  if (jobPhase.dateStart) {
    const dateStart = dateWithoutServerTimezone(jobPhase.dateStart);
    return `${
      dateStart.getMonth() + 1
    }/${dateStart.getDate()}/${dateStart.getFullYear()}`;
  }
  return '';
};

const getApprovedDate = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  if (jobPhase.dateApproved) {
    const dateStart = dateWithoutServerTimezone(jobPhase.dateApproved);
    return `${
      dateStart.getMonth() + 1
    }/${dateStart.getDate()}/${dateStart.getFullYear()}`;
  }
  return '';
};

const showCameraPhase = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  if (BUMP_PHASES.includes(phaseType)) return false;

  if (jobPhase) {
    return !jobPhase.approved && jobPhase.contractorName && jobPhase.requested;
  }
  return false;
};

const isApproved = (phaseType: PhaseTypeEnum, theJob: Job, getPhase: any) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  if (jobPhase) {
    return jobPhase.approved;
  }
  return false;
};

const showPhaseImages = (
  phaseType: PhaseTypeEnum,
  theJob: Job,
  getPhase: any,
) => {
  const jobPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob?.jobPhases,
    phaseType,
  );
  return jobPhase.hasPhotos;
};

const isGarageDisabled = (theJob, getPhase) => {
  const jobPhase = theJob.jobPhase;
  if (theJob.jobPhase) {
    const garagePhase = getPhase(
      theJob.job,
      theJob.phases,
      theJob.jobPhases,
      PhaseTypeEnum['Garage'],
    );

    if (
      jobPhase.priority < 200 ||
      jobPhase.priority > 300 ||
      jobPhase.requested === true ||
      garagePhase.dateApproved
    ) {
      return true;
    }
  }
  return false;
};

const disableGarageDetails = (theJob, getPhase) => {
  const garagePhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob.jobPhases,
    PhaseTypeEnum['Garage'],
  );

  const hangingPhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob.jobPhases,
    PhaseTypeEnum['Hanging'],
  );

  if (hangingPhase.dateApproved && !garagePhase.dateApproved) {
    return true;
  }

  return false;
};

const onChangeGarage = (
  theJob,
  toggleGarage,
  refreshJobPhases,
  setToggleGarage,
  getPhase,
) => {
  const garagePhase = getPhase(
    theJob.job,
    theJob.phases,
    theJob.jobPhases,
    PhaseTypeEnum.Garage,
  );
  if (garagePhase) {
    const params = {
      id: garagePhase.id,
      data: {
        required: !toggleGarage,
      },
      previousData: garagePhase,
    };
    dataProvider.update('job-phase', params).then(_response => {
      refreshJobPhases();
      setToggleGarage(!toggleGarage);
    });
  }
};

const requestResourceOnPhase = async (
  theJob,
  notify,
  refreshJobPhases,
  suggestedContractorId,
  isMonday,
  sprayHour,
  sprayReadiness,
) => {
  const jobPhase = theJob.jobPhase;
  if (jobPhase) {
    const params: any = {
      id: jobPhase.id,
      data: {
        requested: true,
        requestDelayDays: isMonday ? 2 : 0,
      },
      previousData: jobPhase,
    };
    if (suggestedContractorId) {
      params.data.suggestedContractorId = suggestedContractorId;
    }
    if (sprayReadiness) {
      params.data.sprayReadinessStatus = sprayReadiness;
    }
    if (sprayHour) {
      params.data.readyHour = sprayHour;
    }
    await dataProvider
      .update('job-phase', params)
      .then(() => {
        notify('Resource Requested!');
        refreshJobPhases();
      })
      .catch(error => {
        notify(`Request Resource Error: ${error.message}`, 'warning');
      });
  }
};

const logBumpout = async (
  theJob,
  notify,
  refreshJobPhases,
  suggestedContractorId,
  startDate,
) => {
  const jobPhase = theJob.jobPhase;
  const isBumpoutTimesheetFlow = theJob.job.reqBumpoutTimesheet;

  if (jobPhase) {
    const startDateFormatted = startDate.toISO
      ? startDate.toISO()
      : startDate.toISOString();
    const params: any = {
      jobPhaseId: jobPhase.id,
      date: startDateFormatted,
      contractorId: suggestedContractorId,
    };
    return axiosInstance
      .post('job-phase-bump', params)
      .then(response => {
        console.log(response);
        notify(isBumpoutTimesheetFlow ? 'Bump requested!' : 'Bump Logged!');
        refreshJobPhases();
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          notify(`Bump Log Error: ${error.response.data.message}`, 'warning');
        } else {
          notify(`Bump Log Error: ${error.message}`, 'warning');
        }
      });
  }
};

const getNextStatusText = theJob => {
  const currentPhase = theJob.jobPhase;
  if (currentPhase?.phaseType === PhaseTypeEnum['Materials']) {
    return `Materials Sign Off`;
  }
  if (currentPhase.requested === true) {
    if (currentPhase.contractorId) {
      return `${currentPhase?.phaseName}: Resources Allocated`;
    }
    return `${currentPhase?.phaseName}: Resources Requested`;
  } else {
    return `${currentPhase?.phaseName}: Request Resource`;
  }
};

export const MasterSheetTimeline: React.FC<MasterSheetTimelineProps> = ({
  theJob,
  geoLocationError,
  getPhase,
  isInWarranty,
  setOpenConfirmGoBack,
  refreshJobPhases,
  toggleGarage,
  setToggleGarage,
  setIsViewPhotos,
  setUploadModalOpen,
  setSelectedPhase,
  showBump,
}) => {
  const classes = useStyles();
  const innerStyles = styles();

  const notify = useNotify();
  const [openContractorModal, setOpenContractorModal] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [openLogGarage, setOpenLogGarage] = useState(false);
  const [openConfirmRequest, setOpenConfirmRequest] = useState(false);
  const [openInitialDate, setOpenInitialDate] = useState(false);
  const [initialDate, setInitialDate] = useState('');
  const [selectedJobPhase, setSelectedJobPhasel] = useState<any>();

  const { hasPermission } = PermissionsProvider.useContainer();

  const selectJobForUpdate = async (phaseType: any) => {
    const jobPhaseFound = theJob.jobPhases.find(
      jobPhase => jobPhase.phaseType === phaseType,
    );

    const contractorHistory = await dataProvider.getList<any>(
      'job-phase-contractor-history-report',
      {
        filter: { jobPhaseId: jobPhaseFound.id },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      },
    );
    jobPhaseFound.jobPhaseContractorHistories = contractorHistory.data;
    setSelectedJobPhasel(jobPhaseFound);
    setOpenContractorModal(true);
  };

  const openJobPhaseHistoryModal = async (phaseType: any) => {
    const jobPhaseFound = theJob.jobPhases.find(
      jobPhase => jobPhase.phaseType === phaseType,
    );

    const contractorHistory = await dataProvider.getList<any>(
      'job-phase-contractor-history-report',
      {
        filter: { jobPhaseId: jobPhaseFound.id },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'id', order: 'ASC' },
      },
    );
    jobPhaseFound.jobPhaseContractorHistories = contractorHistory.data;
    setSelectedJobPhasel(jobPhaseFound);
    setOpenHistoryModal(true);
  };

  const needStrike = (phaseType: any) => {
    const jobPhaseFound = theJob.jobPhases.find(
      jobPhase => jobPhase.phaseType === phaseType,
    );
    return parseInt(jobPhaseFound.contractorHistoryCount) > 1;
  };

  const onConfirm = (
    suggestedContractorId: any,
    isMonday: boolean,
    sprayHour,
    isBump: boolean,
    startDate: any,
    sprayReadiness,
  ) => {
    if (isBump) {
      logBumpout(
        theJob,
        notify,
        refreshJobPhases,
        suggestedContractorId,
        startDate,
      );
    } else {
      requestResourceOnPhase(
        theJob,
        notify,
        refreshJobPhases,
        suggestedContractorId,
        isMonday,
        sprayHour,
        sprayReadiness,
      );
    }
    setOpenConfirmRequest(false);
  };

  const checkRequestResourcePermission = () => {
    if (theJob.jobPhase.contractorId && !theJob.jobPhase.approved) {
      return hasPermission(CAN_REQUEST_RESOURCE_ASSIGNED);
    }
    return hasPermission(CAN_REQUEST_RESOURCE);
  };

  const isJobPhaseApproved = theJob.jobPhase.approved;
  const hasContractor = !!theJob.jobPhase.contractorId;
  const isRequested = theJob.jobPhase.requested;
  const isBumpoutTimesheetFlow = theJob.job.reqBumpoutTimesheet;

  const isDisabled =
    isJobPhaseApproved ||
    (!hasContractor && !isRequested) ||
    theJob.jobPhase.priority < 200;

  useEffect(() => {
    const sprayPhase = theJob.jobPhases.find(
      jobPhase => jobPhase.priority === 500,
    );
    if (sprayPhase && sprayPhase.initialDateStart) {
      setInitialDate(sprayPhase.initialDateStart);
    }
  }, [theJob]);

  const checkPhaseStarted = (phaseName: string, theJob) => {
    const phase = getPhase(
      theJob.job,
      theJob.phases,
      theJob.jobPhases,
      PhaseTypeEnum[phaseName],
    );

    return phase.priority <= theJob.jobPhase.priority;
  };

  const getAccordionDetails = (phaseName: string) => {
    const isPhaseApproved = isApproved(
      PhaseTypeEnum[phaseName],
      theJob,
      getPhase,
    );
    const isBump = BUMP_PHASES.includes(phaseName);
    const resourceRequested = getResourceRequested(
      PhaseTypeEnum[phaseName],
      theJob,
      getPhase,
    );
    const canEditContractor = isBump
      ? resourceRequested && !isPhaseApproved
      : isPhaseApproved;

    return (
      <Box>
        <Box mb={2}>
          <Typography>
            Resource requested: <br />
            {getResourceRequested(PhaseTypeEnum[phaseName], theJob, getPhase)}
          </Typography>
        </Box>
        <Box mb={2} display="flex" alignItems="center">
          <Typography
            onClick={() => openJobPhaseHistoryModal(PhaseTypeEnum[phaseName])}
            className={
              needStrike(PhaseTypeEnum[phaseName])
                ? classes.striked
                : classes.normal
            }
          >
            Resource Allocated: <br />
            {getResourceAllocated(
              PhaseTypeEnum[phaseName],
              theJob,
              getPhase,
              isBump,
            )}
          </Typography>
          {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
            (isBumpoutTimesheetFlow ? canEditContractor : isPhaseApproved) && (
              <EditIcon
                onClick={() => selectJobForUpdate(PhaseTypeEnum[phaseName])}
                className={classes.editIcon}
              />
            )}
        </Box>
        <Box mb={2} display="flex">
          <Typography>
            Start date: <br />
            {getStartDate(PhaseTypeEnum[phaseName], theJob, getPhase)}
          </Typography>
          {theJob.jobPhase.phaseType === 'Spraying' && initialDate && (
            <InfoIcon
              className={classes.info}
              onClick={() => setOpenInitialDate(true)}
            />
          )}
        </Box>
        <Box mb={2}>
          <Typography>
            Approved date: <br />
            {getApprovedDate(PhaseTypeEnum[phaseName], theJob, getPhase)}
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography>
            Approved by <br />
            {getSupervisorApproved(PhaseTypeEnum[phaseName], theJob, getPhase)}
          </Typography>
        </Box>
        <Box>
          {!showCameraPhase(PhaseTypeEnum[phaseName], theJob, getPhase) &&
            showPhaseImages(PhaseTypeEnum[phaseName], theJob, getPhase) && (
              <CameraAltIcon
                className={classes.pointer}
                onClick={() => {
                  setIsViewPhotos(true);
                  setUploadModalOpen(true);
                  setSelectedPhase(
                    getPhase(
                      theJob.job,
                      theJob.phases,
                      theJob.jobPhases,
                      PhaseTypeEnum[phaseName],
                    ),
                  );
                }}
              />
            )}
        </Box>
      </Box>
    );
  };

  const phaseType = PhaseTypeEnum[theJob.jobPhase.phaseType];
  const isPhaseApproved = isApproved(phaseType, theJob, getPhase);
  const isBump = BUMP_PHASES.includes(phaseType);
  const bumpoutLabel = theJob.job.reqBumpoutTimesheet
    ? 'Request BumpOut'
    : 'Log BumpOut';

  return (
    <Box px={3}>
      <Box mb={2} mt={2}>
        <Box>
          <Typography className={innerStyles.titlePhases}>
            Current Phase:
          </Typography>
        </Box>

        <Box width="100%" mt={1} className={innerStyles.boxTitlePhases}>
          <Box display="flex" alignItems="center" p={2}>
            {geoLocationError && (
              <Typography className={classes.geoLocationError}>
                {geoLocationError}
              </Typography>
            )}
            <Box width="100%">
              <Typography className={classes.titleFont}>
                {getNextStatusText(theJob)}
              </Typography>

              <Box display="flex" justifyContent="space-between">
                <Box mt={2}>
                  <Box mb={2} display="flex">
                    <Typography
                      onClick={() => openJobPhaseHistoryModal(phaseType)}
                      className={
                        needStrike(phaseType) ? classes.striked : classes.normal
                      }
                    >
                      Resource Allocated: <br />
                      {getResourceAllocated(phaseType, theJob, getPhase)}
                    </Typography>
                    {hasPermission(CAN_OVERRIDE_CONTRACTOR) &&
                      !isBump &&
                      isPhaseApproved && (
                        <EditIcon
                          onClick={() => selectJobForUpdate(phaseType)}
                          className={classes.editIcon}
                        />
                      )}
                  </Box>
                  <Box mb={2} display="flex">
                    <Typography>
                      Start date: <br />
                      {getStartDate(phaseType, theJob, getPhase)}
                    </Typography>
                    {theJob.jobPhase.phaseType === 'Spraying' &&
                      initialDate && (
                        <InfoIcon
                          className={classes.info}
                          onClick={() => setOpenInitialDate(true)}
                        />
                      )}
                  </Box>
                </Box>

                <Box mt={2}>
                  {showCameraPhase(phaseType, theJob, getPhase) &&
                    hasPermission(CAN_APPROVE_PHASE) && (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        className={classes.buttonTimeline}
                        onClick={() => {
                          setIsViewPhotos(false);
                          setUploadModalOpen(true);
                          setSelectedPhase(
                            getPhase(
                              theJob.job,
                              theJob.phases,
                              theJob.jobPhases,
                              phaseType,
                            ),
                          );
                        }}
                      >
                        Approve
                      </Button>
                    )}
                </Box>
              </Box>

              {checkRequestResourcePermission() && (
                <Box
                  display="flex"
                  justifyContent="space-between"
                  width="100%"
                  mt={2}
                >
                  <Button
                    variant="contained"
                    size="large"
                    className={classes.buttonTimeline}
                    onClick={() => setOpenConfirmGoBack(true)}
                    disabled={isDisabled}
                  >
                    Go Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.buttonTimeline}
                    onClick={() => {
                      if (
                        hasPermission(CAN_LOG_GARAGE) &&
                        theJob.jobPhase.priority === 200
                      ) {
                        setOpenLogGarage(true);
                      } else {
                        setOpenConfirmRequest(true);
                      }
                    }}
                    disabled={
                      theJob.jobPhase.priority < 200 ||
                      theJob.jobPhase.requested === true
                    }
                  >
                    {hasPermission(CAN_LOG_GARAGE) &&
                    theJob.jobPhase.priority === 200
                      ? 'Log Garage'
                      : theJob.jobPhase.priority > 500
                      ? bumpoutLabel
                      : 'Request Resource'}
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <Accordion
        className={innerStyles.accordion}
        disabled={
          disableGarageDetails(theJob, getPhase) ||
          !checkPhaseStarted('Garage', theJob)
        }
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Garage</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box width="100%" mt={-2} mb={2}>
              {hasPermission(CAN_TOGGLE_GARAGE) && (
                <Switch
                  checked={toggleGarage}
                  onChange={() =>
                    onChangeGarage(
                      theJob,
                      toggleGarage,
                      refreshJobPhases,
                      setToggleGarage,
                      getPhase,
                    )
                  }
                  color="primary"
                  size="small"
                  name="checkedB"
                  disabled={isGarageDisabled(theJob, getPhase)}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              )}
            </Box>

            {getAccordionDetails('Garage')}
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        className={innerStyles.accordion}
        disabled={!checkPhaseStarted('Hanging', theJob)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Hanging</Typography>
        </AccordionSummary>
        <AccordionDetails>{getAccordionDetails('Hanging')}</AccordionDetails>
      </Accordion>

      <Accordion
        className={innerStyles.accordion}
        disabled={!checkPhaseStarted('Finishing', theJob)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Finishing</Typography>
        </AccordionSummary>
        <AccordionDetails>{getAccordionDetails('Finishing')}</AccordionDetails>
      </Accordion>

      <Accordion
        className={innerStyles.accordion}
        disabled={!checkPhaseStarted('Spraying', theJob)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Spraying</Typography>
        </AccordionSummary>
        <AccordionDetails>{getAccordionDetails('Spraying')}</AccordionDetails>
      </Accordion>

      {isInWarranty() && (
        <>
          <Accordion
            className={innerStyles.accordion}
            disabled={!checkPhaseStarted('Bump1', theJob)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>BumpOut 1</Typography>
            </AccordionSummary>
            <AccordionDetails>{getAccordionDetails('Bump1')}</AccordionDetails>
          </Accordion>
          <Accordion
            className={innerStyles.accordion}
            disabled={!checkPhaseStarted('Bump2', theJob)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>BumpOut 2</Typography>
            </AccordionSummary>
            <AccordionDetails>{getAccordionDetails('Bump2')}</AccordionDetails>
          </Accordion>
          <Accordion
            className={innerStyles.accordion}
            disabled={!checkPhaseStarted('Bump3', theJob)}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>BumpOut 3</Typography>
            </AccordionSummary>
            <AccordionDetails>{getAccordionDetails('Bump3')}</AccordionDetails>
          </Accordion>

          {showBump(PhaseTypeEnum.Bump4) && (
            <Accordion
              className={innerStyles.accordion}
              disabled={!checkPhaseStarted('Bump4', theJob)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>BumpOut 4</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {getAccordionDetails('Bump4')}
              </AccordionDetails>
            </Accordion>
          )}

          {showBump(PhaseTypeEnum.Bump5) && (
            <Accordion
              className={innerStyles.accordion}
              disabled={!checkPhaseStarted('Bump5', theJob)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>BumpOut 5</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {getAccordionDetails('Bump5')}
              </AccordionDetails>
            </Accordion>
          )}
        </>
      )}

      <ContractorChangeDialog
        regionId={theJob.job.regionId}
        handleClose={() => setOpenContractorModal(false)}
        open={openContractorModal}
        refreshJobPhases={refreshJobPhases}
        selectedJobPhase={selectedJobPhase}
        job={theJob}
      />
      {selectedJobPhase && (
        <ContractorHistoryDialog
          handleClose={() => setOpenHistoryModal(false)}
          open={openHistoryModal}
          selectedJobPhase={selectedJobPhase}
        />
      )}
      {theJob.jobPhase.priority === 200 && openLogGarage && (
        <LogGarage
          open={openLogGarage}
          handleClose={() => setOpenLogGarage(false)}
          refreshJobPhases={refreshJobPhases}
          selectedJobPhase={theJob.jobPhase}
          job={theJob}
          regionId={theJob.job.regionId}
        />
      )}
      {openConfirmRequest && (
        <ConfirmRequestDialog
          open={openConfirmRequest}
          handleClose={() => setOpenConfirmRequest(false)}
          selectedJobPhase={theJob.jobPhase}
          onConfirm={onConfirm}
          job={theJob}
          regionId={theJob.job.regionId}
        />
      )}
      <ConfirmDialog
        open={openInitialDate}
        title={`Initial Start date: ${
          initialDate
            ? dateWithoutServerTimezone(initialDate).toLocaleDateString()
            : ''
        }`}
        handleClose={() => setOpenInitialDate(false)}
      />
    </Box>
  );
};

export default MasterSheetTimeline;
