interface IErrors {
  firstName?: string[];
  lastName?: string[];
  email?: string[];
  primaryPhone?: string[];
  idNumber?: string[];
  password?: string[];
  id?: string[];
  fieldCoordinatorUserId?: string[];
  roles?: string[];
  '@@ra-many-to-many/user/user-role/role'?: string[];
  region?: string[];
}

type UserValues = {
  firstName: string;
  lastName: string;
  email: string;
  primaryPhone: string;
  idNumber: string;
  password: string;
  id: string;
  fieldCoordinatorUserId: string;
  '@@ra-many-to-many/user/user-role/role': string[];
  '@@ra-many-to-many/user/user-region/region': string[];
};

type UserCreation = {
  type: 'create' | 'edit';
  isCustomerServiceRep?: boolean;
  supervisorRoleId?: string;
  regions?: string[];
  roles?: string[];
};

export const validateUserCreation =
  ({
    type,
    isCustomerServiceRep,
    supervisorRoleId,
    regions,
    roles,
  }: UserCreation) =>
  async (values: UserValues): Promise<IErrors> => {
    const errors: IErrors = {};
    const isCreate = type === 'create';
    const regionField = values['@@ra-many-to-many/user/user-region/region'];
    const rolesField = values['@@ra-many-to-many/user/user-role/role'];

    if (!values.firstName) {
      errors.firstName = ['The firstName is required'];
    }

    if (!values.lastName) {
      errors.lastName = ['The lastName is required'];
    }

    if (!values.email) {
      errors.email = ['The email is required'];
    }

    if (!values.primaryPhone) {
      errors.primaryPhone = ['The primary phone number is required'];
    }

    if (!values.idNumber) {
      errors.idNumber = ['The id number is required'];
    }

    const rolesIds: string[] = values['@@ra-many-to-many/user/user-role/role'];

    if (rolesIds && rolesIds[0]) {
      const isSupervisor = rolesIds.some(id => id === supervisorRoleId);

      if (
        !isCreate &&
        isSupervisor &&
        !isCustomerServiceRep &&
        !values.fieldCoordinatorUserId
      ) {
        errors.fieldCoordinatorUserId = [
          'The Customer Service Representative is required',
        ];
      }
    }

    if (rolesField && rolesField.length === 0) {
      errors.roles = ['The Role is required'];
    }

    if (isCreate) {
      if (!roles || roles.length === 0) {
        errors.roles = ['The Role is required'];
      }
    }

    if (regionField && regionField.length === 0) {
      errors.region = ['The Region is required'];
    }

    if (isCreate) {
      if (!regions || regions.length === 0) {
        errors.region = ['The Region is required'];
      }
    }

    if (isCreate && !values.password) {
      errors.password = ['The password is required'];
    }

    return errors;
  };
