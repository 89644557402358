import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useDataProvider, GetListResult } from 'react-admin';
import { ContractorDto } from '@vatos-pas/common';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Alert from '@material-ui/lab/Alert';

const JobPhaseSprayReadyHourEnum = {
  '8am': 8,
  '9am': 9,
  '10am': 10,
  '11am': 11,
  '12pm': 12,
  '1pm': 13,
  '2pm': 14,
};

const useStyles = makeStyles({
  dialog: {
    width: '500px',
    '@media (max-width: 800px)': {
      width: 'auto',
    },
  },
  dateStarted: {
    marginTop: '0px',
  },
});

const convertFilters = (phaseName: string, regionId: string) => {
  const filter: {
    archived: boolean;
    active: boolean;
    suspended: boolean;
    'contractorRegions.regionId': string;
    hangerAvailable?: boolean;
    finisherAvailable?: boolean;
    sprayerAvailable?: boolean;
    bumperAvailable?: boolean;
  } = {
    active: true,
    suspended: false,
    archived: false,
    'contractorRegions.regionId': regionId,
  };

  const bumpPhases = ['Bump1', 'Bump2', 'Bump3', 'Bump4', 'Bump5'];

  if (phaseName === 'Hanging' || phaseName === 'Garage') {
    filter.hangerAvailable = true;
  } else if (phaseName === 'Finishing') {
    filter.finisherAvailable = true;
  } else if (phaseName === 'Spraying') {
    filter.sprayerAvailable = true;
  } else if (bumpPhases.includes(phaseName)) {
    filter.bumperAvailable = true;
  }

  return filter;
};

export const ConfirmRequestDialog = (props: any) => {
  const classes = useStyles();

  const [contractors, setContractors] = useState<ContractorDto[]>([]);
  const [contractorSelected, setContractorSelected] = useState('');
  const [sprayHour, setSprayHour] = useState('');
  const [isMonday, setIsMonday] = useState(false);
  const [sprayReadiness, setSprayReadiness] = useState<any>('');
  const [startDate, setStartDate] = useState(new Date());
  const dataProvider = useDataProvider();
  const isBump =
    props.selectedJobPhase && props.selectedJobPhase.priority > 500;
  const onChangeContractor = (event: any) => {
    setContractorSelected(event.target.value);
  };

  const onChangeSpray = (event: any) => {
    setSprayHour(event.target.value);
  };

  const getContractorBumps = () => {
    if (props.selectedJobPhase && props.selectedJobPhase > 500) {
      const currentPhase = props.job.phases.find(
        (phase: any) => phase.priority === props.selectedJobPhase.priority,
      );
      const previousPhase = props.job.jobPhases.find(
        (phase: any) => phase.priority === currentPhase.priority - 100,
      );
      const previousContractor = contractors.find(
        (contractor: any) => contractor.id === previousPhase.contractorId,
      );
      if (
        previousContractor &&
        (currentPhase.name === 'Bump 2' ||
          currentPhase.name === 'Bump 3' ||
          currentPhase.name === 'Bump 4' ||
          currentPhase.name === 'Bump 5')
      ) {
        if (
          previousContractor.bumperAvailable &&
          previousContractor.bumperPaymentType === 'PerBoard'
        ) {
          return setContractorSelected(previousContractor.id);
        }
      }
    }
    return setContractorSelected('');
  };

  const getContractors = async () => {
    const phaseType = props.selectedJobPhase.phaseType;
    const contractorData: GetListResult<ContractorDto> =
      await dataProvider.getList('contractor', {
        filter: {
          ...convertFilters(phaseType, props.regionId),
          $join: [{ field: 'contractorRegions' }],
        },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'name', order: 'ASC' },
      });
    if (contractorData.data) {
      setContractors(contractorData.data);
    } else {
      throw new Error('Job phases not found');
    }
  };

  useEffect(() => {
    setStartDate(new Date());
    setIsMonday(false);
    if (props.selectedJobPhase) {
      getContractors();
    }
  }, [props.selectedJobPhase]);

  useEffect(() => {
    if (contractors.length > 0) {
      getContractorBumps();
    }
  }, [contractors]);

  const checkConfirm = () => {
    const contractorFound = contractors.find(
      contractor => contractor.id === contractorSelected,
    );
    if (contractorFound) {
      setSprayReadiness('');
      setContractorSelected('');
      setSprayHour('');
      props.onConfirm(
        contractorSelected,
        isMonday,
        sprayHour,
        isBump,
        startDate,
        sprayReadiness,
      );
    } else {
      props.onConfirm(
        '',
        isMonday,
        sprayHour,
        isBump,
        startDate,
        sprayReadiness,
      );
    }
  };

  const isFriday = () => new Date().getDay() === 5;

  const phase = props.selectedJobPhase.phaseName;

  const bumpoutLabel = props.job.job.reqBumpoutTimesheet ? 'Request ' : 'Log ';

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-title">
        {isBump ? bumpoutLabel + phase : 'Request Resource'}
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        {isBump && (
          <Box display="flex" mb={2}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Start Date"
              fullWidth
              className={classes.dateStarted}
              value={startDate}
              onChange={(date: any) => setStartDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </Box>
        )}
        <Box display="flex">
          <Typography>
            {isBump ? 'Select a Contractor' : 'Suggest a Contractor'}
          </Typography>
        </Box>
        <Box>
          <Box display="flex">
            <Select
              onChange={onChangeContractor}
              labelId={`custom-reference-contractor`}
              id={`reference-contractor`}
              fullWidth
              value={contractorSelected}
              disabled={props.isLaborCoordinator}
            >
              <MenuItem key="Select" value="" data-item="" data-name="select">
                Select a contractor
              </MenuItem>
              {contractors.map((item: ContractorDto) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  data-item={item.id}
                  data-name={item.name}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          {!isBump && isFriday() && (
            <Box mt={2}>
              <Box>
                <Typography>
                  This request will be for a Saturday resource, if you need it
                  for Monday Please check below:
                </Typography>
              </Box>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isMonday}
                      onChange={(event: any) =>
                        setIsMonday(event.target.checked)
                      }
                      name="isMonday"
                      color="primary"
                    />
                  }
                  label="Monday Resource"
                />
              </Box>
            </Box>
          )}
          {props.selectedJobPhase && props.selectedJobPhase.priority === 500 && (
            <Fragment>
              <Box display="flex" mt={2}>
                <Typography>Spray Readiness</Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Select
                  onChange={event => {
                    setSprayReadiness(event.target.value);
                    setSprayHour('');
                  }}
                  labelId={`custom-reference-contractor`}
                  id={`reference-contractor`}
                  fullWidth
                  value={sprayReadiness}
                >
                  <MenuItem
                    key="ReadyLater"
                    value="ReadyLater"
                    data-item=""
                    data-name="select1"
                  >
                    Ready Later (Select Time)
                  </MenuItem>
                  <MenuItem
                    key="ReadyNow"
                    value="ReadyNow"
                    data-item=""
                    data-name="select2"
                  >
                    Ready Now
                  </MenuItem>
                  <MenuItem
                    key="Done"
                    value="Done"
                    data-item=""
                    data-name="select3"
                  >
                    Done
                  </MenuItem>
                </Select>
              </Box>
              <Box display="flex" mt={2}>
                <Typography>Spray Request Time</Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Select
                  onChange={onChangeSpray}
                  labelId={`custom-reference-contractor`}
                  id={`reference-contractor`}
                  fullWidth
                  disabled={
                    sprayReadiness === 'ReadyNow' || sprayReadiness === 'Done'
                  }
                  value={sprayHour}
                >
                  <MenuItem
                    key="Select"
                    value=""
                    data-item=""
                    data-name="select"
                  >
                    Select a Time
                  </MenuItem>
                  {Object.keys(JobPhaseSprayReadyHourEnum).map(hour => (
                    <MenuItem
                      key={JobPhaseSprayReadyHourEnum[hour]}
                      value={JobPhaseSprayReadyHourEnum[hour]}
                      data-item={JobPhaseSprayReadyHourEnum[hour]}
                      data-name={hour}
                    >
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
                {(sprayReadiness === 'ReadyNow' ||
                  sprayReadiness === 'Done') && (
                  <Box mt={1}>
                    <Alert severity="warning">
                      Spray request time cannot be selected when &apos;Ready
                      Now&apos; or &apos;Done&apos; is selected.
                    </Alert>
                  </Box>
                )}
              </Box>
              {/* {hasPermission(CAN_MARK_SPRAYING_READY_NOW) && (
                <Box mt={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={readyNow}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          setReadyNow(event.target.checked);
                          setSprayHour('');
                        }}
                        name="readyNow"
                        color="primary"
                      />
                    }
                    label={`Mark house as "Ready Now" for spraying.`}
                  />
                </Box>
              )} */}
            </Fragment>
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3}>
          <Button
            onClick={() => {
              setSprayReadiness('');
              setContractorSelected('');
              setSprayHour('');
              props.handleClose();
            }}
            color="primary"
          >
            Cancel request
          </Button>

          <Button
            onClick={checkConfirm}
            color="primary"
            disabled={
              isBump
                ? !contractorSelected || !startDate
                : props.selectedJobPhase &&
                  props.selectedJobPhase.priority === 500 &&
                  ((!sprayHour && sprayReadiness === 'ReadyLater') ||
                    !sprayReadiness)
            }
          >
            {props.job.job.reqBumpoutTimesheet
              ? 'Submit request'
              : isBump
              ? 'Confirm Log'
              : 'Submit request'}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmRequestDialog;
