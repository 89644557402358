import React, { useState, useEffect, Fragment, cloneElement, FC } from 'react';
import {
  useDataProvider,
  List,
  TextField,
  GetListResult,
  useNotify,
  sanitizeListRestProps,
  TopToolbar,
  useListContext,
  ExportButton,
  FunctionField,
  Pagination,
  FunctionFieldProps,
  ListProps,
  PublicFieldProps,
  InjectedFieldProps,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Datagrid from 'components/Datagrid';
import {
  JobPendingResourceDto,
  ContractorDto,
  JobPendingResourceInterface,
  JobPhaseInterface,
  JobPhaseOwnableInterface,
  PhaseTypeEnum,
} from '@vatos-pas/common';
import Box from '@material-ui/core/Box';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ConfirmDialog from 'components/ConfirmDialog';
import { RmMasterSheetFilter } from '../filters/rm-master-sheet-filters';
import { exporter } from 'providers/export';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { UIProvider } from 'providers/uiProvider';
import { CAN_ASSIGN_CONTRACTOR } from 'providers/permissions';
import InfoDialog from 'components/InfoDialog';
import { WithoutTimezoneDateField } from 'modules/master-sheet/views';

const JobPhaseSprayReadyHourEnum = {
  8: '8am',
  9: '9am',
  10: '10am',
  11: '11am',
  12: '12pm',
  13: '1pm',
  14: '2pm',
  Now: 'Now',
  Done: 'Done',
};

const useStyles = makeStyles({
  button: {
    marginLeft: '10px',
  },
  content: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  contentClosed: {
    '@media(max-width: 1300px)': {
      overflow: 'auto',
    },
  },
  root: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 260px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  rootClosed: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 125px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
});

const Ready: FC<
  Omit<FunctionFieldProps<JobPendingResourceInterface>, 'render'>
> = props => (
  <FunctionField<JobPendingResourceInterface>
    {...props}
    render={record => (
      <label>
        {record?.jobPhaseReadyHour &&
          JobPhaseSprayReadyHourEnum[record.jobPhaseReadyHour]}
      </label>
    )}
  />
);

const lateVerify = (record: JobPendingResourceDto & any) => {
  let backgroundColor = '#ffffff';

  if (record.isLateHangRequest && record.phaseName === PhaseTypeEnum.Hanging) {
    backgroundColor = '#ffc0c0';
  } else if (!record.requestIsOnTime) {
    backgroundColor = '#ff8282';
  }

  return {
    backgroundColor,
  };
};

const convertFilters = (phaseName: string, regionId: string) => {
  const filter: {
    archived: boolean;
    active: boolean;
    suspended: boolean;
    'contractorRegions.regionId': string;
    hangerAvailable?: boolean;
    finisherAvailable?: boolean;
    sprayerAvailable?: boolean;
    bumperAvailable?: boolean;
  } = {
    active: true,
    suspended: false,
    archived: false,
    'contractorRegions.regionId': regionId,
  };

  const bumpPhases = ['Bump 1', 'Bump 2', 'Bump 3', 'Bump 4', 'Bump 5'];

  if (phaseName === 'Hanging' || phaseName === 'Garage') {
    filter.hangerAvailable = true;
  } else if (phaseName === 'Finishing') {
    filter.finisherAvailable = true;
  } else if (phaseName === 'Spraying') {
    filter.sprayerAvailable = true;
  } else if (bumpPhases.includes(phaseName)) {
    filter.bumperAvailable = true;
  }

  return filter;
};

const getDefaultContractor = record => {
  const {
    suggestedContractorId,
    phaseName,
    previousBumpContractorBumperAvailable,
    previousBumpContractorBumperPaymentType,
    previousBumpContractorId,
  } = record;

  if (suggestedContractorId) return suggestedContractorId;

  if (['Bump 2', 'Bump 3', 'Bump 4', 'Bump 5'].includes(phaseName)) {
    if (
      previousBumpContractorBumperAvailable &&
      previousBumpContractorBumperPaymentType === 'PerBoard'
    )
      return previousBumpContractorId;
  }

  return '';
};

type ContractorProps = {
  confirmContractor: (
    contractorSelectedId: string,
    contractorNameSelected: string,
    jobSelectedId: string,
    jobIdSelected: string,
  ) => void;
  updated: string[];
  changedJobPhase: ChangeJobPhase[];
} & PublicFieldProps &
  InjectedFieldProps<JobPendingResourceDto>;

export const Contractor: FC<ContractorProps> = props => {
  const dataProvider = useDataProvider();
  const classes = useStyles();
  const [contractors, setContractors] = useState<ContractorDto[]>([]);
  const [contractorSelected, setContractorSelected] = useState(
    getDefaultContractor(props.record),
  );
  const [openAssignedSprayDateWarning, setOpenAssignedSprayDateWarning] =
    useState(false);
  const { hasPermission } = PermissionsProvider.useContainer();

  const getContractors = async () => {
    if (!props.record?.phaseName || !props.record?.regionId) return;

    const contractorData: GetListResult<ContractorDto> =
      await dataProvider.getList('contractor', {
        filter: {
          ...convertFilters(props.record.phaseName, props.record.regionId),
          $join: [{ field: 'contractorRegions' }],
        },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'name', order: 'ASC' },
      });
    if (contractorData.data) {
      setContractors(contractorData.data);
    } else {
      throw new Error('Job phases not found');
    }
  };

  const onChangeContractor = event => {
    setContractorSelected(event.target.value);
  };

  useEffect(() => {
    getContractors();
  }, []);

  const onClickSubmit = () => {
    if (
      props.record?.phaseName === 'Spraying' &&
      !props.record?.jobPhaseCustomDateStart
    ) {
      setOpenAssignedSprayDateWarning(true);
      return;
    }

    const contractor = contractors.find(
      contractor => contractor.id === contractorSelected,
    );
    if (contractor) {
      props.confirmContractor(
        contractorSelected,
        contractor.name,
        props.record?.jobPhaseId || '',
        props.record?.jobId || '',
      );
    }
  };

  const getUpdated = () =>
    props.updated.indexOf(props.record?.jobPhaseId || '') !== -1;

  const checkDisabled = () => {
    if (getUpdated()) {
      return true;
    }
    if (contractorSelected) {
      return false;
    }
    return true;
  };

  return (
    <Box display="flex">
      {props.record?.contractorId ? (
        <Box>
          <Typography>{props.record.contractorName}</Typography>
        </Box>
      ) : (
        <Fragment>
          <Select
            onChange={onChangeContractor}
            labelId={`custom-reference-contractor`}
            id={`reference-contractor`}
            fullWidth
            value={contractorSelected}
            disabled={!hasPermission(CAN_ASSIGN_CONTRACTOR) || getUpdated()}
          >
            {contractors.map((item: ContractorDto) => (
              <MenuItem
                key={item.id}
                value={item.id}
                data-item={props.record?.jobPhaseId}
                data-name={item.name}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {hasPermission(CAN_ASSIGN_CONTRACTOR) && (
            <Button
              disabled={checkDisabled()}
              variant="contained"
              color="primary"
              onClick={onClickSubmit}
              className={classes.button}
            >
              Assign
            </Button>
          )}
          <InfoDialog
            handleClose={() => {
              setOpenAssignedSprayDateWarning(false);
              setContractorSelected('');
            }}
            open={openAssignedSprayDateWarning}
            content="Before a contractor can be assigned, a Spray Date must be assigned on the Production List by the relevant supervisor."
          />
        </Fragment>
      )}
    </Box>
  );
};

const workdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const Workday = props => {
  const getWorkday = () => {
    const dateOfWorkday = new Date(props.record.jobPhaseDateRequestDue);
    dateOfWorkday.setDate(dateOfWorkday.getDate() + 1);
    return workdays[dateOfWorkday.getDay()];
  };

  const handleChange = event => {
    props.setChangedJobPhase([
      ...props.changedJobPhase,
      {
        jobPhaseId: props.record.jobPhaseId,
        requestDelayDays: event.target.value,
      },
    ]);
  };

  const getUpdated = () =>
    props.updated.indexOf(props.record.jobPhaseId) !== -1;

  return (
    <Box>
      {getWorkday() === workdays[1] || getWorkday() === workdays[6] ? (
        <Select
          style={{ width: '70px' }}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Age"
          onChange={handleChange}
          defaultValue={getWorkday() === 'Sat' ? 0 : 2}
          disabled={getUpdated()}
        >
          <MenuItem value={0}>{workdays[6]}</MenuItem>
          <MenuItem value={2}>{workdays[1]}</MenuItem>
        </Select>
      ) : (
        <Typography>{getWorkday()}</Typography>
      )}
    </Box>
  );
};

const ListActions = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

type JobPhaseParams = {
  id: string;
  data: {
    contractorId: string;
    requestDelayDays?: number;
  };
  previousData: JobPhaseInterface;
};

type ChangeJobPhase = Pick<JobPhaseOwnableInterface, 'jobPhaseId'> &
  JobPhaseInterface;

export const MasterSheetList: FC<ListProps> = props => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { opened } = UIProvider.useContainer();

  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmWarning, setOpenConfirmWarning] = useState(false);
  const [contractorSelected, setContractorSelected] = useState('');
  const [contractorName, setContractorName] = useState('');
  const [jobSelected, setJobSelected] = useState('');
  const [jobId, setJobId] = useState('');
  const [updated, setUpdated] = useState<string[]>([]);
  const [changedJobPhase, setChangedJobPhase] = useState<ChangeJobPhase[]>([]);

  const onClose = () => {
    setOpenConfirm(false);
    setOpenConfirmWarning(false);
  };

  const confirmContractor = (
    contractorSelectedId: string,
    contractorNameSelected: string,
    jobSelectedId: string,
    jobIdSelected: string,
  ) => {
    setContractorName(contractorNameSelected);
    setContractorSelected(contractorSelectedId);
    setJobSelected(jobSelectedId);
    setJobId(jobIdSelected);
    setOpenConfirm(true);
  };

  const onConfirmContractor = async () => {
    const pastPhases = await dataProvider.getList<JobPhaseInterface>(
      'job-phase',
      {
        filter: { jobId },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'priority', order: 'ASC' },
      },
    );
    const contractor = pastPhases.data.filter(
      pastPhase => pastPhase.contractorId === contractorSelected,
    );
    if (contractor.length) {
      setOpenConfirmWarning(true);
    } else {
      try {
        const jobPhaseFound = await dataProvider.getOne<JobPhaseInterface>(
          'job-phase',
          {
            id: jobSelected,
          },
        );
        const params: JobPhaseParams = {
          id: jobPhaseFound.data.id,
          data: { contractorId: contractorSelected },
          previousData: jobPhaseFound.data,
        };
        const changedPhase = changedJobPhase.find(
          changed => changed.jobPhaseId === jobPhaseFound.data.id,
        );
        if (changedPhase) {
          params.data.requestDelayDays = changedPhase.requestDelayDays;
        }
        await dataProvider.update('job-phase', params);
        const newUpdated = [...updated, jobPhaseFound.data.id];
        setUpdated(newUpdated);
        notify('Contractor Assigned!');
      } catch (error) {
        notify(error.message, 'warning');
      }
    }
    setOpenConfirm(false);
  };

  const confirmWarning = async () => {
    try {
      const jobPhaseFound = await dataProvider.getOne<JobPhaseInterface>(
        'job-phase',
        {
          id: jobSelected,
        },
      );
      const params: JobPhaseParams = {
        id: jobPhaseFound.data.id,
        data: { contractorId: contractorSelected },
        previousData: jobPhaseFound.data,
      };
      const changedPhase = changedJobPhase.find(
        changed => changed.jobPhaseId === jobPhaseFound.data.id,
      );
      if (changedPhase) {
        params.data.requestDelayDays = changedPhase.requestDelayDays;
      }
      await dataProvider.update('job-phase', params);
      const newUpdated = [...updated, jobPhaseFound.data.id];
      setUpdated(newUpdated);
      notify('Contractor Assigned!');
    } catch (error) {
      notify(error.message, 'warning');
    }
    setOpenConfirmWarning(false);
  };

  return (
    <Fragment>
      <List
        {...props}
        exporter={exporter('rm-master-sheet')}
        filters={<RmMasterSheetFilter>{props.filter}</RmMasterSheetFilter>}
        filterDefaultValues={{ 'contractorHasBeenAssigned||$eq': false }}
        sort={{ field: 'requestIsOnTime', order: 'ASC' }}
        actions={<ListActions maxResults={20000} />}
        bulkActionButtons={false}
        classes={{
          content: opened ? classes.content : classes.contentClosed,
          root: opened ? classes.root : classes.rootClosed,
        }}
        pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
        perPage={5}
      >
        <Datagrid rowStyle={lateVerify}>
          <TextField source="supervisorFullName" label="Supervisor" />
          <TextField source="subdivisionName" label="Subdivision" />
          <TextField source="jobLot" label="Lot" />
          <TextField source="jobBlock" label="Block" />
          <TextField source="builderName" label="Builder" />
          <TextField source="phaseName" label="Phase" />
          <WithoutTimezoneDateField
            source="jobPhaseDateRequested"
            label="Phase 1st Request Date"
          />
          <TextField source="phaseDaysCount" label="Phase Business Days" />
          <TextField source="jobDaysCount" label="Job Business Days" />
          <Workday
            source="jobPhaseDateRequestDue"
            label="Workday"
            updated={updated}
            setChangedJobPhase={setChangedJobPhase}
            changedJobPhase={changedJobPhase}
          />
          <Ready source="jobPhaseReadyHour" label="Ready?" />
          <TextField source="payableBoardsTotal" label="Payable Boards" />
          <TextField
            source="payableBoardsReceiptTotal"
            label="Supervisor Count"
          />
          <Contractor
            label="Contractor"
            updated={updated}
            changedJobPhase={changedJobPhase}
            confirmContractor={confirmContractor}
          />
        </Datagrid>
      </List>
      <ConfirmDialog
        open={openConfirm}
        title={`Are you sure that you want to assign the contractor ${contractorName}?`}
        handleClose={onClose}
        onConfirm={onConfirmContractor}
      />
      <ConfirmDialog
        showWarning
        open={openConfirmWarning}
        title={`You've previously assigned this contractor to this house. Are you sure you want to continue?`}
        handleClose={onClose}
        onConfirm={confirmWarning}
      />
    </Fragment>
  );
};
