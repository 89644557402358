import React, { FC, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  dialog: {
    width: '500px',
    '@media (max-width: 800px)': {
      width: 'auto',
    },
  },
  textItem: {
    fontSize: '14px',
  },
  separator: {
    height: 1,
    backgroundColor: 'lightgray',
    marginBottom: 8,
    marginTop: 8,
  },
});

export const ContractorHistoryDialog: FC<any> = props => {
  const classes = useStyles();

  const sortPhases = (a: any, b: any) =>
    new Date(a.createdAt) < new Date(b.createdAt) ? -1 : 1;

  if (!props.selectedJobPhase) {
    return <Fragment />;
  }

  const sortedPhases =
    props.selectedJobPhase.jobPhaseContractorHistories.sort(sortPhases);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogTitle id="alert-dialog-title">Contractor History</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Box display="flex" justifyContent="space-between">
          <Box width="30%">
            <Typography>Override Contractor</Typography>
          </Box>
          <Box width="30%">
            <Typography>Approval Manager</Typography>
          </Box>
          <Box width="30%">
            <Typography>Time Stamp</Typography>
          </Box>
        </Box>
        <Box className={classes.separator} />
        {props.selectedJobPhase &&
          sortedPhases.map((itemHistory, index) => (
            <Fragment key={itemHistory.id}>
              <Box
                key={itemHistory.id}
                display="flex"
                width="100%"
                justifyContent="space-between"
              >
                <Box width="30%">
                  <Typography className={classes.textItem}>
                    {itemHistory.contractor.name}
                  </Typography>
                </Box>
                <Box width="30%">
                  <Typography className={classes.textItem}>
                    {`${itemHistory.assigningUser.firstName} ${itemHistory.assigningUser.lastName}`}
                  </Typography>
                </Box>
                <Box width="30%">
                  <Typography className={classes.textItem}>
                    {index === 0
                      ? `Original`
                      : `${new Date(
                          itemHistory.updatedAt,
                        ).toLocaleDateString()}-${new Date(
                          itemHistory.updatedAt,
                        ).toLocaleTimeString()}`}
                  </Typography>
                </Box>
              </Box>
              {index !== sortedPhases.length - 1 && (
                <Box className={classes.separator} />
              )}
            </Fragment>
          ))}
        <Box display="flex" justifyContent="flex-end" mt={3}>
          <Button onClick={props.handleClose} color="primary">
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ContractorHistoryDialog;
