import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './master-sheet-styles';
import Button from '@material-ui/core/Button';

export const RepairShowContractorDialog: FC<any> = ({
  open,
  handleClose,
  isMobile,
  selectedContractors,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogContent>
        <Box className={classes.titleBorderBox}>
          <Typography className={classes.titleFont}>Contractors</Typography>
        </Box>
        <DialogContentText id="alert-dialog-desc9ription">
          <Box minWidth={isMobile ? 'unset' : '600px'} py={2}>
            {selectedContractors?.map((item, i) => {
              const {
                repairResourceType,
                contractor,
                estimatedHours,
                extraHours,
                fixedAmount,
              } = item;

              const title = estimatedHours
                ? 'Hours'
                : extraHours
                ? 'Extra Hours'
                : fixedAmount
                ? 'Fixed Amount $'
                : 'Per Day';

              const value = estimatedHours
                ? estimatedHours
                : extraHours
                ? extraHours
                : fixedAmount
                ? `$${fixedAmount}`
                : '-';

              const display = isMobile ? 'block' : 'flex';
              const mt = isMobile ? 2 : 0;

              return (
                <Box key={i} m={3} flexDirection="row" display={display}>
                  <Box display="flex" flex={2} flexDirection="column" mr={1}>
                    <Typography className={classes.titleFont}>
                      Resource Type
                    </Typography>
                    <Typography className={classes.titleFontBlack}>
                      {repairResourceType}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flex={4}
                    flexDirection="column"
                    mr={1}
                    mt={mt}
                  >
                    <Typography className={classes.titleFont}>
                      Contractor
                    </Typography>
                    <Typography className={classes.titleFontBlack}>
                      {contractor.name}
                    </Typography>
                  </Box>
                  <Box display="flex" flex={1} flexDirection="column" mt={mt}>
                    <Typography className={classes.titleFont}>
                      {title}
                    </Typography>
                    <Typography className={classes.titleFontBlack}>
                      {value}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </DialogContentText>
        <Box display="flex" justifyContent="flex-end" mb={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            className={classes.buttonRepairs}
          >
            Close
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default RepairShowContractorDialog;
