import React, { FC, useEffect, useMemo, useState } from 'react';
import { UpdateParams, useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddIcon from '@material-ui/icons/Add';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { luxon } from 'services/luxon';
import RepairPhotoUpload from './job-repairs-sheet-repair-photo-upload';
import RepairContractorDialog from './job-repairs-sheet-repair-contractor';
import { useStyles } from '../views/job-repairs-sheet-styles';
import { Repair, RepairPayment, Image } from '../views';
import { axiosInstance } from 'services/http';
import { Show } from 'components/Show';
import { getAddContractorsBlocked } from 'modules/master-sheet/utils/getAddContractorsBlocked';
import {
  JobRepairNoteType,
  JobRepairPhotoInterface,
  SubdivisionDto,
} from '@vatos-pas/common';
import { findOneSubdivision } from 'services/jobs';
import NotesManagement from 'modules/master-sheet/components/NotesManagement';

type RepairDialogProps = {
  repair: Repair;
  isShowView: boolean;
  handleConfirm(): void;
  handleClose(): void;
  open: boolean;
};

const getPayment = (repairPayment: RepairPayment) => {
  const { approvedHours, estimatedHours, extraHours, fixedAmount } =
    repairPayment;

  const type =
    // If it has `approvedHours` and doesn't have `estimatedHours`
    // then this record was created outside the bumpout timesheet flow, regardless of current subdivision flag.
    approvedHours && !estimatedHours
      ? 'Hours'
      : estimatedHours
      ? 'Estimated Hours'
      : extraHours
      ? 'Extra Hours'
      : fixedAmount
      ? 'Fixed Amount $'
      : 'Per Day';

  const amount =
    approvedHours && !estimatedHours
      ? approvedHours
      : estimatedHours
      ? estimatedHours
      : extraHours
      ? extraHours
      : fixedAmount
      ? fixedAmount
      : '';

  return { type, amount };
};

const validateFields = (fields: {
  repairDate: Date;
}): Record<string, boolean> => {
  const errors: Record<string, boolean> = {};

  if (!fields.repairDate) {
    errors['repairDate'] = true;
  }

  return errors;
};

export const RepairDialog: FC<RepairDialogProps> = ({
  open,
  isShowView,
  handleClose,
  handleConfirm,
  repair,
}) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [saving, setSaving] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [addContractorModalOpen, setAddContractorModalOpen] = useState(false);

  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [notes, setNotes] = useState(() => {
    const currentRepairNote = repair?.jobRepairNotes?.find(
      repair =>
        repair.isCurrent &&
        repair.jobRepairNoteType === JobRepairNoteType.DESCRIPTION,
    );

    return currentRepairNote?.text ?? '';
  });
  const [repairDate, setRepairDate] = useState(new Date(repair.repairDate));
  const [photos, setPhotos] = useState<Image[]>([]);
  const [photosStaged, setPhotosStaged] = useState<any>([]);
  const [payments, setPayments] = useState<RepairPayment[]>([]);
  const [complete, setComplete] = useState(repair.complete);
  const [deletedPayments, setDeletedPayments] = useState<string[]>([]);
  const [subdivision, setSubdivision] = useState<SubdivisionDto | null>(null);

  const isAddContractorsBlocked = getAddContractorsBlocked(repair);
  const currentRepairNote = repair?.jobRepairNotes?.find(
    repair =>
      repair.isCurrent &&
      repair.jobRepairNoteType === JobRepairNoteType.DESCRIPTION,
  );

  const classes = useStyles();

  const loadPayments = () => {
    const list = repair.repairPayments.map(
      ({
        id,
        approvedHours,
        contractorId,
        repairFormulaId,
        extraHours,
        fixedAmount,
        estimatedHours,
        contractor,
        repairResourceType,
        windowStartTime,
        windowEndTime,
      }) => ({
        id,
        contractorId,
        approvedHours,
        repairFormulaId,
        extraHours,
        fixedAmount,
        estimatedHours,
        contractor,
        repairResourceType,
        windowStartTime,
        windowEndTime,
      }),
    );

    setPayments(list);
  };

  const loadPhotos = async () => {
    try {
      const { id } = repair;
      const { data } = await dataProvider.getOne<Repair>('job-repair', {
        id,
      });
      const { jobRepairPhotos } = data;

      setPhotos(jobRepairPhotos);
    } catch (error) {
      notify('Failed to load repair photos', 'error');
    }
  };

  const stagePhoto = (fileName: any) =>
    setPhotosStaged([...photosStaged, fileName]);

  const onDeleteContractorClick = (payment: RepairPayment, index: number) => {
    const newPayments = [...payments];
    newPayments.splice(index, 1);
    setPayments(newPayments);

    if (payment.id) {
      setDeletedPayments(payments => [...payments, payment.id]);
    }
  };

  const onAddContractorClick = () => setAddContractorModalOpen(true);

  const handleUploadClick = () => {
    setUploadModalOpen(true);
  };

  const onConfirmClick = async () => {
    try {
      const newErrors = validateFields({ repairDate });
      setErrors(newErrors);
      if (Object.keys(newErrors).length > 0) return;

      setSaving(true);
      const { id } = repair;
      const promises: Promise<JobRepairPhotoInterface>[] = [];

      photosStaged.forEach((photo: any) => {
        const data = {
          jobRepairId: id,
          fileName: photo.fileName,
          repairPhotoType: 'Photo',
        };
        promises.push(axiosInstance.post('job-repair-photo', data));
      });

      if (
        // Loose equality to check for both `null` and `undefined`
        (currentRepairNote == undefined && notes) ||
        (currentRepairNote != undefined && notes !== currentRepairNote.text)
      ) {
        promises.push(
          axiosInstance.post('/job-repair-note', {
            text: notes.trim(),
            jobRepairId: repair?.id,
            jobRepairNoteType: JobRepairNoteType.DESCRIPTION,
          }),
        );
      }

      await Promise.all(promises);

      const insert = payments
        .filter(payment => !payment.id)
        .map(payment => {
          const {
            repairFormulaId,
            contractorId,
            estimatedHours,
            fixedAmount,
            extraHours,
            regionTimeWindowId,
          } = payment;
          return {
            jobRepairId: repair.id,
            repairFormulaId,
            contractorId,
            estimatedHours,
            fixedAmount,
            extraHours,
            regionTimeWindowId,
          };
        });

      const batchParams = { insert, delete: deletedPayments };
      await dataProvider.create('repair-payment/batch-process', {
        data: batchParams,
      });

      const { data: previousData } = await dataProvider.getOne('job-repair', {
        id,
      });

      const params: UpdateParams = {
        id,
        previousData,
        data: { complete, repairDate },
      };

      await dataProvider.update('/job-repair', params);

      handleClose();
      handleConfirm();
    } catch (error) {
      setSaving(false);
      notify(error.message, 'error');
    }
  };

  const hasChanges = useMemo(() => {
    const hasCompleteChange = repair.complete !== complete;
    const hasRepairDateChange = !luxon.isSameDay(
      luxon.date(repair.repairDate),
      luxon.date(repairDate),
    );
    const hasNewContractors = payments.some(payment => !payment.id);
    const hasDeletedContractors = deletedPayments.length > 0;
    const hasNotesChanges = currentRepairNote?.text !== notes;

    return (
      hasCompleteChange ||
      hasRepairDateChange ||
      hasNotesChanges ||
      hasNewContractors ||
      hasDeletedContractors
    );
  }, [
    repair,
    complete,
    repairDate,
    currentRepairNote?.text,
    notes,
    deletedPayments,
    payments,
  ]);

  const loadSubdivision = async () => {
    const subdivision = await findOneSubdivision(
      dataProvider,
      repair.subdivisionId,
    );

    if (subdivision) {
      setSubdivision(subdivision);
    }
  };

  useEffect(() => {
    loadPhotos();
    loadPayments();
    loadSubdivision();
  }, [repair]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-ti9tle">Repair</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-desc9ription">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            className={classes.info}
          >
            <Box flex={1}>
              <Typography className={classes.label}>Repair Type</Typography>
              <Typography>{repair.repairTypeDescription}</Typography>
            </Box>
            <Box flex={2} mt="-8px">
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="PO Request Date"
                fullWidth
                error={errors.repairDate}
                value={repairDate}
                onChange={selected => {
                  if (!selected) return;
                  setRepairDate(selected.toJSDate());
                }}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                disabled={!!repair.complete}
              />
            </Box>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            my={2}
            className={classes.info}
          >
            <Box flex={2}>
              <Typography className={classes.label}>Subdivision</Typography>
              <Typography>{repair.subdivisionName || '-'}</Typography>
            </Box>
            <Box flex={1}>
              <Typography className={classes.label}>Lot</Typography>
              <Typography>{repair.jobLot?.padStart(4, '0') || '-'}</Typography>
            </Box>
            <Box flex={1}>
              <Typography className={classes.label}>Building #</Typography>
              <Typography>
                {repair.buildingNumber?.padStart(3, '0') || '-'}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.info}
            >
              <Box>
                <Typography className={classes.label}>PO Total $</Typography>
                <Typography>{repair.poTotal}</Typography>
              </Box>
            </Box>
            <Box justifyContent="flex-end" display="flex" flex={1} gridGap={8}>
              <IconButton
                onClick={!repair.complete ? handleUploadClick : undefined}
              >
                <CloudUploadIcon className={classes.iconsUpload} />
              </IconButton>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            <NotesManagement
              enableHistory
              value={notes}
              disabled={isShowView}
              onChange={event => setNotes(event.target.value)}
              jobRepairId={repair?.id}
            />
          </Box>
          <Box className={classes.table} mt={2}>
            <Box className={classes.tableHeader}>
              <Typography>Contractors</Typography>
              {!repair.complete && (
                <IconButton
                  style={{
                    padding: 0,
                  }}
                  disabled={isAddContractorsBlocked}
                  onClick={onAddContractorClick}
                >
                  <AddIcon
                    className={
                      isAddContractorsBlocked
                        ? classes.iconAddDisabled
                        : classes.iconAdd
                    }
                  />
                </IconButton>
              )}
            </Box>
            {payments.map((payment, i) => {
              const { amount, type } = getPayment(payment);

              return (
                <Box
                  key={`repair-payment-${i.toString()}`}
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                  gridGap={16}
                  className={classes.tableLine}
                >
                  {!repair.complete && (
                    <Box onClick={() => onDeleteContractorClick(payment, i)}>
                      <DeleteIcon className={classes.iconDelete} />
                    </Box>
                  )}
                  <Box flex={2}>
                    <Typography className={classes.label}>Resource</Typography>
                    <Typography>{payment.repairResourceType}</Typography>
                  </Box>
                  <Box flex={4}>
                    <Typography className={classes.label}>
                      Contractor
                    </Typography>
                    <Typography>{payment.contractor?.name}</Typography>
                  </Box>
                  <Box flex={1}>
                    <Typography className={classes.label}>{type}</Typography>
                    <Typography>{amount}</Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Show condition={isAddContractorsBlocked}>
            <Typography
              style={{
                marginTop: 8,
              }}
            >
              Contractor is not able to be assigned until the PO has been
              released.
            </Typography>
          </Show>
          <Box display="flex" alignItems="center" mt={2}>
            <Checkbox
              disabled={repair.complete}
              checked={complete}
              onClick={() => setComplete(c => !c)}
            />
            <Typography>Repair Complete</Typography>
          </Box>
        </DialogContentText>
        <Box display="flex" justifyContent="space-between" mt={4} mb={2}>
          <Button
            color="default"
            variant="contained"
            className={classes.buttonRepairsCancel}
            onClick={handleClose}
          >
            {repair.complete ? 'Close' : 'Cancel'}
          </Button>
          {!repair.complete && (
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonRepairs}
              onClick={onConfirmClick}
              disabled={saving || !hasChanges}
            >
              Confirm
            </Button>
          )}
        </Box>
      </DialogContent>
      {uploadModalOpen && (
        <RepairPhotoUpload
          open
          handleClose={() => setUploadModalOpen(false)}
          setPhotosStaged={stagePhoto}
          photosStaged={photosStaged}
          photos={photos}
        />
      )}
      {addContractorModalOpen && (
        <RepairContractorDialog
          open
          repair={repair}
          isBumpoutTimesheetFlow={!!subdivision?.reqBumpoutTimesheet}
          handleClose={() => setAddContractorModalOpen(false)}
          handleConfirm={newPayment =>
            setPayments(oldPayments => [...oldPayments, newPayment])
          }
        />
      )}
    </Dialog>
  );
};

export default RepairDialog;
