/* eslint-disable no-debugger */
import React, { Fragment, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

import Typography from '@material-ui/core/Typography';
import CustomReference from 'components/CustomReference';
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider, useNotify } from 'react-admin';
import NotesManagementCheckCount from '../components/notes-management-check-count';
import { BoardPurchaseCheckCountNoteDto } from '../../../../../common/dist';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_ADD_NOTE_CHECK_COUNT } from 'providers/permissions';

const sum = function (items, prop) {
  return items.reduce(function (a, b) {
    return a + b[prop];
  }, 0);
};

export const CheckCountDialog = (props: any) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [floors, setFloors] = useState<any>([]);
  const [currentNote, setCurrentNote] = useState('');
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);

  const canAddNoteCheckCount = hasPermission(CAN_ADD_NOTE_CHECK_COUNT);

  const getCurrentNote = async () => {
    try {
      setLoading(true);

      const notesResponse =
        await dataProvider.getList<BoardPurchaseCheckCountNoteDto>(
          'board-purchase-check-count-note',
          {
            filter: {
              boardPurchaseId: props.boardPurchase.id,
              isCurrent: true,
            },
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'ASC' },
          },
        );

      const noteText = notesResponse.data?.[0]?.text ?? '';

      setCurrentNote(noteText);
      setNote(noteText);
    } catch (err) {
      notify('Failed to get notes', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const floorsNumber = [
      ...new Set(props.boardPurchaseFloors.map((item: any) => item.floor)),
    ];
    const newFloors: any = floorsNumber.map(floorNumber => ({
      name: floorNumber,
      boards: getBoardsPerFloor(floorNumber),
    }));
    const newFields = {};
    newFloors.map(floorItem => {
      newFields[floorItem.name] = {};
      floorItem.boards.map(boardItem => {
        const boardPurchaseFloor = props.boardPurchaseFloors.find(
          boardPurchaseFloorItem =>
            boardPurchaseFloorItem.floor === parseInt(floorItem.name) &&
            boardPurchaseFloorItem.boardTypeId === boardItem.boardTypeId,
        );
        newFields[floorItem.name][boardItem.boardTypeId] =
          boardPurchaseFloor?.quantity || 0;
      });
    });
    const floorsSorted = newFloors.sort((a, b) => (a.name < b.name ? -1 : 1));
    setFloors(floorsSorted);
  }, [props.boardPurchase]);

  useEffect(() => {
    if (props.boardPurchase?.id) {
      getCurrentNote();
    }
  }, [props.boardPurchase?.id]);

  const onConfirm = async () => {
    try {
      const trimmedNote = note?.trim();

      const params = {
        id: props.boardPurchase.id,
        data: {
          boardPurchaseId: props.boardPurchase.id,
          text: trimmedNote,
        },
      };

      await dataProvider.create('board-purchase-check-count-note', params);
      notify('Note updated successfully!');
      setCurrentNote(trimmedNote);
      props.handleClose();
    } catch (error) {
      notify(
        'An error occurred while adding the note. Please try again.',
        'warning',
      );
    }
  };

  const selectedPurchaseOrder = props.selectedPurchaseOrder;

  const getBoardsPerFloor = floorNumber => {
    const ordered = props.boardPurchaseFloors.filter(
      boardPurchaseFloor =>
        boardPurchaseFloor.floor === floorNumber &&
        boardPurchaseFloor.boardPurchaseId === props.boardPurchase.id,
    );
    const receipt = props.boardReceiptFloors.filter(
      boardReceiptFloor =>
        boardReceiptFloor.floor === floorNumber &&
        boardReceiptFloor.boardPurchaseId === props.boardPurchase.id,
    );
    const boardsType = [
      ...new Set([...ordered.map((item: any) => item.boardTypeId)]),
    ];
    const floorsBoards = boardsType.map(boardTypeId => ({
      boardTypeId,
      quantity: sum(
        ordered.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantity',
      ),
      quantityReceipt: sum(
        receipt.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantity',
      ),
    }));

    return floorsBoards;
  };

  const sumFloorsReceipt = () => {
    let sum = 0;
    floors.forEach(floorItems => {
      if (floorItems && floorItems.boards) {
        floorItems.boards.forEach(floorItem => {
          sum += floorItem.quantityReceipt;
        });
      }
    });
    return sum;
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
    >
      <DialogContent>
        <Fragment>
          {floors.map((floor, floorIndex) => (
            <Box
              key={floorIndex}
              className={classes.floorBoard}
              display="flex"
              width="calc(100% - 20px)"
              flexDirection="column"
              mt={1}
            >
              <Box display="flex" width="100%" alignItems="center">
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    Floor #{floor.name}
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    PO
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    Field Count
                  </Typography>
                </Box>
                <Box width="100%">
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.bold}
                  >
                    Delta
                  </Typography>
                </Box>
              </Box>
              {floor.boards.map((board, boardIndex) => (
                <Box
                  display="flex"
                  width="100%"
                  key={boardIndex}
                  alignItems="center"
                >
                  <CustomReference
                    value={board.boardTypeId || 0}
                    label="Board Type"
                    resource="board-type"
                    disabled={selectedPurchaseOrder !== null}
                    customItemName="shortName"
                    sort={{ field: 'shortName', order: 'ASC' }}
                  />
                  <TextField
                    label="Quantity"
                    fullWidth
                    className={classes.inputNoMarginRight}
                    disabled
                    value={board.quantity}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  <TextField
                    label="Quantity"
                    fullWidth
                    className={classes.inputNoMarginRight}
                    disabled
                    value={board.quantityReceipt || 0}
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                  <TextField
                    label="Quantity"
                    fullWidth
                    className={classes.inputNoMarginRight}
                    value={
                      (parseInt(board.quantityReceipt) || 0) -
                      (parseInt(board.quantity) || 0)
                    }
                    type="number"
                    disabled
                    InputProps={{ inputProps: { min: 0 } }}
                  />
                </Box>
              ))}
            </Box>
          ))}
          <Box
            className={classes.floorBoard}
            display="flex"
            width="calc(100% - 20px)"
            mt={1}
          >
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            ></Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            >
              Total: {sum(props.boardPurchaseFloors, 'quantity')}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            >
              Total: {sumFloorsReceipt()}
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              className={classes.boldInput}
            >
              Total:{' '}
              {sumFloorsReceipt() - sum(props.boardPurchaseFloors, 'quantity')}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" mt={2}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <NotesManagementCheckCount
                label="Notes"
                enableHistory
                disabled={props.disabled || !canAddNoteCheckCount}
                value={note}
                onChange={event => setNote(event.target.value)}
                boardPurchaseId={props?.boardPurchase?.id}
              />
            )}
          </Box>
        </Fragment>
        <Box display="flex" justifyContent="space-between" mt={3} pb={2}>
          <Button onClick={props.handleClose} color="primary">
            Cancel
          </Button>
          {canAddNoteCheckCount && (
            <Button
              variant="contained"
              onClick={onConfirm}
              color="primary"
              disabled={note === currentNote}
            >
              Confirm
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

const useStyles = makeStyles({
  input: {
    margin: '8px 0px',
  },
  inputNoMarginRight: {
    margin: '8px 0px 8px 15px',
  },
  bold: {
    fontWeight: 'bold',
  },
  boldInput: {
    fontWeight: 'bold',
    width: '100%',
  },
  floorBoard: {
    backgroundColor: '#efefef',
    borderRadius: '10px',
    padding: '10px',
  },
  dialog: {
    padding: 16,
  },
});

export default CheckCountDialog;
