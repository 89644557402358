import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import { useStyles } from '../master-sheet-styles';
import { BoardPurchaseCheckCountNoteDto } from '../../../../../../common/dist';
import NotesManagementCheckCount from 'modules/master-sheet/components/notes-management-check-count';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_ADD_NOTE_CHECK_COUNT } from 'providers/permissions';

const sum = (items: any[], prop: string) =>
  items.reduce<number>((a: any, b: any) => Number(a) + Number(b[prop]), 0);

interface Props {
  open: boolean;
  title: string;
  theJob: any;
  history: any;
  boardTypes: any[];
  handleClose: () => void;
  disabled: boolean;
  boardPurchase: any;
  boardReceipts: any[];
  boardReceiptFloors: any[];
  boardPurchaseFloors: any[];
}

export const CheckCountDialog: React.FC<Props> = ({
  open,
  title,
  theJob,
  boardTypes,
  handleClose,
  disabled = false,
  boardPurchase,
  boardReceipts,
  boardReceiptFloors,
  boardPurchaseFloors,
}) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [fields, setFields] = useState<any>([]);
  const [floors, setFloors] = useState<any>([]);
  const [currentNote, setCurrentNote] = useState('');
  const [note, setNote] = useState('');
  const [loading, setLoading] = useState(false);

  const canAddNoteCheckCount = hasPermission(CAN_ADD_NOTE_CHECK_COUNT);

  const getCurrentNote = async () => {
    try {
      setLoading(true);

      const notesResponse =
        await dataProvider.getList<BoardPurchaseCheckCountNoteDto>(
          'board-purchase-check-count-note',
          {
            filter: {
              boardPurchaseId: boardPurchase.id,
              isCurrent: true,
            },
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'id', order: 'ASC' },
          },
        );

      const noteText = notesResponse.data?.[0]?.text ?? '';

      setCurrentNote(noteText);
      setNote(noteText);
    } catch (err) {
      notify('Failed to get notes', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const floorsNumber = [
      ...new Set(boardPurchaseFloors.map((item: any) => item.floor)),
    ];
    const newFloors: any = floorsNumber.map(floorNumber => ({
      name: floorNumber,
      boards: getBoardsPerFloor(floorNumber),
    }));
    const newFields = {};
    newFloors.map(floorItem => {
      newFields[floorItem.name] = {};
      floorItem.boards.map(boardItem => {
        const boardPurchaseFloor = boardPurchaseFloors.find(
          boardPurchaseFloorItem =>
            boardPurchaseFloorItem.floor === parseInt(floorItem.name) &&
            boardPurchaseFloorItem.boardTypeId === boardItem.boardTypeId,
        );
        newFields[floorItem.name][boardItem.boardTypeId] =
          boardPurchaseFloor?.quantity || 0;
      });
    });
    setFields(newFields);
    const floorsSorted = newFloors.sort((a, b) => (a.name < b.name ? -1 : 1));
    setFloors(floorsSorted);
  }, [boardPurchase]);

  useEffect(() => {
    if (boardPurchase?.id) {
      getCurrentNote();
    }
  }, [boardPurchase?.id]);

  const onConfirm = async () => {
    try {
      const trimmedNote = note?.trim();

      const params = {
        id: boardPurchase.id,
        data: {
          boardPurchaseId: boardPurchase.id,
          text: trimmedNote,
        },
      };

      await dataProvider.create('board-purchase-check-count-note', params);
      notify('Note updated successfully!');
      setCurrentNote(trimmedNote);
      handleClose();
    } catch (error) {
      notify(
        'An error occurred while adding the note. Please try again.',
        'warning',
      );
    }
  };
  const getBoardsPerFloor = floorNumber => {
    const ordered = boardPurchaseFloors.filter(
      boardPurchaseFloor =>
        boardPurchaseFloor.floor === floorNumber &&
        boardPurchaseFloor.boardPurchaseId === boardPurchase.id,
    );
    const receipt = boardReceiptFloors.filter(
      boardReceiptFloor =>
        boardReceiptFloor.floor === floorNumber &&
        boardReceiptFloor.boardPurchaseId === boardPurchase.id,
    );
    const boardsType = [
      ...new Set([...ordered.map((item: any) => item.boardTypeId)]),
    ];
    const floorsBoards = boardsType.map(boardTypeId => ({
      boardTypeId,
      quantity: sum(
        ordered.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantity',
      ),
      quantityReceipt: sum(
        receipt.filter(orderedItem => orderedItem.boardTypeId === boardTypeId),
        'quantity',
      ),
    }));

    return floorsBoards;
  };

  const isCountedWrong = boardType => {
    if (!boardReceipts.length) {
      return false;
    }

    if (!boardType) {
      return false;
    }

    const purchaseBoards = boardPurchase.boardPurchaseFloors.filter(
      boardReceipt => boardReceipt.boardTypeId === boardType.id,
    );

    const originalPO = sum(purchaseBoards, 'quantity');

    const receiptBoards = theJob.boardReceiptFloors.filter(
      boardReceipt => boardReceipt.boardTypeId === boardType.id,
    );

    const counted = sum(receiptBoards, 'quantity');
    return originalPO !== counted;
  };

  const isFloorCountedWrong = (floorNumber: number) =>
    getBoardsPerFloor(floorNumber).some(
      ({ quantity, quantityReceipt }) => quantity !== quantityReceipt,
    );

  const sumFloorFields = (floorNumber: number) => {
    const floor = fields[floorNumber];
    if (!floor) return 0;
    return Object.values(floor).reduce<number>(
      (acc: number, value) => acc + Number(value),
      0,
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title-"
      aria-describedby="alert-dialog-description9"
      fullWidth
    >
      <DialogContent>
        <Box mb={2}>
          <Typography gutterBottom>{title}</Typography>
        </Box>
        <Box>
          {floors.map((floor, floorIndex) => (
            <Box key={floorIndex} className={classes.whiteBoxMobile}>
              <Box className={classes.floorHeader}>
                <Typography>Floor #{floor.name}</Typography>
              </Box>
              {floor.boards.map(board => {
                const boardType = boardTypes.find(
                  b => b.id === board.boardTypeId,
                );

                const delta =
                  (parseInt(board.quantityReceipt) || 0) -
                  (parseInt(board.quantity) || 0);

                return (
                  <Box
                    key={board.id}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    className={classes.floorGroupMobile}
                  >
                    <Box display="flex" flexDirection="row">
                      <Box display="flex" flex={0.5}>
                        <Box>
                          <Typography
                            className={
                              isCountedWrong(boardType)
                                ? classes.contedWrong
                                : ''
                            }
                          >
                            {boardType?.shortName}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        flex={1}
                        gridGap={16}
                        display="flex"
                        flexDirection="column"
                      >
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.mobileFieldText}>
                            PO
                          </Typography>
                          <Typography
                            className={classes.mobileFieldTextCentered}
                          >
                            {board.quantity}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.mobileFieldText}>
                            Field Count
                          </Typography>
                          <Typography
                            className={classes.mobileFieldTextCentered}
                          >
                            {board.quantityReceipt}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.mobileFieldText}>
                            Delta
                          </Typography>
                          <Typography
                            className={classes.mobileFieldTextCentered}
                          >
                            {delta}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                );
              })}
              <Box
                display="flex"
                justifyContent="center"
                className={classes.floorGroupMobile}
              >
                <Box display="flex" flex={1} alignItems="center">
                  <Typography>Total boards</Typography>
                </Box>
                <Box
                  display="flex"
                  flex={0.5}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    className={
                      isFloorCountedWrong(floor.name) ? classes.error : ''
                    }
                  >
                    {sumFloorFields(floor.name)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <NotesManagementCheckCount
              label="Notes"
              enableHistory
              disabled={disabled || !canAddNoteCheckCount}
              value={note}
              onChange={event => setNote(event.target.value)}
              boardPurchaseId={boardPurchase?.id}
            />
          )}
        </Box>
        <Box display="flex" justifyContent="space-between" mt={3} pb={2}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          {canAddNoteCheckCount && (
            <Button
              variant="contained"
              onClick={onConfirm}
              color="primary"
              disabled={note === currentNote}
            >
              Confirm
            </Button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default CheckCountDialog;
