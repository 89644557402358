import React, { useEffect, useRef, useState } from 'react';

import { Identifier, useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Email from '@material-ui/icons/Email';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { SubdivisionBuilderSupervisorDto } from '@vatos-pas/common';
import InfoDialog from 'components/InfoDialog';
import InfoIcon from '@material-ui/icons/Info';
import CircularProgress from '@material-ui/core/CircularProgress';

import RepairShowContractorDialog from '../master-sheet-repair-show-contractor';
import MasterSheetRepairsCheckPhotos from '../master-sheet-repairs-check-photos';
import { useStyles } from '../master-sheet-styles';
import AddRepairDialog from '../master-sheet-add-repair';
import {
  CAN_ADD_REPAIR_CONTRACTOR,
  CAN_CREATE_REPAIR,
  CAN_SEND_EMAIL_CLIENT,
} from 'providers/permissions';
import { Show } from 'components/Show';
import { PermissionsProvider } from 'providers/permissionsProvider';

import RepairDialog from 'modules/master-sheet/components/repair-modal';
import { Repair } from 'modules/job-repair-sheet/views';
import { sendMail } from 'modules/master-sheet/utils/emailManagement';
import { maskDate } from 'modules/master-sheet/utils/maskDate';
import { getAddContractorsBlocked } from 'modules/master-sheet/utils/getAddContractorsBlocked';
import { EmailManagementDialog } from 'modules/master-sheet/components/email-management-dialog';

interface MasterSheetRepairsProps {
  theJob: any;
  refreshRepairs: any;
  initialValue?: string;
  setJob?: any;
}

const styles = makeStyles({
  boxTitlePhases: {
    background: '#f2f2f2',
    borderRadius: '5px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  titlePhases: {
    fontWeight: 'bold',
  },
  accordion: {
    marginBottom: '10px',
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  value: {
    fontSize: '17px',
  },
  label: {
    fontSize: '12px',
  },
  title: {
    fontSize: '17px',
    fontWeight: 'bold',
  },
});

export const MasterSheetRepairs: React.FC<MasterSheetRepairsProps> = ({
  theJob,
  refreshRepairs,
  initialValue,
  setJob,
}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const { hasPermission } = PermissionsProvider.useContainer();
  const scroll = useRef<HTMLDivElement>(null);
  const notify = useNotify();

  const [poReleasedInfoOpen, setPoReleasedInfoOpen] = useState(false);
  const [showRepairModal, setShowRepairModal] = useState(false);
  const [showAddRepairModal, setShowAddRepairModal] = useState(false);
  const [repair, setRepair] = useState<any>();
  const [emailRepairId, setEmailRepairId] = useState<Identifier | null>(null);
  const [openContractorsModal, setOpenContractorsModal] = useState(false);
  const [openCheckPhotosModal, setOpenCheckPhotosModal] = useState(false);
  const [isShowView, setIsShowView] = useState(false);
  const [title, setTitle] = useState('');
  const [selectedContractors, setSelectedContractors] = useState<any[]>([]);
  const [photos, setPhotos] = useState<any>([]);
  const [subdivisionBuilderSupervisors, setSubdivisionBuilderSupervisors] =
    useState<SubdivisionBuilderSupervisorDto[] | null>(null);
  const [loadingBuilderSupervisorEmail, setLoadingBuilderSupervisorEmail] =
    useState(false);
  const [hasPhotos, setHasPhotos] = useState(false);
  const [emailManagementOpen, setEmailManagementOpen] = useState(false);

  const innerStyles = styles();
  const canSendEmailClient = hasPermission(CAN_SEND_EMAIL_CLIENT);

  const getSubdivisionBuilderSupervisors = async (subdivisionId: string) => {
    const builderSupervisors =
      await dataProvider.getList<SubdivisionBuilderSupervisorDto>(
        'subdivision-builder-supervisor',
        {
          filter: { subdivisionId },
          pagination: { page: 1, perPage: 999 },
          sort: { field: 'user.firstName', order: 'ASC' },
        },
      );

    if (builderSupervisors?.data.length) {
      setSubdivisionBuilderSupervisors(builderSupervisors.data);
    }
  };

  const onAddRepair = async (repair: Repair, hasPhotos: boolean) => {
    // Get refresh repairs because when creating a repair
    // the builder supervisors information doesn't get joined in the response.
    const refreshedRepairs = await refreshRepairs();

    const createdRepair = refreshedRepairs?.find(
      record => record.id === repair?.id,
    );

    if (!createdRepair) return;

    setRepair(createdRepair);
    setShowRepairModal(true);

    if (canSendEmailClient) {
      setEmailRepairId(createdRepair.id);
      await handleSendMail(createdRepair.id);
      setHasPhotos(hasPhotos);
      setEmailManagementOpen(true);
    }
  };

  const handleSendMail = async (repairId: Identifier | undefined | null) => {
    if (!repairId) return;

    try {
      setLoadingBuilderSupervisorEmail(true);
      await sendMail(repairId);
    } catch (error) {
      notify(error.message, 'warning');
    } finally {
      setLoadingBuilderSupervisorEmail(false);
    }
  };

  const handleUploadClick = async (repair: any) => {
    const jobRepairPhotos = await dataProvider.getList<any>(
      'job-repair-photo',
      {
        filter: { jobRepairId: repair.id },
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'createdAt', order: 'ASC' },
      },
    );

    if (jobRepairPhotos.data) {
      setTitle('Photos');
      setPhotos(jobRepairPhotos.data);
      setOpenCheckPhotosModal(true);
    }
  };

  const repairs = theJob?.jobRepairs;

  const canCreateRepair = hasPermission(CAN_CREATE_REPAIR);
  const canAddContractor = hasPermission(CAN_ADD_REPAIR_CONTRACTOR);

  useEffect(() => {
    const openInitialRepair = () => {
      if (!initialValue) return;
      scroll.current?.scrollIntoView();
    };
    getSubdivisionBuilderSupervisors(theJob.job.subdivisionId);
    openInitialRepair();
  }, [initialValue]);

  return (
    <Box px={3}>
      <Box display="flex" justifyContent="flex-end" my={3}>
        {canCreateRepair && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.buttonRepairs}
            onClick={() => setShowAddRepairModal(true)}
          >
            Add Repair
          </Button>
        )}
      </Box>
      <div ref={scroll} />
      <Box width="100%">
        {repairs.map((repair, i) => {
          const { repairPayment } = repair;
          const { description, repairDate, poTotal, text, hasPhotos } = repair;

          const canEdit = canAddContractor && !repair.complete;
          const canView = canAddContractor ? true : repair.complete;
          const isAddContractorsBlocked = getAddContractorsBlocked(repair);

          const buttonText = canEdit
            ? isAddContractorsBlocked
              ? 'Review Details'
              : 'Add Contractor'
            : 'Show Contractors';

          const onActionClick = () => {
            setRepair(repair);
            setIsShowView(!canEdit);
            setSelectedContractors(repairPayment);
            setShowRepairModal(true);
          };

          return (
            <>
              <Accordion className={innerStyles.accordion} key={i}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <Box display="flex" gridGap={2}>
                      <Box>
                        <Typography className={innerStyles.label}>
                          Service Type
                        </Typography>
                        <Typography className={innerStyles.value}>
                          {description}
                        </Typography>
                      </Box>
                      <Show condition={isAddContractorsBlocked}>
                        <IconButton
                          onClick={event => {
                            event.stopPropagation();
                            setPoReleasedInfoOpen(true);
                          }}
                        >
                          <InfoIcon color="error" />
                        </IconButton>
                      </Show>
                    </Box>

                    <Box>
                      <Typography className={innerStyles.label}>
                        Date
                      </Typography>
                      <Typography className={innerStyles.value}>
                        {maskDate(repairDate)}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width="100%">
                    <Box>
                      <Typography className={innerStyles.label}>
                        PO Total $
                      </Typography>
                      <Typography className={innerStyles.value}>
                        {poTotal}
                      </Typography>
                    </Box>
                    <Show condition={canSendEmailClient}>
                      <Box mt={2}>
                        <Typography className={innerStyles.label}>
                          Email Management
                        </Typography>

                        <Typography className={classes.bigText}>
                          <Box width="100%">
                            <Typography className={classes.bigText}>
                              <Show condition={canSendEmailClient}>
                                <IconButton
                                  disabled={loadingBuilderSupervisorEmail}
                                  onClick={async () => {
                                    setEmailRepairId(repair?.id);
                                    setHasPhotos(hasPhotos);
                                    setEmailManagementOpen(true);
                                    setRepair(repair);
                                  }}
                                >
                                  {repair?.id === emailRepairId &&
                                  loadingBuilderSupervisorEmail ? (
                                    <CircularProgress size={30} />
                                  ) : (
                                    <Email className={classes.iconsUpload} />
                                  )}
                                </IconButton>
                              </Show>
                            </Typography>
                          </Box>
                        </Typography>
                      </Box>
                    </Show>
                    <Box borderBottom="1px solid black" width="100%" mt={2}>
                      <Typography className={innerStyles.title}>
                        Description
                      </Typography>
                    </Box>
                    <Box>
                      <Typography className={innerStyles.value}>
                        {text}
                      </Typography>
                    </Box>
                    <Box width="100%" display="flex" my={1}>
                      {hasPhotos ? (
                        <Box>
                          <IconButton onClick={() => handleUploadClick(repair)}>
                            <CloudUploadIcon className={classes.iconsUpload} />
                          </IconButton>
                        </Box>
                      ) : null}
                    </Box>
                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={!canView}
                        className={classes.buttonRepairs}
                        onClick={onActionClick}
                      >
                        {buttonText}
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
      </Box>
      <AddRepairDialog
        open={showAddRepairModal}
        subdivisionBuilderSupervisors={subdivisionBuilderSupervisors}
        theJob={theJob}
        onClose={() => setShowAddRepairModal(false)}
        onAdd={onAddRepair}
        isMobile
      />
      {showRepairModal && (
        <RepairDialog
          open
          isShowView={isShowView}
          subdivisionBuilderSupervisors={subdivisionBuilderSupervisors}
          repair={repair}
          theJob={theJob}
          handleClose={() => setShowRepairModal(false)}
          handleConfirm={refreshRepairs}
          setJob={setJob}
        />
      )}
      <RepairShowContractorDialog
        isMobile
        selectedContractors={selectedContractors}
        handleClose={() => setOpenContractorsModal(false)}
        open={openContractorsModal}
      />
      <MasterSheetRepairsCheckPhotos
        photos={photos}
        title={title}
        handleClose={() => setOpenCheckPhotosModal(false)}
        open={openCheckPhotosModal}
      />
      <EmailManagementDialog
        hasPhotos={hasPhotos}
        emailManagementOpen={emailManagementOpen}
        loadingBuilderSupervisorEmail={loadingBuilderSupervisorEmail}
        emailRepairId={emailRepairId}
        handleSendMail={handleSendMail}
        handleUploadClick={() => handleUploadClick(repair)}
        handleClose={() => {
          setEmailManagementOpen(false);
          setHasPhotos(false);
          setEmailRepairId(null);
        }}
      />
      <InfoDialog
        handleClose={() => setPoReleasedInfoOpen(false)}
        open={poReleasedInfoOpen}
        content="Contractor is not able to be assigned until the PO has been released."
      />
    </Box>
  );
};

export default MasterSheetRepairs;
