/* eslint-disable */
import React, { useEffect, useState, FC, useRef } from 'react';
import {
  Edit,
  TextInput,
  BooleanInput,
  SelectArrayInput,
  useNotify,
  useRefresh,
  useDataProvider,
  useRedirect,
  ReferenceInput,
  SelectInput,
  EditProps,
  Record,
} from 'react-admin';
import { useFormState } from 'react-final-form';
import {
  ReferenceManyToManyInput,
  ManyToManyReferenceContextProvider,
} from '@react-admin/ra-relationships';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import SimpleForm from 'components/SimpleForm';
import CustomSelect from 'components/CustomSelect';
import { getSupervisors } from 'services/regions';
import TextField from '@material-ui/core/TextField';
import { validateUserCreation } from './userValidation';
import { AppRole } from 'providers/roles';
import { RoleDto, RoleInterface, UserRoleInterface } from '@vatos-pas/common';
import { useHistory } from 'react-router-dom';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { FormHelperText } from '@material-ui/core';

type LocationState = {
  formState: Record;
  selectedBumpers: string[];
};

const useStyles = makeStyles({
  input: {
    margin: '0px 15px',
  },
  fields: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  createBox: {
    maxWidth: '1500px',
  },
  halfWidth: {
    width: '50%',
    margin: '0px 15px',
  },
});

interface ICustomItem {
  name: string;
  id: string;
}

const callSupervisors = async (
  setSupervisors: React.Dispatch<React.SetStateAction<ICustomItem[]>>,
  regionId: string,
) => {
  const users = await getSupervisors({ regionId });
  setSupervisors(
    users.data.data.map(({ id, firstName, lastName }) => ({
      id,
      name: `${firstName} ${lastName}`,
    })),
  );
};

const isSupervisor = (roles?: RoleInterface[]): boolean => {
  if (!roles) return false;
  return roles.some(role => role.name === 'Supervisor');
};

const isCustomerServiceRepDisabled = (
  roles: string[],
  builderSupervisorRoleId: string,
  hasRole: (role: AppRole | AppRole[]) => boolean,
) => {
  const hasBuilderSupervisorRole =
    Array.isArray(roles) &&
    roles.some(role => role === builderSupervisorRoleId);
  const isCustomerServiceRep = hasRole(AppRole.CustomerServiceRepresentative);

  return (
    isCustomerServiceRep && (roles?.length > 1 || !hasBuilderSupervisorRole)
  );
};

const getRegionalManagerName = (
  supervisors: ICustomItem[],
  manager: string | undefined,
) => {
  if (!supervisors?.length || !manager) return '';
  const regionalManager = supervisors.find(s => s.id === manager);
  if (!regionalManager) return '';
  return regionalManager.name;
};

type RegionalManagerFieldsProps = {
  supervisors: ICustomItem[];
  manager: string | undefined;
  supervisorRoleId: string;
  builderSupervisorRoleId: string;
};

const RegionalManagerFields: FC<RegionalManagerFieldsProps> = ({
  manager,
  supervisors,
  supervisorRoleId,
  builderSupervisorRoleId,
}) => {
  const { values } = useFormState();
  const { hasRole } = PermissionsProvider.useContainer();
  const classes = useStyles();

  const roles: string[] = values['@@ra-many-to-many/user/user-role/role'];
  const hasSupervisorRole =
    Array.isArray(roles) && roles.some(role => role === supervisorRoleId);

  const isDisabled = isCustomerServiceRepDisabled(
    roles,
    builderSupervisorRoleId,
    hasRole,
  );

  if (!hasSupervisorRole) return null;

  return (
    <Box display="flex">
      <TextField
        label="Regional Manager"
        fullWidth
        disabled
        className={classes.input}
        value={getRegionalManagerName(supervisors, manager)}
      />
      <ReferenceInput
        label="Customer Service Representative"
        source="fieldCoordinatorUserId"
        reference="user"
        resettable
        data-cy="RmMasterSheetFilterSupervisor"
        alwaysOn
        allowEmpty={false}
        filterToQuery={value => {
          if (value) {
            return {
              s: {
                $and: [
                  {
                    $or: [
                      { firstName: { $cont: value } },
                      { lastName: { $cont: value } },
                    ],
                  },
                  {
                    'userRoles.role.name': {
                      $eq: AppRole.CustomerServiceRepresentative,
                    },
                  },
                ],
              },
            };
          } else {
            return {
              s: {
                'userRoles.role.name': {
                  $eq: AppRole.CustomerServiceRepresentative,
                },
              },
            };
          }
        }}
        sort={{ field: 'firstName', order: 'ASC' }}
      >
        <SelectInput
          disabled={isDisabled}
          initialValue={null}
          fullWidth
          optionText={(user: any) => `${user.firstName} ${user.lastName}`}
        />
      </ReferenceInput>
    </Box>
  );
};

const NameFields = ({ builderSupervisorRoleId }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const { hasRole } = PermissionsProvider.useContainer();

  const roles: string[] = values['@@ra-many-to-many/user/user-role/role'];
  const isDisabled = isCustomerServiceRepDisabled(
    roles,
    builderSupervisorRoleId,
    hasRole,
  );

  return (
    <Box className={classes.fields}>
      <TextInput
        disabled={isDisabled}
        className={classes.input}
        fullWidth
        source="firstName"
      />
      <TextInput
        disabled={isDisabled}
        className={classes.input}
        fullWidth
        source="lastName"
      />
    </Box>
  );
};

const UserInfoFields = ({ builderSupervisorRoleId }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const { hasRole } = PermissionsProvider.useContainer();

  const roles: string[] = values['@@ra-many-to-many/user/user-role/role'];
  const isDisabled = isCustomerServiceRepDisabled(
    roles,
    builderSupervisorRoleId,
    hasRole,
  );

  return (
    <Box className={classes.fields}>
      <TextInput
        disabled={isDisabled}
        className={classes.input}
        fullWidth
        source="email"
      />
      <TextInput
        disabled={isDisabled}
        className={classes.input}
        fullWidth
        source="primaryPhone"
      />
      <TextInput
        disabled={isDisabled}
        className={classes.input}
        fullWidth
        source="idNumber"
        label="ID Number"
      />
      <BooleanInput disabled={isDisabled} source="active" />
    </Box>
  );
};

const RoleField = props => {
  const classes = useStyles();

  return (
    <SelectArrayInput
      className={classes.halfWidth}
      optionText="name"
      {...props}
    />
  );
};

const RegionField = props => {
  const classes = useStyles();
  const { values } = useFormState();
  const { hasRole } = PermissionsProvider.useContainer();

  const roles: string[] = values['@@ra-many-to-many/user/user-role/role'];
  const hasBuilderSupervisorRole =
    Array.isArray(roles) &&
    roles.some(role => role === props.builderSupervisorRoleId);
  const isCustomerServiceRep = hasRole(AppRole.CustomerServiceRepresentative);

  const isDisabled =
    isCustomerServiceRep && (roles?.length > 1 || !hasBuilderSupervisorRole);

  const renderRegionError = localStorage.getItem('renderRegionError');

  return (
    <>
      <SelectArrayInput
        disabled={isDisabled}
        className={classes.halfWidth}
        optionText="name"
        {...props}
      />
      {renderRegionError && (
        <FormHelperText
          style={{ marginTop: '-20px', marginLeft: '20px', color: 'red' }}
        >
          The Region is required.
        </FormHelperText>
      )}
    </>
  );
};

export const UserEdit: FC<EditProps> = props => {
  const history = useHistory();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { hasRole } = PermissionsProvider.useContainer();
  // This ref is needed because <Edit /> form memoizes onSuccess function.
  // This memoization prevents `onSuccess` function from having the updated state when
  // user saves its changes.
  // By passing a reference to the function and updating the reference value in every render
  // We make sure that the value inside `onSuccess` will always be up to date.
  const hasFailureRef = useRef(false);

  const [regionalManagerOpen, setRegionalManagerOpen] = useState(false);
  const [supervisors, setSupervisors] = useState<ICustomItem[]>([]);
  const [manager, setManager] = useState<string>();
  const [supervisorRoleId, setSupervisorRoleId] = useState('');
  const [builderSupervisorRoleId, setBuilderSupervisorRoleId] = useState('');

  const searchParams = new URLSearchParams(props?.location?.search);
  const contractorId = searchParams.get('contractorId');
  const isCustomerServiceRep = hasRole(AppRole.CustomerServiceRepresentative);

  const routerState = (props?.location?.state ||
    props?.history?.location?.state) as LocationState;
  const formState = routerState?.formState || null;
  const selectedBumpers = routerState?.selectedBumpers || null;

  useEffect(() => {
    localStorage.removeItem('renderRegionError');
  }, []);

  const getSupervisorRoleId = async () => {
    try {
      const roles = await dataProvider.getList<RoleDto>('role', {
        filter: {},
        pagination: { page: 1, perPage: 20 },
        sort: { field: 'id', order: 'ASC' },
      });

      for await (let role of roles.data) {
        if (role.name === AppRole.Supervisor) {
          setSupervisorRoleId(role.id);
        }

        if (role.name === AppRole.BuilderSupervisor) {
          setBuilderSupervisorRoleId(role.id);
        }
      }
    } catch (error) {
      setSupervisorRoleId('');
    }
  };

  const getRegionalManagers = async () => {
    try {
      const userRegion = await dataProvider.getList('user-region', {
        filter: { userId: props.id },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'id', order: 'ASC' },
      });

      if (userRegion.data[0]) {
        setManager(userRegion.data[0].managerUserId);
        callSupervisors(setSupervisors, userRegion.data[0].regionId);
      }
    } catch (error) {
      notify('Failed to load regional managers', 'warning');
    }
  };

  const saveUserRegion = async () => {
    try {
      const userRegion = await dataProvider.getList('user-region', {
        filter: { userId: props.id },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'id', order: 'ASC' },
      });

      const paramsUpdate = {
        id: userRegion.data[0].id,
        data: { managerUserId: manager },
        previousData: userRegion.data[0],
      };

      await dataProvider.update('user-region', paramsUpdate);

      redirect('/user');
      notify('User updated');
    } catch (error: any) {
      notify(`User update error: ${error.message}`, 'warning');
    }
  };

  const onSuccess = async data => {
    if (hasFailureRef.current) {
      hasFailureRef.current = false;
      return;
    }

    const userRole = await dataProvider.getList<UserRoleInterface>(
      'user-role',
      {
        filter: { userId: props.id },
        pagination: { page: 1, perPage: 200 },
        sort: { field: 'id', order: 'ASC' },
      },
    );

    const rolesId = userRole.data.map(role => role.roleId);
    const role = await dataProvider.getList<RoleInterface>('role', {
      filter: { 'id||$in||': rolesId.join() },
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'id', order: 'ASC' },
    });

    if (isSupervisor(role.data) && !isCustomerServiceRep) {
      getRegionalManagers();
      setRegionalManagerOpen(true);
    } else {
      if (!contractorId) {
        redirect('/user');
        notify('User updated');
        refresh();
        return;
      }

      // Redirect to contractors's edit page if this edit form
      // was originated from a contractor's edit flow
      history.push({
        pathname: `/contractor/${contractorId}`,
        state: {
          _scrollToTop: true,
          user: data.data,
          formState,
          selectedBumpers,
        },
      });
    }
  };

  useEffect(() => {
    getRegionalManagers();
    getSupervisorRoleId();
  }, []);

  return (
    <Edit
      onSuccess={onSuccess}
      onFailure={data => {
        notify(data.message, 'warning');
        hasFailureRef.current = true;
        refresh();
      }}
      {...props}
    >
      <ManyToManyReferenceContextProvider>
        {!regionalManagerOpen ? (
          <SimpleForm
            validate={validateUserCreation({
              type: 'edit',
              isCustomerServiceRep: isCustomerServiceRep,
              supervisorRoleId: supervisorRoleId,
            })}
          >
            <NameFields builderSupervisorRoleId={builderSupervisorRoleId} />
            <UserInfoFields builderSupervisorRoleId={builderSupervisorRoleId} />
            <ReferenceManyToManyInput
              source="roles"
              reference="role"
              through="user-role"
              using="userId,roleId"
              label="Roles"
            >
              <RoleField />
            </ReferenceManyToManyInput>
            <ReferenceManyToManyInput
              source="region"
              reference="region"
              through="user-region"
              using="userId,regionId"
              label="Regions"
            >
              <RegionField builderSupervisorRoleId={builderSupervisorRoleId} />
            </ReferenceManyToManyInput>
            <RegionalManagerFields
              manager={manager}
              supervisors={supervisors}
              supervisorRoleId={supervisorRoleId}
              builderSupervisorRoleId={builderSupervisorRoleId}
            />
          </SimpleForm>
        ) : (
          <SimpleForm removeGoBack customSave={saveUserRegion}>
            <CustomSelect
              label="Regional Manager"
              resource="regionalManager"
              options={supervisors}
              value={manager}
              onChange={event => {
                if (event.target.value === manager) return;
                setManager(event.target.value as string);
              }}
            />
          </SimpleForm>
        )}
      </ManyToManyReferenceContextProvider>
    </Edit>
  );
};
