import { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { JobRepairNoteDto } from '@vatos-pas/common';
import { Identifier, useDataProvider, useNotify } from 'react-admin';
import { Show } from 'components/Show';

type NotesHistoryCheckCountProps = {
  boardPurchaseId: Identifier | undefined;
};

export const NotesHistoryCheckCount = ({
  boardPurchaseId,
}: NotesHistoryCheckCountProps) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [notes, setNotes] = useState<JobRepairNoteDto[] | null>(null);

  const getNotes = async () => {
    try {
      setLoading(true);
      const notesResponse = await dataProvider.getList<JobRepairNoteDto>(
        '/board-purchase-check-count-note',
        {
          filter: {
            boardPurchaseId,
          },
          pagination: { page: 1, perPage: 1000 },
          sort: { field: 'createdAt', order: 'DESC' },
        },
      );

      setNotes(notesResponse.data);
    } catch (err) {
      notify('Failed to get notes', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (boardPurchaseId) {
      getNotes();
    }
  }, [boardPurchaseId]);

  return (
    <Box width="100%" paddingBottom={1}>
      {loading ? (
        <Box display="flex" justifyContent="center">
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Box>
          <Show
            condition={!!notes?.length}
            fallback={<Typography>No notes available.</Typography>}
          >
            <Box pt={2}>
              <Typography style={{ fontWeight: 'bold' }}>
                Notes History
              </Typography>
            </Box>
            {notes?.map((item, index) => {
              const isFirstNote =
                !!notes?.length && item.id === notes?.[notes.length - 1].id;

              const noteType = isFirstNote ? 'Note' : 'Note Update';

              const isNoteRemoved =
                noteType === 'Note Update' && !item.text?.length;

              return (
                <Box
                  key={item.id}
                  mb={2}
                  borderTop={index === 0 ? '' : 1}
                  borderColor="lightgray"
                  pt={2}
                >
                  <Box>
                    <Typography variant="caption">{noteType}</Typography>
                    <Box>
                      <Typography>{`${item.user?.firstName} ${
                        item.user?.lastName
                      } - ${new Date(item.createdAt).toLocaleTimeString(
                        'en-US',
                      )} ${new Date(item.createdAt).toLocaleDateString(
                        'en-US',
                      )}`}</Typography>
                    </Box>
                    <Box mt={2}>
                      <Typography
                        className={isNoteRemoved ? classes.italic : undefined}
                      >
                        {isNoteRemoved ? 'Note removed' : item.text}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Show>
        </Box>
      )}
    </Box>
  );
};

const useStyles = makeStyles({
  italic: {
    fontStyle: 'italic',
  },
});

export default NotesHistoryCheckCount;
